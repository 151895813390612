import {
  DispatchedOrder,
  isAliExpressUniqueCode,
  isCoupangUniqueCode,
} from '@sweep/contract';
import { isNotEmptyString } from 'src/utils/string';

export function getBundleCancelRequestOrders(orders: DispatchedOrder[]) {
  const bundleOrders: (DispatchedOrder | DispatchedOrder[])[] = [];
  const ordersByCancelCode = new Map<string, DispatchedOrder[]>();
  const ordersByOrderNumber = new Map<string, DispatchedOrder[]>();

  orders.forEach((order) => {
    const orderNumber = order.orderNumber?.toString();
    const cancelCode = order.cancelCode?.toString();

    if (isCoupangUniqueCode(order.uniqueCode) && isNotEmptyString(cancelCode)) {
      const cancelCodeOrders = ordersByCancelCode.get(cancelCode);
      if (cancelCodeOrders == null) {
        ordersByCancelCode.set(cancelCode, [order]);
      } else {
        cancelCodeOrders.push(order);
      }
      return;
    }

    if (
      isAliExpressUniqueCode(order.uniqueCode) &&
      isNotEmptyString(orderNumber)
    ) {
      const orderNumberOrders = ordersByOrderNumber.get(orderNumber);
      if (orderNumberOrders == null) {
        ordersByOrderNumber.set(orderNumber, [order]);
      } else {
        orderNumberOrders.push(order);
      }
      return;
    }

    bundleOrders.push(order);
  });

  ordersByCancelCode.forEach((cancelCodeOrders) => {
    bundleOrders.push(cancelCodeOrders);
  });

  ordersByOrderNumber.forEach((orderNumberOrders) => {
    bundleOrders.push(orderNumberOrders);
  });

  return bundleOrders;
}
