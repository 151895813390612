import { hide, offset, Placement, size } from '@floating-ui/react';
import { hideOnVisibility, useDialog, useHideOnVisibility } from '@sweep/sds';

interface FilterDialogOptions {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  reference: React.RefObject<HTMLElement>;
}

const FLIP_MAX_HEIGHT = 240;

export function useTableFilterDialog({
  open,
  onOpenChange,
  reference,
}: FilterDialogOptions) {
  const dialog = useDialog({
    open,
    onOpenChange,
    reference,
    placement: 'bottom-start',
    middleware: [
      hide(),
      hideOnVisibility(),
      offset(10),
      {
        name: 'flip-max-height-auto-placement',
        fn({ placement, rects }) {
          const availableHeight = getAvailableHeight(
            rects.reference,
            placement
          );
          const verticalSide =
            availableHeight < FLIP_MAX_HEIGHT ? 'top' : 'bottom';

          const isOverflowRight =
            rects.reference.x + rects.floating.width > window.innerWidth;
          const horizontalSide = isOverflowRight ? 'end' : 'start';

          const newPlacement: Placement = `${verticalSide}-${horizontalSide}`;

          return {
            reset: {
              placement: newPlacement,
            },
          };
        },
      },
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(0, availableHeight - 12)}px`,
          });
        },
      }),
    ],
  });

  useHideOnVisibility(dialog.middlewareData, onOpenChange);

  return dialog;
}

function getAvailableHeight(
  rect: { y: number; height: number },
  placement: Placement
) {
  const side = getSide(placement);
  if (side === 'top') {
    return rect.y;
  }

  if (side === 'bottom') {
    return window.innerHeight - rect.y - rect.height;
  }

  return 0;
}

function getSide(placement: Placement) {
  switch (placement) {
    case 'top-start':
      return 'top';
    case 'top':
      return 'top';
    case 'top-end':
      return 'top';
    case 'right-start':
      return 'right';
    case 'right':
      return 'right';
    case 'right-end':
      return 'right';
    case 'bottom-start':
      return 'bottom';
    case 'bottom':
      return 'bottom';
    case 'bottom-end':
      return 'bottom';
    case 'left-start':
      return 'left';
    case 'left':
      return 'left';
    case 'left-end':
      return 'left';
  }
}
