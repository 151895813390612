import { useState } from 'react';
import { OMSStore } from 'src/stores/OMSStore';
import { toast } from 'src/third-parties/toast';

export enum EmailSendStatus {
  INIT = 'init',
  SENDING = 'sending',
  DONE = 'done',
}

type UseEmailSenderProps = {
  oms: OMSStore;
  onSubmit: () => void;
};

export const useEmailSender = ({ oms, onSubmit }: UseEmailSenderProps) => {
  // TODO(@형준): store 로 대체 가능한 hook, 불필요한 기능 제거하고 sendEmailWithPurchaseOrderFileStore 로 기능 이동
  const [emailSendStatus, setEmailSendStatus] = useState<EmailSendStatus>(
    EmailSendStatus.INIT
  );

  const onClickSend = async () => {
    if (emailSendStatus === EmailSendStatus.SENDING) {
      return;
    }

    setEmailSendStatus(EmailSendStatus.SENDING);
    try {
      const selectedRecordIds =
        oms.sendEmailWithPurchaseOrderFileStore.selectedRecordIds;

      const { isSuccess, successCount } =
        await oms.sendEmailWithPurchaseOrderFileStore.requestShareAllPurchaseOrderFileRecordsWithEmail(
          selectedRecordIds
        );

      if (successCount > 0) {
        toast.success('발주서가 전송되었습니다.');
      }

      if (isSuccess) {
        onSubmit();
      }
    } finally {
      setEmailSendStatus(EmailSendStatus.DONE);
    }
  };

  const onSelectRecord = (recordId: string) => {
    const selectedRecordIds =
      oms.sendEmailWithPurchaseOrderFileStore.selectedRecordIds;
    if (selectedRecordIds.includes(recordId)) {
      const newSelectedRecordIds = selectedRecordIds.filter(
        (id) => id !== recordId
      );
      oms.sendEmailWithPurchaseOrderFileStore.setSelectedRecordIds(
        newSelectedRecordIds
      );
    } else {
      oms.sendEmailWithPurchaseOrderFileStore.setSelectedRecordIds([
        ...selectedRecordIds,
        recordId,
      ]);
    }
  };

  const selectedRecordCount =
    oms.sendEmailWithPurchaseOrderFileStore.selectedRecordIds.length;
  const totalRecordCount =
    oms.sendEmailWithPurchaseOrderFileStore.recordItemStatuses.length;

  return {
    selectedRecordCount,
    totalRecordCount,
    onClickSend,
    selectedRecordIds:
      oms.sendEmailWithPurchaseOrderFileStore.selectedRecordIds,
    onSelectRecord,
    emailSendStatus,
  };
};
