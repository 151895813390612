import { observer } from 'mobx-react-lite';
import { IconControlReset, IconTime } from '@sweep/asset/icons';
import { Button, SearchInput } from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { useOMSStore } from 'src/hooks/useOMSStore';
import InquiryDetail from './components/InquiryDetail';
import InquiryTable from './components/InquiryTable';
import ShoppingMallSelect from './components/ShoppingMallSelect';
import StatusRadioGroup from './components/StatusRadioGroup';
import InquiryEmptyScreen from './InquiryEmptyScreen';
import { InquiryScreenStore } from './InquiryScreenStore';

function InquiryScreen() {
  const oms = useOMSStore();
  const store = oms.getStore(InquiryScreenStore);

  if (store.lastUpdatedAt == null) {
    return <InquiryEmptyScreen onDispatchClick={store.fetchInquiries} />;
  }

  return (
    <div className="gap-20px py-20px px-40px flex h-full flex-col bg-gray-100">
      <div className="gap-24px flex items-center">
        <p className="text-extrabold-l text-gray-700">CS 관리</p>
        <Button
          color="lightGray"
          rightAccessory={<IconControlReset />}
          onClick={store.fetchInquiries}
        >
          새로고침
        </Button>
        <div className="gap-4px flex items-center text-gray-500">
          <IconTime />
          <p className="text-regular-s">
            {formatDate(store.lastUpdatedAt, 'yyyy. M. dd. a h:mm')}
          </p>
        </div>
      </div>
      <div className="rounded-8px gap-20px px-20px pb-8px pt-20px shadow-line flex min-h-0 flex-col bg-white">
        <div className="gap-4px flex items-center justify-between">
          <div className="gap-40px flex">
            <ShoppingMallSelect
              value={store.selectedShoppingMall}
              onChange={store.setSelectedShoppingMall}
            />
            <StatusRadioGroup
              count={store.unansweredInquiries.length}
              value={store.selectedInquiryStatus}
              onChange={store.setSelectedInquiryStatus}
            />
          </div>
          <SearchInput
            value={store.search}
            onChange={store.setSearch}
            placeholder="검색어를 입력하세요."
            className="max-w-400px"
          />
        </div>
        <hr />
        <div className="gap-4px flex">
          <p className="text-medium-s text-gray-500">문의</p>
          <p className="text-medium-s text-gray-500">
            (
            <span className="text-semibold-s text-blue-500">
              {store.filteredInquiries.length}
            </span>
            건)
          </p>
        </div>
        <InquiryTable
          inquiries={store.filteredInquiries}
          selectedInquiryUniqueCode={store.selectedInquiryUniqueCode}
          onSelectInquiryUniqueCode={store.setSelectedInquiryUniqueCode}
        />
      </div>
      {store.selectedInquiry != null && (
        <InquiryDetail store={store} inquiry={store.selectedInquiry} />
      )}
    </div>
  );
}

export default observer(InquiryScreen);
