import { observer } from 'mobx-react-lite';
import {
  IconErrorLarge,
  IconFactory,
  IconSuccessLarge,
  IconX,
} from '@sweep/asset/icons';
import { SwitchCase } from '@sweep/utils/react';
import { LoadingLottie } from 'src/screens/dispatch/components/DispatchModal/LoadingLottie';
import {
  SEND_EMAIL_STATUS,
  SendEmailStatus,
} from 'src/stores/SendEmailWithPurchaseOrderFileStore';

type RecordItemInProgressProps = {
  supplierName: string;
  supplierEmail: string;
  filename: string;
  emailStatus: SendEmailStatus;
};

export const RecordItemInProgress = observer(
  ({ supplierName, filename, emailStatus }: RecordItemInProgressProps) => {
    return (
      <div className="py-14px px-20px gap-16px flex w-full gap-2 rounded-[8px] bg-gray-100">
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <IconFactory className="text-blue-500" />
            <div className="gap-3px flex flex-col truncate">
              <p className="text-bold-m text-gray-700">{supplierName}</p>
              <p className="text-medium-s text-gray-500">{filename}</p>
            </div>
          </div>
        </div>
        <SwitchCase
          value={emailStatus}
          caseBy={{
            [SEND_EMAIL_STATUS.INIT]: <IconX />,
            [SEND_EMAIL_STATUS.SENDING]: <LoadingLottie />,
            [SEND_EMAIL_STATUS.ERROR]: <IconErrorLarge />,
            [SEND_EMAIL_STATUS.SUCCESS]: <IconSuccessLarge />,
          }}
        />
      </div>
    );
  }
);
