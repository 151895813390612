import {
  GET_POLLING_INQUIRIES_URL,
  GetPollingInquiriesResponse,
  getShoppingMallNameByUniqueCode,
  REPLY_INQUIRY_URL,
  ReplyInquiriesBody,
  ReplyInquiriesResponse,
  REQUEST_INQUIRIES_URL,
  RequestInquiriesBody,
  RequestInquiriesResponse,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import { amplitude } from 'src/third-parties/amplitude';
import api, { APIResponse } from './api';

export async function requestInquiry(shoppingMall: string) {
  const response = await tryCatch(() =>
    api.post<APIResponse<RequestInquiriesResponse>, RequestInquiriesBody>(
      REQUEST_INQUIRIES_URL,
      { shoppingMall }
    )
  );

  amplitude.track('Dispatch cs (Server)', {
    storeName: shoppingMall,
  });

  return response?.data;
}

export async function getPollingInquiries(inquiryRequestId: string) {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetPollingInquiriesResponse>>(
      GET_POLLING_INQUIRIES_URL(inquiryRequestId)
    )
  );

  return response?.data;
}

export async function replyInquiry(
  inquiryUniqueCode: string,
  content?: string
) {
  const response = await tryCatch(() =>
    api.post<APIResponse<ReplyInquiriesResponse>, ReplyInquiriesBody>(
      REPLY_INQUIRY_URL,
      {
        inquiryUniqueCode,
        content,
      }
    )
  );

  amplitude.track('Reply cs (Server)', {
    storeName: getShoppingMallNameByUniqueCode(inquiryUniqueCode),
  });

  return response?.data;
}
