import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { IconTrashcan } from '@sweep/asset/icons';
import { CombinedShipping, CreateCombinedShippingDTO } from '@sweep/contract';
import { Button, SearchInput } from '@sweep/sds';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { toast } from 'src/third-parties/toast';
import { openCombinedShippingCreateFormModal } from './components/CombinedShippingCreateFormModal';
import { openCombinedShippingEditFormModal } from './components/CombinedShippingEditFormModal/openCombinedShippingEditFormModal';
import { openCombinedShippingMultiCreateFormModal } from './components/CombinedShippingMultiCreateFormModal';
import { CombinedShippingTable } from './components/CombinedShippingTable';
import { openConfirmDeleteConbinedShippingDialog } from './components/Dialogs/openConfirmDeleteConbinedShippingDialog';
import { filterCombinedShipping } from './services/filterCombinedShipping';

function CombinedShippingScreen() {
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState<CombinedShipping[]>([]);
  const oms = useOMSStore();
  const combinedShippings = oms.combinedShipping.combinedShippings;
  const filteredCombinedShippings = combinedShippings.filter(
    (combinedShipping) => filterCombinedShipping(oms, search, combinedShipping)
  );

  const handleCreate = async () => {
    const result = await openCombinedShippingCreateFormModal();
    if (result == null) {
      return;
    }

    await oms.combinedShipping.create(result.combinedShipping);
    await oms.product.updateMany(result.products);
    setSelectedItems([]);
    toast.success('합배송이 설정되었습니다.');
  };

  const handleMultiCreate = async () => {
    const result = await openCombinedShippingMultiCreateFormModal();
    if (result == null) {
      return;
    }
    const createCombinedShippingDTOs: CreateCombinedShippingDTO[] =
      result.products.map((product) => ({
        type: 'count',
        products: [{ productId: product.productId, option: product.option }],
        amount: result.count,
        supplierId: product.supplierId,
      }));

    await oms.combinedShipping.createMany(createCombinedShippingDTOs);
    setSelectedItems([]);
    toast.success('합배송이 설정되었습니다.');
  };

  const handleEdit = async (prevCombinedShipping: CombinedShipping) => {
    const result =
      await openCombinedShippingEditFormModal(prevCombinedShipping);
    if (result == null) {
      return;
    }

    await oms.combinedShipping.update(
      prevCombinedShipping._id,
      result.combinedShipping
    );
    await oms.product.updateMany(result.products);
    setSelectedItems([]);
    toast.success('합배송 설정이 수정되었습니다.');
  };

  const handleDelete = async () => {
    const isConfirmed = await openConfirmDeleteConbinedShippingDialog();
    if (!isConfirmed) {
      return;
    }

    await Promise.all(
      selectedItems.map((item) => oms.combinedShipping.delete(item._id))
    );
    setSelectedItems([]);
    toast.success('합배송 설정이 삭제되었습니다.');
  };

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <p className="h-42px text-extrabold-l text-gray-700">합배송</p>
      <div className="gap-16px p-20px rounded-8px shadow-line flex flex-col bg-white">
        <div className="flex items-center justify-between">
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="검색어를 입력하세요."
            className="max-w-500px"
          />
          <div className="gap-10px flex shrink-0 items-center">
            <If is={selectedItems.length === 0}>
              <Button color="gray" onClick={handleMultiCreate}>
                합배송 일괄 설정
              </Button>
              <Button onClick={handleCreate}>합배송 설정</Button>
            </If>
            <If is={selectedItems.length > 0}>
              <Button
                onClick={handleDelete}
                color="lightGray"
                variant="line"
                leftAccessory={<IconTrashcan />}
              >
                삭제
              </Button>
            </If>
          </div>
        </div>
        <If is={combinedShippings.length > 0}>
          <CombinedShippingTable
            items={filteredCombinedShippings}
            selectedItems={selectedItems}
            onSelect={setSelectedItems}
            onEdit={handleEdit}
          />
        </If>
        <If is={combinedShippings.length === 0}>
          <div className="flex-center h-500px flex">
            <p className="text-medium-s text-gray-500">
              합배송 기준이 없습니다.
            </p>
          </div>
        </If>
      </div>
    </div>
  );
}

export default observer(CombinedShippingScreen);
