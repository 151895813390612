import { If } from '@sweep/utils/react';
import { IconCheckXSmall } from '@sweep/asset/icons';

interface TableSelectControlProps {
  count: number;
  onClear: () => void;
}

export function TableSelectControl({
  count,
  onClear,
}: TableSelectControlProps) {
  return (
    <div className="flex gap-[8px] h-[23px] items-end">
      <div className="gap-6px flex items-center">
        <IconCheckXSmall className="text-gray-400" />
        <p className="text-semibold-s text-blue-500">{count}</p>
        <p className="text-medium-s text-gray-500">선택됨</p>
      </div>
      <If is={count > 0}>
        <button
          className="h-[23px] rounded-[6px] px-[8px] bg-gray-100 font-semibold text-[12px] leading-[17px] text-gray-500"
          onClick={onClear}
        >
          전체 해제
        </button>
      </If>
    </div>
  );
}
