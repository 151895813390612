import { observer } from 'mobx-react-lite';
import { IconErrorLarge, IconFactory } from '@sweep/asset/icons';
import { Divider } from '@sweep/sds';

interface EmailSendFailProps {
  supplierName: string;
  filename: string;
  supplierEmail: string;
}

function EmailSendFail({
  supplierName,
  filename,
  supplierEmail,
}: EmailSendFailProps) {
  return (
    <div className="py-12px px-20px gap-12px flex flex-col items-center rounded-[8px] bg-white">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-[12px]">
          <div className="flex size-[44px] items-center justify-center rounded-[8px] bg-gray-100 text-blue-200">
            <IconFactory width={24} height={24} />
          </div>
          <div className="gap-3px flex flex-col">
            <p className="text-bold-m text-gray-700">{supplierName}</p>
            <p className="text-medium-s shrink-0 truncate text-gray-500">
              {filename}
            </p>
          </div>
        </div>
        <IconErrorLarge width={48} height={48} />
      </div>
      <Divider type="horizontal-full" />
      <div className=" w-full">
        <p className="pl-8px text-medium-s text-gray-500">{supplierEmail}</p>
      </div>
    </div>
  );
}

export default observer(EmailSendFail);
