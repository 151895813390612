import { IconInfo } from '@sweep/asset/icons';
import { InquiryStatus } from '@sweep/contract';
import { RadioGroup, Tooltip } from '@sweep/sds';

export type RadioInquiryStatus = InquiryStatus | 'ALL';

interface StatusRadioGroupProps {
  count: number;
  value: RadioInquiryStatus;
  onChange: (value: RadioInquiryStatus) => void;
}

function StatusRadioGroup({ value, onChange, count }: StatusRadioGroupProps) {
  return (
    <div className="gap-20px flex items-center">
      <RadioGroup value={value} onChange={onChange}>
        <label className="flex cursor-pointer items-center">
          <RadioGroup.Item value="ALL" className="mr-8px" />
          <p className="text-semibold-s text-gray-600">전체</p>
        </label>
        <label className="flex cursor-pointer items-center">
          <RadioGroup.Item value="UNANSWERED" className="mr-8px" />
          <p className="text-semibold-s mr-6px text-gray-600">미답변</p>
          <div className="px-6px h-24px flex-center rounded-4px flex w-fit bg-blue-50">
            <p className="text-bold-s text-blue-500">{count}</p>
          </div>
        </label>
        <label className="flex cursor-pointer items-center">
          <RadioGroup.Item value="ANSWERED" className="mr-8px" />
          <p className="text-semibold-s mr-6px text-gray-600">답변 완료</p>
          <Tooltip placement="bottom-start">
            <Tooltip.Trigger>
              <IconInfo className="text-gray-400" />
            </Tooltip.Trigger>
            <Tooltip.Content>
              최근 30일의 답변 완료 CS가 나타납니다.
            </Tooltip.Content>
          </Tooltip>
        </label>
      </RadioGroup>
    </div>
  );
}

export default StatusRadioGroup;
