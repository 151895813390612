import { z } from 'zod';
import { Inquiry } from '../type/inquiry/Inquiry';

// POST /inquiry/request
export const REQUEST_INQUIRIES_URL = '/inquiry/request';
export const RequestInquiriesBodySchema = z.object({
  shoppingMall: z.string(),
});
export type RequestInquiriesBody = z.infer<typeof RequestInquiriesBodySchema>;
export type RequestInquiriesResponse = string;

// GET /inquiry/request/:inquiryRequestId
export const GET_POLLING_INQUIRIES_URL = (inquiryRequestId: string) =>
  `/inquiry/request/${inquiryRequestId}`;
export const GetPollingInquiriesQuerySchema = z.object({
  inquiryRequestId: z.string(),
});
export type GetPollingInquiriesQuery = z.infer<
  typeof GetPollingInquiriesQuerySchema
>;

export type GetPollingInquiriesResponse =
  | { status: 'IN_PROGRESS' }
  | { status: 'FAILED' }
  | {
      status: 'FINISH';
      inquiries?: Inquiry[];
    };

// POST /inquiry/reply
export const REPLY_INQUIRY_URL = '/inquiry/reply';
export const ReplyInquiriesBodySchema = z.object({
  inquiryUniqueCode: z.string(),
  content: z.string().optional(),
});
export type ReplyInquiriesBody = z.infer<typeof ReplyInquiriesBodySchema>;
export type ReplyInquiriesResponse = Inquiry;
