import { CreateOrderShippingProcessHistoryDTO } from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function requestCreateOrderShippingProcessHistory(
  params: CreateOrderShippingProcessHistoryDTO
): Promise<CreateOrderShippingProcessHistoryDTO | null> {
  // SWP-765 발주서 이메일 전송 파일 사이즈 제한 개선
  // NOTE(@형준): 몽고DB 16MB 제한이 있어서 임시로 processedItems 제외하고 업로드
  const { processedItems, ...rest } = params;

  const newDTO = {
    ...rest,
    processedItems: [],
  };
  // SWP-765 발주서 이메일 전송 파일 사이즈 제한 개선

  const response = await api.post<
    APIResponse<CreateOrderShippingProcessHistoryDTO>
  >('/order-shipping-process-histories', newDTO);

  if (response?.data?.success === true && response.data.data != null) {
    return response.data.data;
  }

  return null;
}
