import { overlay } from 'overlay-kit';
import PurchaseOrderFileProcessModal from './PurchaseOrderFileProcessModal';

export async function openPurchaseOrderFileProcessModal() {
  return new Promise((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <PurchaseOrderFileProcessModal
        open={isOpen}
        onClose={() => {
          close();
          unmount();
          resolve(true);
        }}
      />
    ))
  );
}
