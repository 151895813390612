import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Product } from '@sweep/contract';
import { Button, Modal } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { toast } from 'src/third-parties/toast';
import { ProductFormStore } from '../../ProductFormStore';
import { checkCombinedShippingRemoval } from '../../services/checkCombinedShippingRemoval';
import { getNormalizedProduct } from '../../services/getNormalizedProduct';
import { isDuplicatedOption } from '../../services/isDuplicatedOption';
import { isExistEmptySalesChannel } from '../../services/isExistEmptySalesChannel';
import { openDeleteCombinedShippingWarningDialog } from '../common/openDeleteCombinedShippingWarningDialog';
import OptionInputSection from '../common/OptionInputSection';
import ProductNameInput from '../common/ProductNameInput';
import ProductSettingInput from '../common/ProductSettingInput';
import SupplierInputSection from '../common/SupplierInputSection';
import { openProductUpdateFormCloseDialog } from './openProductUpdateFormCloseDialog';

interface ProductUpdateFormModalProps {
  open: boolean;
  product: Product;
  onSubmit: (product: Product | null) => void;
}

function ProductUpdateFormModal({
  open,
  product: givenProduct,
  onSubmit: givenOnSubmit,
}: ProductUpdateFormModalProps) {
  const oms = useOMSStore();
  const [store] = useState(() => new ProductFormStore(oms, givenProduct));

  const onClose = async () => {
    const isClose = await openProductUpdateFormCloseDialog();
    if (!isClose) {
      return;
    }
    givenOnSubmit(null);
  };

  const onSubmit = async () => {
    if (isExistEmptySalesChannel(store.salesChannelSupplierMatchings)) {
      store.setShowSupplierError(true);
      toast.error('판매처를 선택해주세요.');
      return;
    }

    const normalizedProduct = getNormalizedProduct(
      store.product,
      store.salesChannelSupplierMatchings
    );

    if (normalizedProduct.productName === '') {
      toast.error('상품명은 비워둘 수 없습니다.');
      return;
    }

    if (
      store.isDuplicatedProductName ||
      isDuplicatedOption(normalizedProduct)
    ) {
      toast.error('중복된 상품명 혹은 옵션이 있습니다.');
      return;
    }

    const willRemoveCombinedShipping = checkCombinedShippingRemoval(
      oms,
      givenProduct,
      normalizedProduct
    );

    if (willRemoveCombinedShipping) {
      const confirmed = await openDeleteCombinedShippingWarningDialog();
      if (!confirmed) {
        return;
      }
    }

    givenOnSubmit(normalizedProduct);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className="max-h-720px"
    >
      <Modal.Title>상품 수정하기</Modal.Title>
      <ProductSettingInput store={store} className="mb-24px" />
      <div className="gap-8px pb-52px flex flex-col overflow-y-auto">
        <ProductNameInput store={store} />
        <OptionInputSection store={store} />
        {store.useSupplierByOption !== true && (
          <SupplierInputSection store={store} className="mt-24px" />
        )}
      </div>
      <div className="gap-12px flex-center flex">
        <Modal.Close className="w-200px" color="lightGray">
          닫기
        </Modal.Close>
        <Button className="w-200px" onClick={onSubmit}>
          수정하기
        </Button>
      </div>
    </Modal>
  );
}

export default observer(ProductUpdateFormModal);
