import { useState } from 'react';
import { IconBigDown, IconBigDownLeft } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import {
  imageInterviewProfile농협,
  imageInterviewProfile엔씨유통,
  imageInterviewProfile자연맛남,
} from 'src/images';
import InterviewCard, { Interview } from './InterviewCard';

const interviews: Interview[] = [
  {
    logo: imageInterviewProfile농협,
    companyName: '농협목우촌',
    position: '이○○ 주임님',
    content: (
      <>
        알리 익스프레스에 입점한 셀러가 있다면 스윕을 추천하고 싶어요. 스윕을
        쓰지 않았을 때와 비교하면 업무가 훨씬 편해졌습니다.
      </>
    ),
    description: '농협 대표 브랜드',
  },
  {
    logo: imageInterviewProfile자연맛남,
    companyName: '이화컴퍼니',
    position: '나○○ 차장님',
    content: (
      <>
        알리 익스프레스 주문 처리는 스윕이 제일 좋은 것 같아요. 특히 요청 사항을
        전달드렸을 때 빠르게 반영되는 점이 좋습니다.
      </>
    ),
    description: "식품 전문 브랜드 '자연맛남'",
  },
  {
    logo: imageInterviewProfile엔씨유통,
    companyName: '엔씨유통',
    position: '박○○ 대표님',
    content: (
      <>
        다른 솔루션도 써봤지만 알리 주문을 처리할 때에는 스윕이 제일 편한 것
        같아요. 특히 화면이 복잡하지 않고 단순해서 좋아요.
      </>
    ),
    description: '농심 알리 익스프레스 공식 유통사',
  },
];

function Interviews() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleClickLeft = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleClickRight = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, interviews.length - 1));
  };

  return (
    <div className="flex h-[320px] w-[94%] items-center justify-between">
      <div
        className={button({ visible: currentIndex > 0 })}
        onClick={handleClickLeft}
      >
        <IconBigDownLeft className={arrow()} />
      </div>
      <div className="relative flex items-center justify-center">
        <InterviewCard rotated={true} visible={true} />
        <InterviewCard rotated={false} visible={true} />
        {interviews.map((interview, index) => (
          <InterviewCard
            key={index}
            rotated={index > currentIndex}
            visible={index === currentIndex}
            interview={interview}
          />
        ))}
      </div>
      <div
        className={button({ visible: currentIndex < interviews.length - 1 })}
        onClick={handleClickRight}
      >
        <IconBigDown className={arrow()} />
      </div>
    </div>
  );
}

const button = cva(
  'flex size-[26px] items-center justify-center rounded-full bg-gray-400 transition-all duration-500',
  {
    variants: {
      visible: {
        true: 'cursor-pointer',
        false: 'opacity-0',
      },
    },
  }
);

const arrow = cva('size-[16px]');

export default Interviews;
