import { cva } from '@sweep/tailwind';
import { formatDateAgo } from '@sweep/utils';
import { BrandLogoByName } from '@sweep/sds';
import { IconError, IconTimeSmall } from '@sweep/asset/icons';
import SelectIcon from './SelectIcon';
import { useShoppingMallSelectContext } from './ShoppingMallSelectContext';

interface ShoppingMallSelectOptionProps {
  shoppingMall: string;
  lastDispatchedAt?: number;
  rejected?: boolean;
}

function ShoppingMallSelectOption({
  shoppingMall,
  lastDispatchedAt,
  rejected,
}: ShoppingMallSelectOptionProps) {
  const { value: contextValue, onChange } = useShoppingMallSelectContext();

  const selected = contextValue.includes(shoppingMall);

  const handleClick = () => {
    if (selected) {
      onChange(contextValue.filter((v) => v !== shoppingMall));
      return;
    }

    onChange([...contextValue, shoppingMall]);
  };

  return (
    <button onClick={handleClick} className={container({ selected })}>
      <SelectIcon selected={selected} />
      <div className="flex w-max min-w-0 flex-1 justify-between">
        <div className="ml-[4px] flex min-w-0 flex-1 items-center gap-[6px]">
          <BrandLogoByName size="sm" name={shoppingMall} />
          <p className="text-medium-s overflow-hidden text-ellipsis text-nowrap text-gray-700">
            {shoppingMall}
          </p>
        </div>
        {lastDispatchedAt != null && (
          <div className="flex items-center">
            {rejected && (
              <IconError width={24} height={24} className="mr-[8px]" />
            )}
            <p className="text-regular-xs mr-[4px] text-gray-500">
              {formatDateAgo(new Date(lastDispatchedAt))}
            </p>
            <IconTimeSmall className="text-gray-500" />
          </div>
        )}
      </div>
    </button>
  );
}

const container = cva('gap-4px py-10px pl-12px pr-16px flex items-center', {
  variants: {
    selected: {
      true: 'bg-gray-100',
      false: 'bg-white hover:bg-gray-100',
    },
  },
});

export default ShoppingMallSelectOption;
