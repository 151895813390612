import { isNil } from 'es-toolkit';
import { FileHeaderMappingResult, NormalizedOrder } from '@sweep/contract';
import {
  filterNonEmptyRows,
  findHeaderRowAndSlice,
  findRetailerHeaderMapping,
  mapHeaderRowToObjects,
  separateHeaderAndItems,
} from '@sweep/domain/services/header-mappings';
import { isNotNil } from '@sweep/utils';
import { openCreateSpreadsheetHeaderMappings } from '../../components/spreadsheet-header-mapping-form/SpreadsheetHeaderMappingsForm';
import { ProcessShippingOrderFileContext } from './processShippingOrderFile.type';

export async function processFileHeaderMapping(
  params: { rawSpreadsheetData: string[][]; filename: string },
  context: ProcessShippingOrderFileContext
): Promise<{
  isSuccess: boolean;
  shippingOrders: NormalizedOrder[];
  defaultShippingCompany?: string | null;
  matchDetails: FileHeaderMappingResult;
}> {
  const { rawSpreadsheetData, filename } = params;
  const { oms } = context;

  const supplierInfos = oms.supplier.suppliers;
  const customExcelSettings = oms.user.setting;
  const userSpreadsheetHeaderMappings =
    oms.spreadsheetHeaderMapping.spreadsheetHeaderMappings;

  const slicedExcelData = findHeaderRowAndSlice(rawSpreadsheetData);
  const validExcelRows = filterNonEmptyRows(slicedExcelData);
  const { headerRow, dataRows } = separateHeaderAndItems(validExcelRows);

  const matchResult = findRetailerHeaderMapping({
    headerRow,
    supplierInfos,
    customExcelSettings,
    userSpreadsheetHeaderMappings,
  });

  if (isNotNil(matchResult)) {
    const {
      headerKeys,
      mappingColumn,
      defaultShippingCompany,
      matchReason: headerMatchReason,
      matchReasonDetail: headerMatchDetails,
    } = matchResult;

    const shippingOrders = mapHeaderRowToObjects(
      headerKeys,
      dataRows
    ) as NormalizedOrder[];

    return {
      isSuccess: true,
      shippingOrders,
      defaultShippingCompany,
      matchDetails: {
        isSuccess: true,
        headerMatchReason,
        headerMatchDetails,
        headers: headerRow,
        headerKeys,
        mappingColumn,
        defaultShippingCompany,
      },
    };
  }

  const newSpreadsheetHeaderMappings =
    await openCreateSpreadsheetHeaderMappings([
      {
        filename,
        headers: headerRow,
        exampleContents: dataRows.slice(0, 7),
      },
    ]);

  if (isNil(newSpreadsheetHeaderMappings)) {
    return {
      isSuccess: false,
      shippingOrders: [],
      matchDetails: {
        isSuccess: false,
        headerMatchReason: '헤더 매핑 실패',
        headerMatchDetails: '헤더 매핑 실패',
        headers: headerRow,
        headerKeys: [],
        mappingColumn: {},
        defaultShippingCompany: null,
      },
    };
  }

  const matchResult2 = findRetailerHeaderMapping({
    headerRow,
    supplierInfos,
    customExcelSettings,
    userSpreadsheetHeaderMappings: newSpreadsheetHeaderMappings,
  });

  if (isNotNil(matchResult2)) {
    const {
      headerKeys,
      mappingColumn,
      defaultShippingCompany,
      matchReason: headerMatchReason,
      matchReasonDetail: headerMatchDetails,
    } = matchResult2;

    const shippingOrders = mapHeaderRowToObjects(
      headerKeys,
      dataRows
    ) as NormalizedOrder[];

    return {
      isSuccess: true,
      shippingOrders,
      defaultShippingCompany,
      matchDetails: {
        isSuccess: true,
        headerMatchReason,
        headerMatchDetails,
        headers: headerRow,
        headerKeys,
        mappingColumn,
        defaultShippingCompany,
      },
    };
  }

  return {
    isSuccess: false,
    shippingOrders: [],
    matchDetails: {
      isSuccess: false,
      headerMatchReason: '헤더 매핑 실패',
      headerMatchDetails: '헤더 매핑 실패',
      headers: headerRow,
      headerKeys: [],
      mappingColumn: {},
      defaultShippingCompany: null,
    },
  };
}
