import { formatDate } from '@sweep/utils';
import { Markdown } from '../Markdown';

interface InquiryWriteReplyResultProps {
  content?: string;
  replyDate: number;
}

function InquiryWriteReplyResult({
  content,
  replyDate,
}: InquiryWriteReplyResultProps) {
  return (
    <div className="gap-8px flex w-full flex-col">
      <Markdown className="rounded-8px py-12px px-16px w-full min-w-0 flex-1 border-[1.5px] border-gray-200">
        {content}
      </Markdown>
      {replyDate != null && (
        <div className="flex justify-end">
          <p className="text-medium-s text-gray-500">
            처리일시: {formatDate(new Date(replyDate), 'yyyy-MM-dd HH:mm:ss')}
          </p>
        </div>
      )}
    </div>
  );
}

export default InquiryWriteReplyResult;
