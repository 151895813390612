import { memo } from 'react';
import { cva } from '@sweep/tailwind';
import { ColumnDef } from '../interface';
import { TableHeaderCellRenderer } from './TableHeaderCellRenderer';

interface TableHeaderProps<T> {
  columns: ColumnDef<T>[];
}

function _TableHeader<T>({ columns }: TableHeaderProps<T>) {
  return (
    <div className={header()}>
      {columns.map(({ header, resize }, index) => (
        <TableHeaderCellRenderer
          key={index}
          header={header}
          context={{
            lastColumn: index === columns.length - 1,
          }}
          index={index}
          resize={resize}
        />
      ))}
    </div>
  );
}

const header = cva('min-h-36px sticky left-0 top-0 z-[1] flex w-full');

export const TableHeader = memo(_TableHeader) as typeof _TableHeader;
