import { z } from 'zod';

export const SharePurchaseOrderFileRecordWithEmailResponseSchema = z.object({
  isSuccess: z.boolean(),
  purchaseOrderFileRecordId: z.string(),
  errorMessage: z.string().optional(),
  successCount: z.number(),
  failedCount: z.number(),
});

export type SharePurchaseOrderFileRecordWithEmailResponse = z.infer<
  typeof SharePurchaseOrderFileRecordWithEmailResponseSchema
>;
