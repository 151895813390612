import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openConfirmUploadDialog(
  uploadedFilesCount: number,
  firstUploadedFileName: string
) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-16px">
          중복 파일을 제외하고 업로드 할까요?
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          {uploadedFilesCount === 1
            ? `'${firstUploadedFileName}' 파일은 이미 업로드 되었어요.`
            : `'${firstUploadedFileName}' 및 ${uploadedFilesCount - 1}개 파일은 이미 업로드 되었어요.`}
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
