import saveAs from 'file-saver';
import { ExcelFileRecord, Order } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { findHeaderKeys } from 'src/services/column-mapping/findHeaderKeys';
import { OMSStore } from 'src/stores/OMSStore';
import { createOrderExcelBuffer } from './createOrderExcelBuffer';

const EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export async function createOrderExcel(
  oms: OMSStore,
  orders: Order[],
  fileName: string,
  headers: string[],
  columnMapping: ColumnMapping
) {
  if (orders.length === 0) {
    return;
  }
  const headerKeys =
    headers.length === 0
      ? oms.user.excelHeaderKeys
      : (findHeaderKeys(headers, columnMapping) as string[]);

  const separateAddressEnabled =
    oms.user.setting?.preprocessSettings?.separateAddress?.enabled ?? false;

  const userExcelSettingFields = oms.user.setting?.excel?.fields ?? [];

  const excelFileRecord: ExcelFileRecord = {
    filename: fileName,
    fileExtension: 'xlsx',
    orders: orders,
    headers: headers,
    headerKeys: headerKeys,
    columnMappings: columnMapping as Record<string, string>,
    separateAddressEnabled,
    userExcelSettingFields,
  };

  const buffer = await createOrderExcelBuffer(excelFileRecord);

  if (buffer == null) {
    return;
  }

  const blob = new Blob([buffer], { type: EXCEL_MIME_TYPE });
  saveAs(blob, `${fileName}.xlsx`);
}
