import { overlay } from 'overlay-kit';
import EditShippingNumberFormModal, {
  ShippingInvoice,
} from './EditShippingNumberFormModal';

export function openEditShippingNumberFormModal(params: {
  initShippingCompany: string;
  initShippingNumber: string;
}): Promise<ShippingInvoice | null> {
  return new Promise((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <EditShippingNumberFormModal
        open={isOpen}
        initShippingCompany={params.initShippingCompany}
        initShippingNumber={params.initShippingNumber}
        onSubmit={(shippingInvoice) => {
          close();
          unmount();
          resolve(shippingInvoice);
        }}
      />
    ))
  );
}
