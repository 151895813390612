import { Inquiry } from '@sweep/contract';
import { SwitchCase } from '@sweep/utils/react';
import InquiryReviewReplyResult from './InquiryConfirmReplyResult';
import InquiryPassReplyResult from './InquiryPassReplyResult';
import InquiryWriteReplyResult from './InquiryWriteReplyResult';

interface InquiryReplyResultProps {
  inquiry: Inquiry;
}

function InquiryReplyResult({ inquiry }: InquiryReplyResultProps) {
  const replyType = inquiry.replyType;
  const reply = inquiry.replies?.at(0);

  return (
    <SwitchCase
      value={replyType}
      caseBy={{
        WRITE: reply && (
          <InquiryWriteReplyResult
            content={reply?.content}
            replyDate={reply?.replyDate}
          />
        ),
        PASS: <InquiryPassReplyResult replyDate={inquiry.inquiryDate} />,
        CONFIRM: <InquiryReviewReplyResult replyDate={inquiry.inquiryDate} />,
      }}
    />
  );
}

export default InquiryReplyResult;
