export const ShoppingMallName = {
  COUPANG: '쿠팡',
  ELEVEN: '11번가',
  NAVER: '스마트스토어',
  ALIEXPRESS: 'AliExpress',
  AUCTION: '옥션',
  GMARKET: '지마켓',
  KAKAOSHOP: '카카오쇼핑',
  ALWAYZ: '올웨이즈',
  CAFE24: '카페24',
  KAKAOMAKERS: '카카오메이커스',
  SHOPBY: '샵바이',
  CASHDEAL: '캐시딜',
  TDEAL: '티딜',
  KDEAL: '케이딜',
} as const;

export type ShoppingMallName =
  (typeof ShoppingMallName)[keyof typeof ShoppingMallName];

export const CAFE_PREFIX = 'CAF-';
export const COUPANG_PREFIX = 'CUP-';
export const ALIEXPRESS_PREFIX = 'ALI-';
export const KAKAO_PREFIX = 'KAK-';
export const NAVER_SMART_STORE_PREFIX = 'NSS-';
export const ELEVEN_PREFIX = 'ELV-';
export const GMARKET_PREFIX = 'GMA-';
export const AUCTION_PREFIX = 'AUC-';
export const ALWAYZ_PREFIX = 'AWZ-';
export const KAKAOMAKERS_PREFIX = 'MAK-';
export const SHOPBY_PREFIX = 'SHP-';

export function isCafeUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(CAFE_PREFIX);
}

export function isCoupangUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(COUPANG_PREFIX);
}

export function isAliExpressUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(ALIEXPRESS_PREFIX);
}

export function isKakaoUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(KAKAO_PREFIX);
}

export function isNaverSmartStoreUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(NAVER_SMART_STORE_PREFIX);
}

export function isElevenUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(ELEVEN_PREFIX);
}

export function isGmarketUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(GMARKET_PREFIX);
}

export function isAuctionUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(AUCTION_PREFIX);
}

export function isAlwayzUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(ALWAYZ_PREFIX);
}

export function isKakaoMakersUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(KAKAOMAKERS_PREFIX);
}

export function isShopByUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(SHOPBY_PREFIX);
}

export function getShoppingMallNameByUniqueCode(
  uniqueCode: string
): ShoppingMallName {
  if (isAliExpressUniqueCode(uniqueCode)) {
    return ShoppingMallName.ALIEXPRESS;
  }

  if (isKakaoUniqueCode(uniqueCode)) {
    return ShoppingMallName.KAKAOSHOP;
  }

  if (isNaverSmartStoreUniqueCode(uniqueCode)) {
    return ShoppingMallName.NAVER;
  }

  if (isElevenUniqueCode(uniqueCode)) {
    return ShoppingMallName.ELEVEN;
  }

  if (isGmarketUniqueCode(uniqueCode)) {
    return ShoppingMallName.GMARKET;
  }

  if (isAuctionUniqueCode(uniqueCode)) {
    return ShoppingMallName.AUCTION;
  }

  if (isAlwayzUniqueCode(uniqueCode)) {
    return ShoppingMallName.ALWAYZ;
  }

  if (isKakaoMakersUniqueCode(uniqueCode)) {
    return ShoppingMallName.KAKAOMAKERS;
  }

  if (isShopByUniqueCode(uniqueCode)) {
    return ShoppingMallName.SHOPBY;
  }

  throw new Error(`Unknown ShoppingMall Unique Code: ${uniqueCode}`);
}
