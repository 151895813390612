import { CombinedShipping, Order } from '@sweep/contract';
import { partition } from '@sweep/utils';
import { isOrderInCombinedShipping } from './isOrderInCombinedShipping';

export function partitionByCombinable(
  orders: Order[],
  combinedShippings: CombinedShipping[],
  options: {
    combineAll: boolean;
    separateAll: boolean;
  }
): [Order[], Order[]] {
  const { combineAll, separateAll } = options;

  if (combineAll || separateAll) {
    return [orders, []];
  }

  return partition(orders, (order) =>
    combinedShippings.some((combinedShipping) =>
      isOrderInCombinedShipping(order, combinedShipping)
    )
  );
}
