import { BrandLogoByName, Select } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface ShoppingMallSelectProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const ALL = 'all';

function ShoppingMallSelect({
  value: givenValue,
  onChange: givenOnChange,
}: ShoppingMallSelectProps) {
  const oms = useOMSStore();
  const shoppingMallNames = oms.shoppingMall.inquiryShoppingMallNames;

  const value = givenValue ?? ALL;
  const onChange = (value: string) => {
    if (value === ALL) {
      givenOnChange(null);
    } else {
      givenOnChange(value);
    }
  };

  return (
    <div className="gap-20px flex items-center">
      <p className="text-medium-s text-gray-500">쇼핑몰</p>
      <Select value={value} onChange={onChange}>
        <Select.Option value={ALL}>전체</Select.Option>
        {shoppingMallNames.map((name) => (
          <Select.Option value={name} key={name} className="gap-6px flex">
            <BrandLogoByName name={name} size="sm" />
            {name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default ShoppingMallSelect;
