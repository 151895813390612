import { findHeaderKeys } from 'src/services/column-mapping/findHeaderKeys';
import { migrateExcelColumnMapping } from './migrateExcelColumnMapping';
import { migrateExcelHeaderKeys } from './migrateExcelHeaderKeys';

export function selectExcelHeaderAndMapper(params: {
  supplierHeaders: string[];
  supplierColumnMapping: Record<string, string>;
  userDefaultHeaderKeys: string[];
  userDefaultColumnMapping: Record<string, string>;
}): {
  headerKeys: string[];
  columnMapping: Record<string, string>;
  headers: string[];
} {
  const {
    supplierHeaders,
    supplierColumnMapping,
    userDefaultHeaderKeys,
    userDefaultColumnMapping,
  } = params;

  const header = supplierHeaders ?? [];
  const columnMapping = supplierColumnMapping ?? {};

  if (header.length === 0) {
    const defaultHeaderKeys = userDefaultHeaderKeys;
    const defaultColumnMapping = userDefaultColumnMapping;

    const migratedHeaderKeys = migrateExcelHeaderKeys(defaultHeaderKeys);
    const migratedColumnMapping =
      migrateExcelColumnMapping(defaultColumnMapping);

    return {
      headerKeys: migratedHeaderKeys,
      columnMapping: migratedColumnMapping,
      headers: header,
    };
  }

  const headerKeys = findHeaderKeys(header, columnMapping) as string[]; // NOTE(@hungjoon): SWP-447 임시 코드

  const migratedHeaderKeys = migrateExcelHeaderKeys(headerKeys);
  const migratedColumnMapping = migrateExcelColumnMapping(columnMapping);

  return {
    headers: header,
    headerKeys: migratedHeaderKeys,
    columnMapping: migratedColumnMapping,
  };
}
