import { default as _Markdown } from 'react-markdown';
import { cva } from '@sweep/tailwind';

interface MarkdownProps {
  className?: string;
  children?: string | null;
}

export function Markdown({ className, children }: MarkdownProps) {
  return (
    <_Markdown
      className={container({ className })}
      components={{
        a: ({ href, children }) => (
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            {children}
          </a>
        ),
      }}
    >
      {processTextToSupportMultilineBreak(children ?? '')}
    </_Markdown>
  );
}

function processTextToSupportMultilineBreak(text: string) {
  const EMPTY = '\u200B';

  return (
    text
      .replace(/\r\n/g, '\n')
      // NOTE(@이지원): 각 줄을 검사하여 빈 줄이면 EMPTY로 치환
      .replace(/^(.*)$/gm, (line) => (line === '' ? EMPTY : line))
      .replace(/\n/g, '\n\n')
  );
}

const container = cva('text-medium-m text-gray-700');
