import { requestInquiry } from 'src/network/inquiry';

export async function requestShoppingMallsInquiry(
  shoppingMallNames: string[]
): Promise<string[]> {
  const responses = await Promise.all(shoppingMallNames.map(requestInquiry));

  return responses
    .filter((response) => response?.success === true)
    .map((response) => response.data);
}
