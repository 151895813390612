import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Inquiry, InquiryStatus } from '@sweep/contract';
import { Badge, BrandLogoByName, CheckIcon, SortIcon } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { formatInquiry } from '../services/formatInquiry';

interface InquiryTableProps {
  inquiries: Inquiry[];
  selectedInquiryUniqueCode: string | null;
  onSelectInquiryUniqueCode: (inquiryUniqueCode: string | null) => void;
}

function InquiryTable({
  inquiries,
  selectedInquiryUniqueCode,
  onSelectInquiryUniqueCode,
}: InquiryTableProps) {
  const [isDesc, setIsDesc] = useState(false);
  const rowRefs = useRef<Map<string, HTMLTableRowElement>>(new Map());

  const handleRowRef =
    (inquiryUniqueCode: string) => (el: HTMLTableRowElement | null) => {
      if (el != null) {
        rowRefs.current.set(inquiryUniqueCode, el);
      } else {
        rowRefs.current.delete(inquiryUniqueCode);
      }
    };

  const handleRowClick = (inquiry: Inquiry, selected: boolean) => {
    onSelectInquiryUniqueCode(selected ? null : inquiry.inquiryUniqueCode);

    // NOTE(@이지원): 상태가 업데이트 된 후, 스크롤을 해야함
    setTimeout(() => {
      const row = rowRefs.current.get(inquiry.inquiryUniqueCode);
      row?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
  };

  const sortedInquiries = [...inquiries].sort(
    (a, b) => (b.inquiryDate - a.inquiryDate) * (isDesc ? 1 : -1)
  );

  return (
    <div
      className={tableContainer({
        selected: selectedInquiryUniqueCode != null,
      })}
    >
      <table className="w-full min-w-max">
        <thead className={headerRow()}>
          <TableHeaderBorderRow />
          <tr>
            <th className={headerCell({ type: 'check' })}></th>
            <th className={headerCell({ type: 'badge' })}></th>
            <th className={headerCell({ type: 'date-text' })}>
              <div
                className="gap-4px flex cursor-pointer items-center"
                onClick={() => setIsDesc(!isDesc)}
              >
                <p>접수일시</p>
                <SortIcon sort={isDesc ? 'DESC' : 'ASC'} />
              </div>
            </th>
            <th className={headerCell({ type: 'text' })}>쇼핑몰</th>
            <th className={headerCell({ type: 'text' })}>문의유형</th>
            <th className={headerCell({ type: 'text' })}>상품명</th>
            <th className={headerCell({ type: 'text' })}>문의제목</th>
            <th className={headerCell({ type: 'text' })}>주문자명</th>
            <th className={headerCell({ type: 'text' })}>주문번호</th>
          </tr>
          <TableHeaderBorderRow />
        </thead>
        <tbody>
          {sortedInquiries.map((inquiry) => {
            const { inquiryUniqueCode, inquiryStatus, shoppingMall } = inquiry;
            const selected =
              selectedInquiryUniqueCode === inquiry.inquiryUniqueCode;
            const formattedInquiry = formatInquiry(inquiry);

            return (
              <tr
                key={inquiryUniqueCode}
                ref={handleRowRef(inquiryUniqueCode)}
                className={row({ selected })}
                onClick={() => handleRowClick(inquiry, selected)}
              >
                <td className={cell({ type: 'check' })}>
                  <CheckIcon checked={selected} size="md" />
                </td>
                <td className={cell({ type: 'badge' })}>
                  <InquiryStatusBadge status={inquiryStatus} />
                </td>
                <td className={cell({ type: 'date-text' })}>
                  {formattedInquiry.date}
                </td>
                <td className={cell({ type: 'text' })}>
                  <div className="gap-6px flex items-center">
                    <BrandLogoByName name={shoppingMall} />
                    {shoppingMall}
                  </div>
                </td>
                <td className={cell({ type: 'text' })}>
                  {formattedInquiry.categoryType}
                </td>
                <td className={cell({ type: 'text' })}>
                  {formattedInquiry.productName}
                </td>
                <td className={cell({ type: 'text' })}>
                  {formattedInquiry.title}
                </td>
                <td className={cell({ type: 'text' })}>
                  {formattedInquiry.buyerName}
                </td>
                <td className={cell({ type: 'text' })}>
                  {formattedInquiry.orderNumber}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function InquiryStatusBadge({ status }: { status: InquiryStatus }) {
  switch (status) {
    case 'ANSWERED':
      return (
        <Badge color="blue" variant="soft" size="sm">
          답변완료
        </Badge>
      );
    case 'UNANSWERED':
      return (
        <Badge color="orange" variant="soft" size="sm">
          미답변
        </Badge>
      );
  }
}

function TableHeaderBorderRow() {
  return (
    <tr className="h-1px bg-gray-200">
      {Array.from({ length: 9 }).map((_, index) => (
        <td key={index} className="p-0"></td>
      ))}
    </tr>
  );
}

const tableContainer = cva('overflow-auto', {
  variants: {
    selected: {
      true: 'h-360px',
      false: 'max-h-[calc(100vh-400px)]',
    },
  },
});

const headerRow = cva('sticky top-0 z-10');

const headerCell = cva('text-semibold-s h-44px bg-gray-100 text-gray-700', {
  variants: {
    type: {
      check: 'w-44px',
      badge: 'w-64px',
      'date-text': 'w-180px pl-20px text-left',
      text: 'px-20px text-left',
    },
  },
});

const row = cva('cursor-pointer hover:bg-gray-100', {
  variants: {
    selected: {
      true: 'bg-gray-100',
      false: '',
    },
  },
});

const cell = cva('max-w-400px h-44px truncate border-b border-gray-200', {
  variants: {
    type: {
      check: 'pl-16px w-44px',
      badge: 'w-64px flex-center flex',
      'date-text': 'w-180px pl-20px text-medium-s text-gray-700',
      text: 'px-20px text-medium-s text-gray-700',
    },
  },
});

export default observer(InquiryTable);
