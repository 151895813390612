import copy from 'fast-copy';
import { NormalizedOrder, Order, OrderShippingStatus } from '@sweep/contract';
import { createUniqueCodeSubV2 } from '@sweep/domain/services/order';
import { upsertNormalizedOrders } from 'src/network/order/order';
import { StoreOriginValuePlugin } from 'src/stores/plugin/default/store-origin-value';
import { AddSupplierPlugin } from 'src/stores/plugin/features/add-supplier';
import { AddSupplierNamePlugin } from 'src/stores/plugin/features/add-supplier-name/AddSupplierNamePlugin';
import {
  CompositionMatchingPlugin,
  CompositionMatchingStage,
} from 'src/stores/plugin/features/composition-matching';
import { FormatProductNamePlugin } from 'src/stores/plugin/features/format-product-name';
import { OrderXX } from '../models/OrderXX';
import useCombineOrder from './fileHandling/interpretOrder/useCombineOrder';
import usePreprocessOrder from './fileHandling/interpretOrder/usePreprocessOrder';
import { useApplyCustomSettings } from './fileHandling/useApplyCustomSettings';
import { useOMSStore } from './useOMSStore';

const useFileHandling = () => {
  const oms = useOMSStore();

  const {
    addPlatformName,
    applyPrefixToOption,
    applyFieldValueToAnother,
    applyMergedCellValues,
    sortOrders,
  } = useApplyCustomSettings();
  const { preprocessOrders } = usePreprocessOrder();
  const { hightlightCombinedOrders } = useCombineOrder();

  const normalizeOrders = async (orders: OrderXX[]) => {
    if (orders.length === 0) {
      return [];
    }

    const updatedOrders = await oms.loading.batch(async () => {
      const newOrders = await processNormalizeOrders(orders, {
        stage: 'normalize',
        initialProcess: 'pending',
        initialShippingStatus: 'pending',
      });

      const sortedOrders = sortOrders([
        ...newOrders,
        ...oms.order.normalizedOrders,
      ] as OrderXX[]);

      oms.order.setNormalizedOrders(sortedOrders as NormalizedOrder[]);
      await upsertNormalizedOrders(sortedOrders as NormalizedOrder[]);

      return sortedOrders;
    });

    return updatedOrders;
  };

  const processNormalizeOrders = async (
    orders: OrderXX[],
    options?: {
      stage?: CompositionMatchingStage;
      initialProcess?: 'shipping' | 'pending';
      initialShippingStatus?: OrderShippingStatus;
    }
  ) => {
    const stage = options?.stage ?? 'normalize';
    const initialProcess = options?.initialProcess ?? 'pending';
    const initialShippingStatus = options?.initialShippingStatus ?? 'pending';

    if (orders.length === 0) {
      return [];
    }

    let newOrders = copy(orders);

    // TODO(@이지원): 커스텀 로직이 플러그인화 되면, default plugin으로 추가
    const storeOriginValuePlugin = new StoreOriginValuePlugin(oms, undefined);
    newOrders = (await storeOriginValuePlugin.transform(
      newOrders as Order[]
    )) as OrderXX[];

    newOrders = await preprocessOrders(newOrders);

    newOrders = (await new CompositionMatchingPlugin(oms, { stage }).transform(
      newOrders as Order[]
    )) as OrderXX[];

    // TODO(@이지원): 커스텀 로직이 플러그인화 되면, default plugin으로 추가
    newOrders = (await new AddSupplierPlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];
    newOrders = (await new AddSupplierNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];

    newOrders = hightlightCombinedOrders(newOrders);
    newOrders = addPlatformName(newOrders);
    newOrders = (await new FormatProductNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];
    newOrders = applyMergedCellValues(newOrders);
    newOrders = applyFieldValueToAnother(newOrders);
    newOrders = applyPrefixToOption(newOrders);

    newOrders = sortOrders(newOrders);

    newOrders = (await oms.order._normalize.normalizeOrders(
      newOrders as unknown as Order[]
    )) as unknown as OrderXX[];

    if (
      oms.user.setting?.interpreteOrderSettings?.useOptionCodeAsRawProductName
    ) {
      newOrders = newOrders.map((order) => {
        if (!order?.needToInterpret) {
          order.option = order.optionCode || order.option;
        }
        return order;
      });
    }

    newOrders = (newOrders as Order[]).map<NormalizedOrder>((order) => ({
      ...order,
      uniqueCodeAfterCustomization:
        order.uniqueCodeAfterCustomization ?? createUniqueCodeSubV2(order),
      process: initialProcess,
      shippingStatus: initialShippingStatus,
    })) as OrderXX[];

    return newOrders;
  };

  return {
    processNormalizeOrders,
    normalizeOrders,
  };
};

export default useFileHandling;
