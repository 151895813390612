import {
  CreatePurchaseOrderFileRecordDTO,
  ExcelFileRecord,
  NormalizedOrder,
  UserSetting,
} from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { migrateExcelColumnMapping } from './partials/migrateExcelColumnMapping';
import { migrateExcelHeaderKeys } from './partials/migrateExcelHeaderKeys';

export function createPurchaseOrderFileRecordForUnseparatedOrders(params: {
  orders: NormalizedOrder[];
  oms: OMSStore;
  today: Date;
  groupId: string;
  groupName: string;
}): CreatePurchaseOrderFileRecordDTO {
  const { orders, oms, today, groupId, groupName } = params;

  const filename = formatDate(today, 'yy.MM.dd 발주 분리 실패주문');

  // NOTE(@hungjoon): SWP-447 임시 코드
  const migratedExcelColumnMapping = migrateExcelColumnMapping(
    oms.user.excelColumnMapping
  );
  const migratedExcelHeaderKeys = migrateExcelHeaderKeys(
    oms.user.excelHeaderKeys
  );
  // NOTE(@hungjoon): SWP-447 임시 코드
  const userCustomSettings = oms.user.setting as UserSetting;

  const excelFileRecord: ExcelFileRecord = {
    filename,
    fileExtension: 'xlsx',
    orders,
    headers: [],
    headerKeys: migratedExcelHeaderKeys,
    columnMappings: migratedExcelColumnMapping,
    separateAddressEnabled:
      userCustomSettings?.preprocessSettings?.separateAddress?.enabled === true,
    userExcelSettingFields: userCustomSettings?.excel?.fields,
  };

  const record: CreatePurchaseOrderFileRecordDTO = {
    groupId,
    groupName,
    excelFileRecord,
  };

  return record;
}
