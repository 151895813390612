import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { IconControlDownload } from '@sweep/asset/icons';
import { NormalizedOrder } from '@sweep/contract';
import {
  Button,
  SearchInput,
  SelectableTable,
  TableCount,
  TableSelectControl,
} from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { createOrderExcel } from 'src/services/file/excel/create';
import { toast } from 'src/third-parties/toast';
import { useTableFilterColumns } from '../hooks/useTableFilterColumns';

function OrderTotalSection() {
  const oms = useOMSStore();

  const normalizedOrders = oms.order.normalizedOrders;
  const [search, setSearch, filterSearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });

  const searchFilteredOrders = useMemo(
    () => filterSearch(normalizedOrders),
    [filterSearch, normalizedOrders]
  );

  const isUploadHandWrittenFile =
    oms.user.setting?.uploadHandWrittenFile === true;
  const { columns, filteredOrders } = useTableFilterColumns({
    orders: searchFilteredOrders,
    useUniqueCodeSub: !isUploadHandWrittenFile, // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
  });

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);
  const currentOrders =
    selectedOrders.length > 0 ? selectedOrders : filteredOrders;

  const download = () => {
    const isOrderEmpty = currentOrders.length === 0;
    if (isOrderEmpty) {
      toast.error('선택된 주문이 없습니다.');
      return;
    }

    createOrderExcel(
      oms,
      currentOrders,
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );
  };

  return (
    <div className="p-20px rounded-8px shadow-line gap-16px flex flex-col bg-white">
      <SearchInput
        value={search}
        onChange={setSearch}
        placeholder="검색어를 입력해주세요."
        className="max-w-400px"
      />
      <hr />
      <div className="flex flex-col">
        <TableCount count={filteredOrders.length} />
        <div className="flex items-end justify-between">
          <TableSelectControl
            count={selectedOrders.length}
            onClear={() => setSelectedOrders([])}
          />
          <Button
            color="gray"
            rightAccessory={<IconControlDownload />}
            onClick={download}
          >
            엑셀 파일
          </Button>
        </div>
      </div>
      <If is={normalizedOrders.length > 0}>
        <SelectableTable
          items={filteredOrders}
          columns={columns}
          selectedItems={selectedOrders}
          onSelect={setSelectedOrders}
          getKey={(order) => order.uniqueCodeAfterCustomization}
          className="max-h-[calc(100vh-300px)]"
        />
      </If>
    </div>
  );
}

export default observer(OrderTotalSection);
