import { useState } from 'react';
import { ShippingCompany } from '@sweep/contract';
import { Button, Modal, Select, TextInput } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

export interface ShippingInvoice {
  shippingCompany: ShippingCompany;
  shippingNumber: string;
}

interface ShippingUploadFormModalProps {
  open: boolean;
  onSubmit: (shippingInvoice: ShippingInvoice | null) => void;
}

function ShippingUploadFormModal({
  open,
  onSubmit,
}: ShippingUploadFormModalProps) {
  const [showError, setShowError] = useState(false);

  const [shippingCompany, setShippingCompany] = useState<
    ShippingCompany | undefined
  >(undefined);
  const [shippingNumber, setShippingNumber] = useState<string>('');

  const onClose = () => onSubmit(null);

  const handleClick = () => {
    if (shippingCompany == null || shippingNumber === '') {
      setShowError(true);
      return;
    }

    onSubmit({ shippingCompany, shippingNumber });
  };

  const showShippingCompanyError = showError && shippingCompany == null;
  const showShippingNumberError = showError && shippingNumber === '';

  const familiarShippingCompanies: ShippingCompany[] = [
    ShippingCompany.CJ,
    ShippingCompany.LOTTE,
    ShippingCompany.HANJIN,
    ShippingCompany.POST_OFFICE,
    ShippingCompany.LOGEN,
  ];
  const shippingCompanies: ShippingCompany[] = [
    ...familiarShippingCompanies,
    ...Object.values(ShippingCompany)
      .filter(
        (value) =>
          ![...familiarShippingCompanies, ShippingCompany.ETC].includes(value)
      )
      .sort((value1, value2) => value1.localeCompare(value2)),
    ShippingCompany.ETC,
  ];

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <div className="gap-12px mb-20px flex flex-col">
        <div className={inputContainer()}>
          <p className={label()}>배송업체</p>
          <Select
            value={shippingCompany}
            onChange={(value) => setShippingCompany(value as ShippingCompany)}
            status={showShippingCompanyError ? 'error' : 'default'}
            helperText={
              showShippingCompanyError ? '배송업체를 선택해주세요.' : ''
            }
            placeholder="선택"
            className="w-full"
          >
            {shippingCompanies.map((company) => (
              <Select.Option key={company} value={company}>
                {company}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={inputContainer()}>
          <p className={label()}>운송장 번호</p>
          <TextInput
            value={shippingNumber}
            onChange={setShippingNumber}
            status={showShippingNumberError ? 'error' : 'default'}
            helperText={
              showShippingNumberError ? '운송장 번호를 입력해주세요.' : ''
            }
          />
        </div>
      </div>
      <div className="gap-12px flex">
        <Modal.Close className="flex-1" color="lightGray">
          취소하기
        </Modal.Close>
        <Button className="flex-1" onClick={handleClick}>
          등록하기
        </Button>
      </div>
    </Modal>
  );
}

const inputContainer = cva('gap-8px flex flex-col');

const label = cva('text-medium-m text-gray-700');

export default ShippingUploadFormModal;
