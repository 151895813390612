import { z } from 'zod';
import { ExcelFileRecordSchema } from './ExcelFileRecord';

export const PurchaseOrderFileRecordSchema = z.object({
  _id: z.string(),
  userId: z.string(),
  supplierId: z.string().optional(),
  groupId: z.string().uuid(),
  groupName: z.string(),
  excelFileRecord: ExcelFileRecordSchema,
  createdAt: z.string(),
});

export type PurchaseOrderFileRecord = z.infer<
  typeof PurchaseOrderFileRecordSchema
>;
