import { round } from './round';
import { sum } from './sum';

export function divide(total: number, ratios: number[]): number[] {
  if (ratios.some((ratio) => ratio < 0)) {
    throw new Error('비율은 0 이상이어야 합니다');
  }

  if (ratios.length === 0) {
    return [];
  }

  if (ratios.length === 1) {
    return [total];
  }

  const ratiosSum = sum(ratios);
  const divided = ratios.map((ratio) => round((total * ratio) / ratiosSum));

  const totalSum = sum(divided);
  const diff = total - totalSum;
  divided[divided.length - 1] += diff;

  return divided;
}
