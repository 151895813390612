import {
  CreatePurchaseOrderFileRecordDTO,
  PurchaseOrderFileRecord,
} from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function requestCreatePurchaseOrderFileRecord(
  params: CreatePurchaseOrderFileRecordDTO
): Promise<PurchaseOrderFileRecord | null> {
  const response = await api.post<APIResponse<PurchaseOrderFileRecord>>(
    '/purchase-order-file-records',
    params
  );

  if (response?.data?.success === true && response.data.data != null) {
    return response.data.data;
  }

  return null;
}
