import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { IconAddExcel, IconControlUpload } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { Button } from '@sweep/sds';
import { IconCircleDeleteSmall, IconTrashcan } from '@sweep/asset/icons';
import ScrollGradient from './ScrollGradient';

interface FileListProps {
  filenames: string[];
  onUpload: (files: File[]) => void;
  onRemove: (index: number) => void;
  onRemoveAll: () => void;
}

function UploadedFileList({
  filenames,
  onUpload,
  onRemove,
  onRemoveAll,
}: FileListProps) {
  const [showLeftGradient, setShowLeftGradient] = useState(false);
  const [showRightGradient, setShowRightGradient] = useState(true);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const OFFSET = 5;
    setShowLeftGradient(target.scrollLeft > OFFSET);
    setShowRightGradient(
      target.scrollWidth - target.clientWidth > target.scrollLeft + OFFSET
    );
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }

    onUpload(Array.from(files));
    event.target.value = '';
  };

  return (
    <div className="px-24px py-20px rounded-8px gap-16px flex bg-white items-center shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
      <p className="text-medium-s text-gray-500">
        주문 파일 (
        <span className="text-semibold-s text-blue-500">
          {filenames.length}
        </span>
        개)
      </p>
      <div className="relative flex min-w-0 flex-1">
        <div
          onScroll={handleScroll}
          className="gap-10px no-scrollbar pr-10px flex flex-1 overflow-auto"
        >
          {filenames.map((filename, index) => (
            <div key={index} className={fileContainer()}>
              <IconAddExcel className="text-gray-400" />
              <p className="text-semibold-s whitespace-nowrap text-gray-700">
                {filename}
              </p>
              <button onClick={() => onRemove(index)} className={fileButton()}>
                <IconCircleDeleteSmall />
              </button>
            </div>
          ))}
        </div>
        {showLeftGradient && <ScrollGradient direction="left" />}
        {showRightGradient && <ScrollGradient direction="right" />}
      </div>
      <button
        className="size-36px rounded-8px flex-center flex bg-gray-100"
        onClick={onRemoveAll}
      >
        <IconTrashcan className="text-gray-500" />
      </button>
      <Button
        rightAccessory={<IconControlUpload />}
        color="gray"
        className="w-fit bg-gray-600"
        asChild
      >
        <label>
          주문 파일 업로드
          <input
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept=".xlsx, .xls, .csv"
            multiple
          />
        </label>
      </Button>
    </div>
  );
}

const fileContainer = cva([
  'h-40px px-12px rounded-8px group relative flex items-center',
  'gap-6px bg-gray-100 transition duration-150',
]);

const fileButton = cva(
  'z-100 absolute right-0 top-1 hidden -translate-y-1 translate-x-1/2 group-hover:block'
);

export default observer(UploadedFileList);
