import { Link, useLocation } from 'react-router-dom';
import { Badge } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

interface SidebarItemProps {
  href: string;
  beta?: boolean;
  leftAccessory?: React.ReactNode;
  children: React.ReactNode;
}

export function SidebarItem({
  href,
  beta = false,
  leftAccessory,
  children,
}: SidebarItemProps) {
  const { pathname } = useLocation();
  const active = pathname === href;

  return (
    <Link to={href} className={container({ active })}>
      {leftAccessory != null && (
        <div className={icon({ active })}>{leftAccessory}</div>
      )}
      {children}
      {beta && (
        <Badge variant={active ? 'outline' : 'soft'} color="blue" size="sm">
          Beta
        </Badge>
      )}
    </Link>
  );
}

const container = cva(
  'text-semibold-s px-20px gap-12px rounded-8px flex h-[39px] w-full items-center',
  {
    variants: {
      active: {
        true: 'bg-blue-10 text-blue-600',
        false: 'hover:bg-blue-10 text-gray-700',
      },
    },
  }
);

const icon = cva('', {
  variants: {
    active: {
      true: 'text-blue-600',
      false: 'text-gray-400',
    },
  },
});
