import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openCMRequriedDialog() {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-16px">
          상품 구성 매칭이 안 된 주문이 있어요.
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          모든 매칭이 완료되어야 발주서를 만들 수 있습니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>매칭하기</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
