import { PurchaseOrderFileRecord } from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function requestFindPurchaseOrderFileRecordWithShareLink(
  id: string,
  code: string
): Promise<PurchaseOrderFileRecord | null> {
  const response = await api.get<APIResponse<PurchaseOrderFileRecord>>(
    `/file-share-link/type/purchase-order-file?id=${id}&code=${code}`
  );

  if (response?.data?.success === true && response.data.data != null) {
    return response.data.data;
  }

  return null;
}
