import { observer } from 'mobx-react-lite';
import { IconErrorLarge, IconSuccessLarge } from '@sweep/asset/icons';
import { BrandLogoByName } from '@sweep/sds';
import { SwitchCase } from '@sweep/utils/react';
import { LoadingState } from '../../store/DispatchModalStore';
import { LoadingLottie } from './LoadingLottie';

interface MallLoadingProps {
  status: LoadingState;
  shoppingMallName: string;
}

function MallLoading({ status, shoppingMallName }: MallLoadingProps) {
  return (
    <div className="flex items-center justify-between rounded-[8px] bg-white p-1">
      <div className="flex min-w-0 items-center gap-[6px] px-[9px]">
        <BrandLogoByName name={shoppingMallName} />
        <p className="text-gray700 text-ellipsis-nowrap text-medium-s">
          {shoppingMallName}
        </p>
      </div>
      <SwitchCase
        value={status}
        caseBy={{
          loading: <LoadingLottie />,
          fail: <IconErrorLarge />,
          success: <IconSuccessLarge />,
        }}
      />
    </div>
  );
}

export default observer(MallLoading);
