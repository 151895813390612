import {
  Middleware,
  MiddlewareData,
  ReferenceElement,
} from '@floating-ui/react';
import { useEffect } from 'react';

export const HIDE_ON_VISIBILITY = 'hide-on-visibility';
const OFFSET = 1;

export const hideOnVisibility = (): Middleware => ({
  name: HIDE_ON_VISIBILITY,
  fn({ elements, rects }) {
    const rect = rects.reference;
    const top = { x: rect.x + rect.width / 2, y: rect.y + OFFSET };
    const bottom = {
      x: rect.x + rect.width / 2,
      y: rect.y + rect.height - OFFSET,
    };
    const left = { x: rect.x + OFFSET, y: rect.y + rect.height / 2 };
    const right = {
      x: rect.x + rect.width - OFFSET,
      y: rect.y + rect.height / 2,
    };
    const center = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };

    const points = [top, bottom, left, right, center];
    const referenceVisible = points.some((point) =>
      getReferenceVisible(
        point.x,
        point.y,
        elements.floating,
        elements.reference
      )
    );

    return {
      data: {
        referenceVisible,
      },
    };
  },
});

function getReferenceVisible(
  x: number,
  y: number,
  floating: HTMLElement,
  reference: ReferenceElement
) {
  const elements = document.elementsFromPoint(x, y);
  const filteredElements = elements.filter(
    (element) => !floating.contains(element)
  );
  const topElement = filteredElements[0];
  if ('contains' in reference) {
    return reference.contains(topElement);
  }

  console.warn('[hideOnVisibility] reference is not an HTMLElement');
  return true;
}

export function useHideOnVisibility(
  middlewareData: MiddlewareData,
  onOpenChange: (isOpen: boolean) => void
) {
  useEffect(() => {
    if (isReferenceVisible(middlewareData)) {
      onOpenChange(false);
    }
  }, [middlewareData, onOpenChange]);
}

function isReferenceVisible(middlewareData: MiddlewareData) {
  return middlewareData[HIDE_ON_VISIBILITY]?.referenceVisible === false;
}
