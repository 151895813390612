import { offset } from '@floating-ui/react';
import { observer } from 'mobx-react-lite';
import { Fragment, useRef, useState } from 'react';
import { IconControlArrowDown } from '@sweep/asset/icons';
import {
  CheckIcon,
  maxHeightOnViewport,
  SearchInput,
  useDialog,
} from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OMSStore } from 'src/stores/OMSStore';
import { SalesChannel } from '../../interface';

interface SalesChannelsInputProps {
  value: SalesChannel[];
  onChange: (value: SalesChannel[]) => void;
  status?: 'default' | 'error';
}

function SalesChannelsInput({
  value,
  onChange,
  status = 'default',
}: SalesChannelsInputProps) {
  const oms = useOMSStore();
  const partners = oms.partner.partners;
  const error = status === 'error' && value.length === 0;

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const { refs, getFloatingProps, floatingStyles } = useDialog({
    open,
    onOpenChange: setOpen,
    placement: 'bottom',
    reference: ref,
    middleware: [offset(6), maxHeightOnViewport(320)],
    dismiss: { ancestorScroll: true },
  });

  const searchFilteredPartners = partners.filter((partner) =>
    partner.name.includes(search)
  );
  const searchShoppingMalls = oms.shoppingMall.shoppingMalls.filter(
    (shoppingMall) => shoppingMall.name.includes(search)
  );

  const isPartnerChecked = (partnerId: string) =>
    value.some((v) => v.type === 'partner' && v.id === partnerId);

  const handlePartnerClick = (partnerId: string) => {
    if (isPartnerChecked(partnerId)) {
      onChange(value.filter((v) => v.type !== 'partner' || v.id !== partnerId));
      return;
    }

    onChange([...value, { type: 'partner', id: partnerId }]);
  };

  const isShoppingMallChecked = (shoppingMallId: string) =>
    value.some((v) => v.type === 'shoppingMall' && v.id === shoppingMallId);

  const handleShoppingMallClick = (shoppingMallId: string) => {
    if (isShoppingMallChecked(shoppingMallId)) {
      onChange(
        value.filter(
          (v) => v.type !== 'shoppingMall' || v.id !== shoppingMallId
        )
      );
      return;
    }

    onChange([...value, { type: 'shoppingMall', id: shoppingMallId }]);
  };

  const isAllChecked =
    searchFilteredPartners.every((partner) => isPartnerChecked(partner._id)) &&
    searchShoppingMalls.every((mall) => isShoppingMallChecked(mall._id));

  const handleAllClick = () => {
    if (isAllChecked) {
      if (search.length === 0) {
        onChange([]);
        return;
      }

      const searchPartnerIdSet = new Set(
        searchFilteredPartners.map((partner) => partner._id)
      );
      const searchShoppingMallIdSet = new Set(
        searchShoppingMalls.map((mall) => mall._id)
      );

      onChange(
        value.filter(
          (v) =>
            !(v.type === 'partner' && searchPartnerIdSet.has(v.id)) &&
            !(v.type === 'shoppingMall' && searchShoppingMallIdSet.has(v.id))
        )
      );
      return;
    }

    const notCheckedPartners = searchFilteredPartners.filter(
      (partner) => !isPartnerChecked(partner._id)
    );
    const notCheckedShoppingMalls = searchShoppingMalls.filter(
      (mall) => !isShoppingMallChecked(mall._id)
    );

    onChange([
      ...value,
      ...notCheckedPartners.map<SalesChannel>((partner) => ({
        type: 'partner',
        id: partner._id,
      })),
      ...notCheckedShoppingMalls.map<SalesChannel>((mall) => ({
        type: 'shoppingMall',
        id: mall._id,
      })),
    ]);
  };

  const label = getLabel(oms, value);

  return (
    <>
      <div
        ref={ref}
        onClick={() => setOpen(!open)}
        className={valueContainer({
          status: error ? 'error' : 'default',
          focused: open,
        })}
      >
        <p className={valueClass({ placeholder: label == null })}>
          {label ?? '판매처 선택'}
        </p>
        <IconControlArrowDown className="text-gray-700" />
      </div>
      <If is={open}>
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={floatingContainer()}
          {...getFloatingProps()}
        >
          <SearchInput
            value={search}
            onChange={(value) => setSearch(value)}
            placeholder="검색"
            className="shrink-0"
          />
          <div className={optionContainer()}>
            <div
              className={option({ checked: isAllChecked })}
              onClick={handleAllClick}
            >
              <CheckIcon checked={isAllChecked} size="sm" />
              <p className="text-gray-400">모두 선택</p>
            </div>
            {searchShoppingMalls.map((mall) => (
              <Fragment key={mall._id}>
                <hr />
                <div
                  className={option({
                    checked: isShoppingMallChecked(mall._id),
                  })}
                  onClick={() => handleShoppingMallClick(mall._id)}
                >
                  <CheckIcon
                    checked={isShoppingMallChecked(mall._id)}
                    size="sm"
                  />
                  <p className="text-ellipsis-nowrap text-gray-700">
                    {mall.name}
                  </p>
                </div>
              </Fragment>
            ))}
            {searchFilteredPartners.map((partner) => (
              <Fragment key={partner._id}>
                <hr />
                <div
                  className={option({ checked: isPartnerChecked(partner._id) })}
                  onClick={() => handlePartnerClick(partner._id)}
                >
                  <CheckIcon
                    checked={isPartnerChecked(partner._id)}
                    size="sm"
                  />
                  <p className="text-ellipsis-nowrap text-gray-700">
                    {partner.name}
                  </p>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </If>
    </>
  );
}

function getLabel(oms: OMSStore, value: SalesChannel[]): string | null {
  if (value.length === 0) {
    return null;
  }

  const names = value.map(
    (v) => getLabelBySalesChannel(oms, v) ?? '알 수 없음'
  );

  return names.join(', ');
}

function getLabelBySalesChannel(
  oms: OMSStore,
  value: SalesChannel
): string | undefined {
  switch (value.type) {
    case 'partner': {
      const partner = oms.partner.getPartnerById(value.id);
      return partner?.name;
    }
    case 'shoppingMall': {
      const shoppingMall = oms.shoppingMall.shoppingMalls.find(
        (shoppingMall) => shoppingMall._id === value.id
      );
      return shoppingMall?.name;
    }
  }
}

const valueContainer = cva(
  [
    'h-40px px-16px flex min-w-0 items-center justify-between',
    'rounded-8px border',
    'cursor-pointer select-none',
  ],
  {
    variants: {
      status: {
        default: '',
        error: '',
      },
      focused: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        status: 'default',
        focused: true,
        className: 'border-blue-500',
      },
      {
        status: 'error',
        focused: true,
        className: 'border-blue-500',
      },
      {
        status: 'default',
        focused: false,
        className: 'border-gray-300',
      },
      {
        status: 'error',
        focused: false,
        className: 'border-red-500',
      },
    ],
  }
);

const valueClass = cva('text-medium-s text-ellipsis-nowrap', {
  variants: {
    placeholder: {
      true: 'text-gray-400',
      false: 'text-gray-700',
    },
  },
});

const floatingContainer = cva([
  'gap-8px flex flex-col',
  'shadow-box p-16px rounded-8px border border-gray-300 bg-white',
]);

const optionContainer = cva(
  'rounded-8px flex flex-1 flex-col overflow-auto border border-gray-300'
);

const option = cva(
  'px-12px gap-4px h-36px text-medium-s flex shrink-0 cursor-pointer items-center',
  {
    variants: {
      checked: {
        true: 'bg-gray-100',
        false: '',
      },
    },
  }
);

export default observer(SalesChannelsInput);
