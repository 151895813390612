import { action, observable, runInAction } from 'mobx';
import {
  CombinedShipping,
  CreateCombinedShippingDTO,
  UpdateCombinedShippingDTO,
} from '@sweep/contract';
import {
  createCombinedShipping,
  deleteCombinedShipping,
  getCombinedShippings,
  updateCombinedShipping,
} from 'src/network/combinedShipping';
import { OMSStore } from './OMSStore';

export class CombinedShippingStore {
  @observable
  accessor combinedShippings: CombinedShipping[] = [];

  hasCombinedShipping(productId: string, option?: string) {
    return this.combinedShippings.some((combinedShipping) =>
      combinedShipping.products.some(
        (product) =>
          product.productId === productId &&
          (option == null || product.option === option)
      )
    );
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await this.loadCombinedShippings();
  }

  @action.bound
  async loadCombinedShippings() {
    const response = await getCombinedShippings();
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.combinedShippings = response.data;
    });
  }

  @action.bound
  async create(dto: CreateCombinedShippingDTO) {
    const response = await createCombinedShipping(dto);
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.combinedShippings.push(response.data);
    });
  }

  @action.bound
  async createMany(dto: CreateCombinedShippingDTO[]) {
    await Promise.all(dto.map((d) => this.create(d)));
  }

  async update(id: string, dto: UpdateCombinedShippingDTO) {
    const index = this.combinedShippings.findIndex(
      (combinedShipping) => combinedShipping._id === id
    );
    if (index === -1) {
      return;
    }

    this.combinedShippings[index] = {
      ...this.combinedShippings[index],
      ...dto,
    };

    await updateCombinedShipping(id, dto);
  }

  @action.bound
  async _update(id: string, dto: UpdateCombinedShippingDTO | null) {
    const index = this.combinedShippings.findIndex(
      (combinedShipping) => combinedShipping._id === id
    );
    if (index === -1) {
      return;
    }

    if (dto === null) {
      this.combinedShippings.splice(index, 1);
    } else {
      this.combinedShippings[index] = {
        ...this.combinedShippings[index],
        ...dto,
      };
    }
  }

  @action.bound
  async delete(id: string) {
    this.combinedShippings = this.combinedShippings.filter(
      (combinedShipping) => combinedShipping._id !== id
    );

    await deleteCombinedShipping(id);
  }
}
