import { offset, useDismiss, useFloating } from '@floating-ui/react';
import { ReactNode, useState } from 'react';
import { IconControlArrowDown } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { Separated } from '@sweep/utils/react';
import { MultiSelectContextProvider } from './MultiSelectContext';
import MultiSelectMultiOption from './MultiSelectMultiOption';
import MultiSelectOption from './MultiSelectOption';

interface MultiSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  children?: ReactNode;
}

function MultiSelect({
  value,
  onChange,
  label,
  placeholder = '선택',
  className,
  children,
}: MultiSelectProps) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);
  const isValueEmpty = value.length === 0;

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange: setOpen,
    middleware: [offset(6)],
  });

  useDismiss(context, {
    outsidePress: true,
  });

  return (
    <MultiSelectContextProvider value={{ open, value, onChange }}>
      <div
        ref={refs.setReference}
        className={container({ open, isValueEmpty, className })}
        onClick={toggle}
      >
        <p className={labelClass({ isValueEmpty })}>
          {isValueEmpty ? placeholder : (label ?? value.join(', '))}
        </p>
        <IconControlArrowDown className="text-gray-700" />
        {open && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={optionContainer()}
          >
            <Separated with={<hr className={divider()} />}>
              {children}
            </Separated>
          </div>
        )}
      </div>
    </MultiSelectContextProvider>
  );
}

const container = cva(
  [
    'relative cursor-pointer',
    'flex h-[40px] items-center px-[16px]',
    'rounded-[8px] border bg-white',
  ],
  {
    variants: {
      open: {
        true: 'border-blue-500',
        false: 'border-gray-300',
      },
      isValueEmpty: {
        true: '',
        false: 'border-blue-500',
      },
    },
  }
);

const labelClass = cva(
  'text-medium-s flex-1 select-none overflow-hidden text-ellipsis text-nowrap',
  {
    variants: {
      isValueEmpty: {
        true: 'text-gray-400',
        false: 'text-gray-700',
      },
    },
  }
);

const optionContainer = cva([
  'flex max-h-[250px] w-full flex-col',
  'rounded-[8px] border border-gray-300 bg-white',
  'z-dropdown overflow-y-scroll shadow-[0_4px_12px_0_rgba(0,0,0,0.12)]',
]);

const divider = cva('h-px shrink-0 border-0 bg-gray-200');

MultiSelect.Option = MultiSelectOption;
MultiSelect.MultiOption = MultiSelectMultiOption;

export default MultiSelect;
