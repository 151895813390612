import { Product } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';

export function checkCombinedShippingRemoval(
  oms: OMSStore,
  prevProduct: Product,
  product: Product
) {
  const isProductNameChanged = prevProduct.productName !== product.productName;
  const hasCombinedShipping = oms.combinedShipping.hasCombinedShipping(
    prevProduct._id
  );
  if (isProductNameChanged && hasCombinedShipping) {
    return true;
  }

  const prevOptionNames = prevProduct.units?.map((unit) => unit.unit);
  const optionNames = product.units?.map((unit) => unit.unit);

  const deletedOptionNames =
    prevOptionNames?.filter(
      (optionName) => optionNames == null || !optionNames.includes(optionName)
    ) ?? [];

  return deletedOptionNames.some((optionName) =>
    oms.combinedShipping.hasCombinedShipping(prevProduct._id, optionName)
  );
}
