import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { Modal } from '@sweep/sds';
import { SwitchCase } from '@sweep/utils/react';
import { openConfirmDialog } from 'src/components/openConfirmDialog';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { amplitude } from 'src/third-parties/amplitude';
import { DoneRecordSection } from './components/DoneRecordSection';
import { PendingRecordSection } from './components/PendingRecordSection';
import { SendingRecordSection } from './components/SendingRecordSection';
import { EmailSendStatus, useEmailSender } from './hooks/useEmailSender';

interface PurchaseOrderFileProcessModalProps {
  open: boolean;
  onClose: () => void;
}

export function PurchaseOrderFileProcessModal({
  open,
  onClose,
}: PurchaseOrderFileProcessModalProps) {
  const oms = useOMSStore();
  const {
    emailSendStatus,
    selectedRecordIds,
    onClickSend,
    selectedRecordCount,
    onSelectRecord,
  } = useEmailSender({
    oms,
    onSubmit: onClose,
  });

  const records = oms.sendEmailWithPurchaseOrderFileStore.recordItemStatuses;

  const handleCloseButtonClick = useCallback(async () => {
    if (emailSendStatus === EmailSendStatus.INIT) {
      const result = await openConfirmDialog(
        '발주서를 전송하지 않고 화면을 닫을까요?',
        '화면을 나가면 자동 전송을 다시 시도할 수 없습니다.'
      );
      if (result) {
        onClose();
      }
      return;
    }

    if (emailSendStatus === EmailSendStatus.SENDING) {
      const result = await openConfirmDialog(
        '발주서 전송을 그만둘까요?',
        '전송 도중 그만두면 올바르게 처리되지 않을 수 있습니다.'
      );
      if (result) {
        onClose();
      }
      return;
    }

    onClose();
  }, [onClose, emailSendStatus]);

  const handleSendButtonClick = () => {
    records.forEach(({ supplierName, supplierEmail }) => {
      amplitude.track('Send puchase-order (Server)', {
        'supplier name': supplierName,
        'supplier email': supplierEmail,
      });
    });

    onClickSend();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseButtonClick}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className="max-h-720px"
    >
      <SwitchCase
        value={emailSendStatus}
        caseBy={{
          [EmailSendStatus.INIT]: (
            <PendingRecordSection
              records={records}
              selectedRecordIds={selectedRecordIds}
              selectedRecordCount={selectedRecordCount}
              onSelectRecord={onSelectRecord}
              onClickSend={handleSendButtonClick}
              onClose={handleCloseButtonClick}
            />
          ),
          [EmailSendStatus.SENDING]: (
            <SendingRecordSection
              records={records}
              selectedRecordIds={selectedRecordIds}
              selectedRecordCount={selectedRecordCount}
              onClose={handleCloseButtonClick}
            />
          ),
          [EmailSendStatus.DONE]: (
            <DoneRecordSection
              records={records}
              selectedRecordIds={selectedRecordIds}
              onSubmit={handleCloseButtonClick}
            />
          ),
        }}
      />
    </Modal>
  );
}

export default observer(PurchaseOrderFileProcessModal);
