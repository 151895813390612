import { Button, Modal } from '@sweep/sds';
import { RecordItemStatus } from 'src/stores/SendEmailWithPurchaseOrderFileStore';
import { RecordItemInProgress } from './RecordItemInProgress';
import { SpinnerWhite } from './SpinnerWhite';

type SendingRecordSectionProps = {
  records: RecordItemStatus[];
  selectedRecordIds: string[];
  selectedRecordCount: number;
  onClose: () => void;
};

export function SendingRecordSection({
  records,
  selectedRecordIds,
  selectedRecordCount,
  onClose,
}: SendingRecordSectionProps) {
  const selectedRecords = records.filter((recordItemStatus) =>
    selectedRecordIds.includes(recordItemStatus.recordId)
  );

  return (
    <>
      <Modal.Title className="text-gray-700">발주서 전송</Modal.Title>
      <Modal.Description className="pb-32px text-gray-700">
        공급사 <span className="text-blue-500">{selectedRecordCount}곳</span>에
        이메일로 발주서를 전송할까요?
      </Modal.Description>
      <Modal.Description className="gap-8px flex flex-col">
        {selectedRecords.map((recordItemStatus) => (
          <RecordItemInProgress
            key={recordItemStatus.recordId}
            supplierName={recordItemStatus.supplierName}
            supplierEmail={recordItemStatus.supplierEmail}
            filename={recordItemStatus.filename}
            emailStatus={recordItemStatus.emailStatus}
          />
        ))}
      </Modal.Description>

      <div className="flex justify-center gap-[12px] pt-[20px]">
        <Button onClick={onClose} color="lightGray" className="w-[200px]">
          취소하기
        </Button>
        <Button className="w-[200px]">
          <SpinnerWhite />
        </Button>
      </div>
    </>
  );
}

export default SendingRecordSection;
