import { useOMSStore } from 'src/hooks/useOMSStore';

const ColumnMappingHandle = ({
  columnMapping,
  setColumnMapping,
  supplierKoreanHeader,
}) => {
  const oms = useOMSStore();
  const findKeyByValue = (object, value) =>
    Object.keys(object).find((key) => object[key] === value);

  const hasUniqueCode = oms.user.excelColumnMapping.uniqueCode != null;
  const userKoreanHeader = [
    ...oms.user.excelHeaderKeys.map((key) => oms.user.excelColumnMapping[key]),
    ...(hasUniqueCode ? [] : ['=====', '스윕고유번호']),
  ];

  const userColumnMapping = {
    ...oms.user.excelColumnMapping,
    ...(hasUniqueCode ? {} : { uniqueCode: '스윕고유번호' }),
  };

  const handleMappingChange = (userheaderIndex, supplierHeaderIndex) => {
    // ColumnMapping이 없으면 추가해야함
    const targetKey = findKeyByValue(
      userColumnMapping,
      userKoreanHeader[userheaderIndex]
    );

    let updatedMapping = { ...columnMapping };

    const koreanValue = supplierKoreanHeader[supplierHeaderIndex];
    const existingKey = Object.keys(columnMapping).find(
      (key) => columnMapping[key] === koreanValue
    );
    const existingValue = targetKey != null ? columnMapping[targetKey] : null;

    if (targetKey == null) {
      updatedMapping[koreanValue] = koreanValue;
    }
    if (existingKey != null) {
      delete updatedMapping[existingKey];
    }
    if (existingValue != null) {
      updatedMapping[existingValue] = existingValue;
    }
    updatedMapping[targetKey] = koreanValue;

    setColumnMapping(updatedMapping);
  };

  const aggregationSelect = (key, index) => {
    return (
      <select
        value={userKoreanHeader.findIndex((korUser) => {
          return (
            findKeyByValue(userColumnMapping, korUser) ===
            findKeyByValue(columnMapping, key)
          );
        })}
        onChange={(e) => {
          return handleMappingChange(e.target.value, index);
        }}
        className={`h-[40px] w-full rounded-lg border border-gray-300 px-[16px] ${
          userKoreanHeader.findIndex((korUser) => {
            return (
              findKeyByValue(userColumnMapping, korUser) ===
              findKeyByValue(columnMapping, key)
            );
          }) === -1
            ? 'text-gray-500'
            : 'text-gray-900'
        }`}
      >
        <option value={-1}>선택되지 않음</option>

        {userKoreanHeader.map((column, index) => (
          <option key={index} value={index}>
            {column}
          </option>
        ))}
      </select>
    );
  };

  return (
    <>
      {supplierKoreanHeader?.map((key, index) => (
        <div key={index} className="flex  flex-col whitespace-nowrap">
          <div
            className={`mb-[32px] flex min-w-[200px] items-center justify-between`}
          >
            <div className="flex w-full flex-col">
              {aggregationSelect(key, index)}
            </div>
          </div>
          <div
            className={`mb-[8px] flex-1 truncate whitespace-nowrap bg-[#F5F7FA] px-[16px] py-[3px] text-left text-[14px]`}
          >
            {key}
          </div>
        </div>
      ))}
    </>
  );
};

export default ColumnMappingHandle;
