import { DispatchedOrder } from '@sweep/contract';
import { getDispatchedOrdersPolling } from 'src/network/order/dispatchOrder';

const MINUTE = 1000 * 60;
const SECOND = 1000;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export async function pollingDispatchedOrders(
  requestId: string,
  signal: AbortSignal,
  {
    timeout = MINUTE * 5,
    interval = SECOND * 5,
  }: { timeout: number; interval: number }
): Promise<DispatchedOrder[] | null> {
  const startTime = new Date().getTime();
  let failCount = 0;

  while (new Date().getTime() - startTime < timeout) {
    if (signal.aborted) {
      return null;
    }

    const response = await getDispatchedOrdersPolling({ requestId });
    if (response == null || response.success === false) {
      failCount += 1;
      if (failCount > 3) {
        return null;
      }

      await sleep(interval);
      continue;
    }

    failCount = 0;

    const result = response.data;
    if (result.status === 'FINISH') {
      return result.data;
    }

    await sleep(interval);
  }

  return null;
}
