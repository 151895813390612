import { cva } from '@sweep/tailwind';

interface ScrollGradientProps {
  direction: 'left' | 'right';
}

function ScrollGradient({ direction }: ScrollGradientProps) {
  return <div className={scrollGradient({ direction })} />;
}

const scrollGradient = cva(
  'w-60px h-full absolute flex-shrink-0 pointer-events-none from-transparent to-white',
  {
    variants: {
      direction: {
        left: 'left-0 bg-gradient-to-l',
        right: 'right-0 bg-gradient-to-r',
      },
    },
  }
);

export default ScrollGradient;
