import { action, observable, runInAction } from 'mobx';
import { NormalizedOrder, Order } from '@sweep/contract';
import {
  getNormalizedOrders,
  upsertNormalizedOrders,
} from 'src/network/order/order';
import { OMSStore } from 'src/stores/OMSStore';
import { PluginExecutionService } from 'src/stores/plugin/PluginExecutionService';

export class NormalizeOrderStore {
  @observable
  accessor normalizedOrders: NormalizedOrder[] = [];

  constructor(private oms: OMSStore) {}

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  @action.bound
  setNormalizedOrders(orders: NormalizedOrder[]) {
    this.normalizedOrders = orders;
  }

  @action.bound
  async init() {
    await this.loadNormalizedOrders();
  }

  @action.bound
  async loadNormalizedOrders() {
    const response = await getNormalizedOrders();
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.normalizedOrders = response.data;
    });
  }

  @action.bound
  normalizeOrders = async (orders: Order[]): Promise<Order[]> => {
    const pluginExecutionService = new PluginExecutionService(
      this.oms,
      this.oms.user.plugins,
      'normalize'
    );

    const transformedOrders = await pluginExecutionService.execute(orders);

    return transformedOrders;
  };

  @action.bound
  async upsertOrders(orders: NormalizedOrder[]) {
    const indexByUniqueCodeAfterCustomization = new Map<string, number>(
      this.normalizedOrders.map((order, index) => [
        order.uniqueCodeAfterCustomization,
        index,
      ])
    );

    const now = new Date().getTime();
    orders.forEach((order) => {
      const index = indexByUniqueCodeAfterCustomization.get(
        order.uniqueCodeAfterCustomization
      );
      if (index != null) {
        this.normalizedOrders[index] = {
          ...this.normalizedOrders[index],
          ...order,
          updatedAt: now,
        };
        return;
      }

      this.normalizedOrders.push({
        ...order,
        updatedAt: now,
        registeredAt: now,
      });
    });

    await upsertNormalizedOrders(orders);
  }

  @action.bound
  removeOrderByFilename(filename: string) {
    this.normalizedOrders = this.normalizedOrders.filter(
      (order) => order.originFile !== filename
    );
  }
}
