import { Link, useNavigate } from 'react-router-dom';
import { IconHeaderLogotype } from '@sweep/asset/icons';
import { image404 } from 'src/images';

export default function NotFoundScreen() {
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative flex h-screen w-screen items-center justify-center bg-gray-100">
      <Link to="/order-dispatch">
        <IconHeaderLogotype className="left-40px top-36px h-30px fixed text-blue-500" />
      </Link>
      <div className="gap-15px flex flex-col items-center">
        <img src={image404} className="size-[147px]" />
        <div className="text-l flex flex-col items-center gap-[47px] font-semibold">
          <div className="flex flex-col items-center text-gray-600">
            <p>요청하신 페이지를 찾을 수 없습니다.</p>
            <p>주소가 정확한지 다시 한번 확인해주세요.</p>
          </div>
          <p
            onClick={navigateBack}
            className="cursor-pointer text-blue-500 hover:underline"
          >
            이전 페이지로 돌아가기
          </p>
        </div>
      </div>
    </div>
  );
}
