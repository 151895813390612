import { action, computed, observable, runInAction } from 'mobx';
import { requestSharePurchaseOrderFileRecordWithEmail } from 'src/network/purchase-order-file-record/sharePurchaseOrderFileRecordWithEmail';
import { OMSStore } from './OMSStore';

export const SEND_EMAIL_STATUS = {
  INIT: 'init',
  SENDING: 'sending',
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

export type SendEmailStatus =
  (typeof SEND_EMAIL_STATUS)[keyof typeof SEND_EMAIL_STATUS];

export type InsertNewRecordItemStatusParams = {
  recordId: string;
  email: string;
  filename: string;
  supplierName: string;
};

export type RecordItemStatus = {
  recordId: string;
  filename: string;
  supplierEmail: string;
  supplierName: string;
  emailStatus: SendEmailStatus;
};

export class SendEmailWithPurchaseOrderFileStore {
  @observable accessor isSendingAll: boolean = false;
  @observable accessor recordItemStatuses: RecordItemStatus[] = [];
  @observable accessor selectedRecordIds: string[] = [];

  constructor(private oms: OMSStore) {}

  @action.bound
  getRecordItemByRecordId(recordId: string) {
    return this.recordItemStatuses.find(
      (recordItemStatus) => recordItemStatus.recordId === recordId
    );
  }

  @action.bound
  async insertNewRecordItemStatus({
    recordId,
    email,
    filename,
    supplierName,
  }: InsertNewRecordItemStatusParams) {
    const newRecordItemStatus: RecordItemStatus = {
      recordId,
      filename,
      supplierEmail: email,
      supplierName,
      emailStatus: SEND_EMAIL_STATUS.INIT,
    };

    runInAction(() => {
      this.recordItemStatuses.push(newRecordItemStatus);
    });
  }

  @action.bound
  setSelectedRecordIds(recordIds: string[]) {
    this.selectedRecordIds = recordIds;
  }

  @action.bound
  async bulkInsertNewRecordItemStatuses(
    recordItemStatuses: InsertNewRecordItemStatusParams[]
  ) {
    const newRecordItemStatuses = recordItemStatuses.map(
      ({ recordId, email, filename, supplierName }) => ({
        recordId,
        filename,
        supplierEmail: email,
        supplierName,
        emailStatus: SEND_EMAIL_STATUS.INIT,
      })
    );

    runInAction(() => {
      this.recordItemStatuses.push(...newRecordItemStatuses);
    });
  }

  @action.bound
  async requestSharePurchaseOrderFileRecordWithEmail(
    recordId: string
  ): Promise<boolean> {
    const selectedRecord = this.recordItemStatuses.find(
      (recordItemStatus) => recordItemStatus.recordId === recordId
    );

    if (!selectedRecord) {
      return false;
    }

    try {
      runInAction(() => {
        selectedRecord.emailStatus = SEND_EMAIL_STATUS.SENDING;
      });

      const response = await requestSharePurchaseOrderFileRecordWithEmail({
        purchaseOrderFileRecordId: recordId,
      });

      const isSuccess = response != null && response.isSuccess === true;

      runInAction(() => {
        selectedRecord.emailStatus = isSuccess
          ? SEND_EMAIL_STATUS.SUCCESS
          : SEND_EMAIL_STATUS.ERROR;
      });

      return isSuccess;
    } catch {
      runInAction(() => {
        selectedRecord.emailStatus = SEND_EMAIL_STATUS.ERROR;
      });

      return false;
    }
  }

  @action.bound
  async requestShareAllPurchaseOrderFileRecordsWithEmail(
    selectedRecordIds: string[]
  ): Promise<{
    isSuccess: boolean;
    successCount: number;
    failedCount: number;
  }> {
    this.isSendingAll = true;

    const selectedRecords = this.recordItemStatuses.filter((recordItemStatus) =>
      selectedRecordIds.includes(recordItemStatus.recordId)
    );

    const initStatusRecordIds = selectedRecords
      .filter(
        (recordItemStatus) =>
          recordItemStatus.emailStatus === SEND_EMAIL_STATUS.INIT
      )
      .map((recordItemStatus) => recordItemStatus.recordId);

    const promiseResults = await Promise.allSettled(
      initStatusRecordIds.map((recordId) =>
        this.requestSharePurchaseOrderFileRecordWithEmail(recordId)
      )
    );

    const successCount = promiseResults.filter(
      (result) => result.status === 'fulfilled' && result.value === true
    ).length;
    const failedCount = promiseResults.filter(
      (result) => result.status === 'rejected' || result.value === false
    ).length;

    runInAction(() => {
      this.isSendingAll = false;
    });

    return {
      isSuccess: successCount > 0 && failedCount === 0,
      successCount,
      failedCount,
    };
  }

  @action.bound
  async clear() {
    runInAction(() => {
      this.recordItemStatuses = [];
    });
  }

  @computed
  get isAllSuccess() {
    return this.recordItemStatuses.every(
      (recordItemStatus) =>
        recordItemStatus.emailStatus === SEND_EMAIL_STATUS.SUCCESS
    );
  }
}
