import { overlay } from 'overlay-kit';
import { IconAlert } from '@sweep/asset/icons';
import { Dialog } from '@sweep/sds';

export function openDeleteCombinedShippingWarningDialog() {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-16px gap-8px flex items-center">
          <IconAlert />
          상품 정보를 수정하면 합배송 설정이 사라져요.
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          상품명 또는 옵션을 수정하면 합배송 설정과 단위 정보가 사라집니다. 필요
          시 다시 추가해주세요.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>수정하기</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
