import { overlay } from 'overlay-kit';
import { DispatchedOrder } from '@sweep/contract';
import { Modal } from '@sweep/sds';
import RejectCanceledOrderModal from '../RejectCanceledOrderModal';

export function openRejectCanceledOrderDialog(
  orders: DispatchedOrder[],
  rejectCanceledOrders: (
    rejectReason: string,
    orders: DispatchedOrder[]
  ) => Promise<boolean>
): Promise<boolean> {
  return new Promise((resolve) => {
    overlay.open(({ isOpen, close, unmount }) => (
      <Modal
        open={isOpen}
        onClose={close}
        className="max-h-[80vh] w-[700px] overflow-scroll"
      >
        <Modal.Title>주문취소 거부</Modal.Title>
        <RejectCanceledOrderModal
          onFormSubmit={async (rejectReason, orders) => {
            const result = await rejectCanceledOrders(rejectReason, orders);
            resolve(result);
            close();
            unmount();
          }}
          orders={orders}
        />
      </Modal>
    ));
  });
}
