function InquiryReplyPassInput() {
  return (
    <div className="flex w-full flex-col">
      <div className="px-15px py-12px rounded-8px w-full border-[1.5px] border-gray-200">
        <p className="text-medium-m text-gray-400">
          답변을 작성할 수 없는 문의입니다.
        </p>
      </div>
    </div>
  );
}

export default InquiryReplyPassInput;
