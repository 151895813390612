import { NormalizedOrder } from '@sweep/contract';
import { formatDate, getUnusedFilename, isNotNil } from '@sweep/utils';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { replaceNormalizedOrders } from 'src/network/order/order';
import { getRemainedOrdersByUniqueCodeAfterCustomization } from 'src/screens/order-process/services/getRemainedOrdersByUniqueCodeAfterCustomization';
import { openConfirmDownloadPurchaseOrderDialog } from '../components/dialogs/openConfirmDownloadPurchaseOrderDialog';
import usePrevDivideOrders from './usePrevDivideOrders';

const useCreatePurchaseOrderForSupplier = () => {
  const oms = useOMSStore();
  const { preDivideOrders } = usePrevDivideOrders();

  const createSupplierPurchaseOrder = async (
    orders: NormalizedOrder[]
  ): Promise<NormalizedOrder[] | null> => {
    const isConfirmed = await openConfirmDownloadPurchaseOrderDialog(
      orders.length
    );
    if (!isConfirmed) {
      return null;
    }
    const remainedOrders = getRemainedOrdersByUniqueCodeAfterCustomization(
      oms.order.normalizedOrders,
      orders.map((order) => order.uniqueCodeAfterCustomization)
    );

    const preDividedOrders = await preDivideOrders(orders);

    const usedFilenames = oms.order.normalizedOrders
      .map((order) => order.purchaseOrderFile)
      .filter(isNotNil);

    const filename = formatDate(new Date(), 'yy.MM.dd 발주통합 (HHmm)');
    const unusedFilename = getUnusedFilename(filename, usedFilenames);
    const shippingOrders = preDividedOrders.map<NormalizedOrder>((order) => ({
      ...order,
      purchaseOrderFile: unusedFilename,
      process: 'shipping',
      shippingStatus: 'processing',
    }));
    const updatedOrders = [...shippingOrders, ...remainedOrders];

    oms.order.setNormalizedOrders(updatedOrders);
    await oms.loading.batch(() => replaceNormalizedOrders(updatedOrders));
    oms.order.file.downloadSupplierPurchaseOrder(
      preDividedOrders,
      unusedFilename
    );

    return shippingOrders;
  };

  return {
    createSupplierPurchaseOrder,
  };
};

export default useCreatePurchaseOrderForSupplier;
