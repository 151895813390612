import { z } from 'zod';

export const ExcelFileRecordSchema = z.object({
  filename: z.string(),
  fileExtension: z.string(),
  orders: z.array(z.record(z.string(), z.unknown())),
  headers: z.array(z.string()),
  headerKeys: z.array(z.string()),
  columnMappings: z.record(z.string(), z.string()),
  separateAddressEnabled: z.boolean(),
  userExcelSettingFields: z
    .array(
      z.object({
        target: z.string(),
        type: z.enum(['number']),
      })
    )
    .optional(),
});

export type ExcelFileRecord = z.infer<typeof ExcelFileRecordSchema>;
