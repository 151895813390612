import './App.css';

import { OverlayProvider } from 'overlay-kit';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import PluginSettingScreen from 'screens/setting/plugin/PluginSettingScreen';
import LoadingProvider from 'stores/loading/LoadingProvider';
import { OMSContextProvider } from 'stores/OMSContext';
import { OMSStore } from 'stores/OMSStore';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import UserExcelFormatRegistrationModal from './modals/IntegratedExcelFormatRegistrationModal';
import OMSServiceLayout from './routes/OMSServiceLayout';
import AddressValidScreen from './screens/address-validate/AddressValidScreen';
import AliExpressLayout from './screens/ali-express/AliExpressLayout';
import AliRegistrationScreen from './screens/ali-express/AliExpressRegistrationScreen';
import AliExpressScreen from './screens/ali-express/AliExpressScreen';
import IntroduceBaljuScreen from './screens/BalJuGwanRi';
import OrderDispatchScreen from './screens/dispatch/OrderDispatchScreen';
import OrderDispatchSearchScreen from './screens/dispatch-search/OrderDispatchSearchScreen';
import InquiryScreen from './screens/inquiry/InquiryScreen';
import IntroduceOrderScreen from './screens/JuMunSoOJip';
import LandingPage from './screens/landing-page/LandingPage';
import LandingPageForABTest from './screens/LandingPageForABTest';
import LoginScreen from './screens/Login/LoginScreen';
import MyPageScreen from './screens/MyPage/MyPageScreen';
import FullPageScreen from './screens/NewLandingPagewithFullPager';
import NotFoundScreen from './screens/NotFoundScreen';
import OrderProcessScreen from './screens/order-process/OrderProcessScreen';
import CombinedShippingScreen from './screens/product/combined-shipping/CombinedShippingScreen';
import CompositionMatchingManagementScreen from './screens/product/CompositionMatchingManage/CompositionMatchingManageScreen';
import ProductManagementScreen from './screens/product/product-management/ProductManagementScreen';
import PreRegistrationScreen from './screens/Registration/PreRegistrationScreen';
import RegisterConsultingScreen from './screens/Registration/RegisterConsultingScreen';
import PartnerManagementScreen from './screens/setting/partnerManagement/PartnerManagementScreen';
import QaSettingScreen from './screens/setting/qa/QaSettingScreen';
import SupplierManagementScreen from './screens/setting/supplierManagement/SupplierManagementScreen';
import DailySettlementManagementScreen from './screens/Settlement/DailySettlementManagementScreen';
import SharedPurchaseOrderFileDownloadScreen from './screens/shared/SharedPurchaseOrderFileDownloadScreen';
import { createDebug } from './third-parties/createDebug';
import { Toaster } from './third-parties/toast';

const debug = createDebug('App');

function App() {
  const [oms] = useState(() => new OMSStore());

  // TODO(@이지원): react-router-dom loader에 넣기
  useEffect(() => {
    if (oms.user.isLoggedIn) {
      oms.loading.batch(() => oms.init());

      // Add Sentry user tracking
      try {
        const userId = oms.user.userId.value!;
        const email = oms.user.email;
        ErrorReporter.setUser(userId, email);
      } catch (error) {
        ErrorReporter.captureError(error as Error, {
          extra: {
            message: 'Error setting Sentry user:',
          },
        });

        console.error('Error setting Sentry user:', error);
      }
    }
  }, [oms]);

  useEffect(() => {
    debug('App mounted');
    ErrorReporter.addBreadcrumb({
      message: 'App mounted',
    });

    return () => {
      debug('App unmounted');
      ErrorReporter.addBreadcrumb({
        message: 'App unmounted',
      });
    };
  }, []);

  return (
    <HelmetProvider>
      <OMSContextProvider value={oms}>
        <LoadingProvider>
          <OverlayProvider>
            <Routes>
              <Route path="/landing-page" element={<LandingPage />} />
              <Route path="/ali-express" element={<AliExpressLayout />}>
                <Route path="" element={<AliExpressScreen />} />
                <Route path="register" element={<AliRegistrationScreen />} />
              </Route>
              <Route path="/address" element={<AddressValidScreen />} />
              <Route
                path="/pre-registration"
                element={<PreRegistrationScreen />}
              />
              <Route
                path="/register-consulting"
                element={<RegisterConsultingScreen />}
              />
              <Route path="/sign-in" element={<LoginScreen />} />

              <Route path="pre-register" element={<FullPageScreen />} />

              <Route
                path="/introduce-order-dispatch"
                element={<IntroduceOrderScreen />}
              />
              <Route
                path="/introduce-order-manage"
                element={<IntroduceBaljuScreen />}
              />

              <Route path="/" element={<LandingPageForABTest />} />
              <Route path="/" element={<OMSServiceLayout />}>
                <Route path="my-page/*" element={<MyPageScreen />} />
                <Route
                  path="order-aggregation"
                  element={<OrderProcessScreen />}
                />
                <Route
                  path="order-dispatch"
                  element={<OrderDispatchScreen />}
                />
                <Route
                  path="order-dispatch-search"
                  element={<OrderDispatchSearchScreen />}
                />
                <Route path="cs" element={<InquiryScreen />} />
                <Route path="product">
                  <Route index element={<ProductManagementScreen />} />
                  <Route
                    path="combined-shipping"
                    element={<CombinedShippingScreen />}
                  />
                  <Route
                    path="composition-matching"
                    element={<CompositionMatchingManagementScreen />}
                  />
                </Route>

                <Route
                  path="settlement/daily-management"
                  element={<DailySettlementManagementScreen />}
                />

                <Route path="setting">
                  <Route path="partner" element={<PartnerManagementScreen />} />
                  <Route
                    path="supplier"
                    element={<SupplierManagementScreen />}
                  />
                  <Route path="plugin" element={<PluginSettingScreen />} />
                  <Route path="qa" element={<QaSettingScreen />} />
                  <Route
                    path="integrated-excel-format"
                    element={<UserExcelFormatRegistrationModal />}
                  />
                </Route>
              </Route>
              <Route
                path="shared/purchase-order-file-record"
                element={<SharedPurchaseOrderFileDownloadScreen />}
              />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
            <Toaster />
          </OverlayProvider>
        </LoadingProvider>
      </OMSContextProvider>
    </HelmetProvider>
  );
}

export default App;
