import { useOMSStore } from 'src/hooks/useOMSStore';
import { isValid } from '../../../utils/utils';

const usePreprocessOrder = () => {
  const oms = useOMSStore();
  const preprocessOrders = async (orders) => {
    let newOrders = [...orders];
    newOrders = orderCalcAddMinus(newOrders);
    newOrders = orderCalcMultiplication(newOrders);
    newOrders = add0TopostCode(newOrders);

    if (isValid(oms.user.setting?.preprocessSettings)) {
      newOrders = add0TocontactNumber(newOrders);
      newOrders = deleteDateinNameOption(newOrders);
      newOrders = deleteWonInNameOption(newOrders);
      newOrders = deleteQuantityInOption(newOrders);
    }

    return newOrders;
  };

  const add0TopostCode = (orders) => {
    let newOrders = [...orders];
    newOrders = newOrders.map((order) => {
      order.postCode = (order.postCode || '').toString().trim();
      if (order.postCode && order.postCode.length === 4) {
        order.postCode = '0' + order.postCode;
      }
      return order;
    });
    return newOrders;
  };
  const add0TocontactNumber = (orders, justDOIT = false) => {
    if (
      justDOIT ||
      oms.user.setting?.preprocessSettings?.add0TocontactNumber?.enabled
    ) {
      let newOrders = [...orders];
      newOrders = newOrders.map((order) => {
        order.contactNumber = (order.contactNumber || '').toString().trim();
        if (
          order.contactNumber &&
          order.contactNumber.length === 10 &&
          order.contactNumber.slice(0, 2) === '10'
        ) {
          order.contactNumber = '0' + order.contactNumber;
        }

        if (
          order.contactNumber &&
          order.contactNumber.length === 12 &&
          order.contactNumber.slice(0, 4) === '8210'
        ) {
          order.contactNumber = '0' + order.contactNumber.slice(2);
        }
        return order;
      });
      return newOrders;
    }
    return orders;
  };

  const orderCalcAddMinus = (orders) => {
    let newOrders = orders.map((order) => {
      //order의 fileds중 필드 이름이 Add1로 끝나는 것들을 합치기
      let keys = Object.keys(order);
      let addKeys = keys.filter((key) => key.match(/Add\d+$/));
      addKeys.forEach((addKey) => {
        let targetKey = addKey.replace(/Add\d+$/, '');
        if (isValid(order[targetKey])) {
          return;
        }
        let newValue = 0;
        for (let i = 1; i <= 5; i++) {
          let key = targetKey + 'Add' + i;
          if (order[key] !== undefined) {
            newValue += parseInt(
              order[key].toString().replace(/,|₩/g, '').trim()
            );
            delete order[key];
          }
        }
        for (let i = 1; i <= 5; i++) {
          let key = targetKey + 'Minus' + i;
          if (isValid(order[key])) {
            newValue -= parseInt(
              order[key].toString().replace(/,|₩/g, '').trim()
            );
            delete order[key];
          }
        }
        order[targetKey] = newValue;
      });
      return order;
    });
    return newOrders;
  };

  const orderCalcMultiplication = (orders) => {
    let newOrders = orders.map((order) => {
      //order의 fileds중 필드 이름이 Add1로 끝나는 것들을 합치기
      let keys = Object.keys(order);
      let multiplicationKeys = keys.filter((key) => key.match(/Multiply\d+$/));
      multiplicationKeys.forEach((multiplicationKey) => {
        let targetKey = multiplicationKey.replace(/Multiply\d+$/, '');
        if (isValid(order[targetKey])) {
          return;
        }
        let newValue = 1;
        for (let i = 1; i <= 5; i++) {
          let key = targetKey + 'Multiply' + i;
          if (order[key] !== undefined) {
            newValue *= Number(
              order[key].toString().replace(/,|₩/g, '').trim()
            );
            delete order[key];
          }
        }
        order[targetKey] = newValue;
      });
      return order;
    });
    return newOrders;
  };

  // 배경
  // - 이베이, 11번가, 돌쇠네 등의 판매처의 경우 수량이 옵션에 중복되어 포함되어 있음(ex. productName: '반숙란 30구 (2개)', quantity: 2인데 실제 주문한 개수가 2개인 경우)
  // - 따라서 상품명 또는 옵션에 붙어있는 수량을 삭제해서 중복을 방지
  // 질문 - 어떻게 관리하는게 좋을지? 1) 파트너사 이름으로 바른다, 2) 그런 고객사의 이름으로 바른다
  // 위 방식에서 발라지지 않는 경우, 1) 파트너사 설정(customSettings)에서 바른다, 2) 별도의 customSettings를 만든다
  const deleteQuantityInOption = (orders) => {
    if (
      !oms.user.setting?.preprocessSettings?.deleteQuantityInOption?.enabled
    ) {
      return orders;
    }

    let newOrders = [...orders];
    newOrders = newOrders.map((order) => {
      let partner;
      let partnerName;
      let partnerCustomSettings;

      partner = oms.partner.partners.find(
        (partner) => partner._id === order.partnerId
      );

      if (isValid(partner)) {
        partnerName = partner.name;
        partnerCustomSettings = partner.customSettings;
      }

      // 파트너 이름에 '이베이' 혹은 '11번가' 이 포함되어 있으면 수량을 없애기
      if (
        (isValid(partnerName) &&
          (partnerName.includes('이베이') ||
            partnerName.includes('11번가') ||
            partnerName.includes('돌쇠네'))) ||
        partnerCustomSettings?.deleteQuantityInOption
      ) {
        // 1) order.option에서 /(\d+개)$/ 패턴이 있는지 확인
        // 2) 있는 경우, order.option에서 /(\d+개)$/ 패턴을 삭제
        // 3) 없는 경우, order.productName에서 /(\d+개)$/가 있는지 확인 하고, 있을 경우 해당 패턴을 삭제
        let regex = /\s*(?:\(\d+개\)|-\d+개|\/\d+개)\s*$/;

        if (regex.test(order.option)) {
          order.option = order.option.replace(regex, '').trim();
        } else {
          if (regex.test(order.productName)) {
            order.productName = order.productName.replace(regex, '').trim();
          }
        }
      }

      if (isValid(partnerName) && partnerName.includes('알리')) {
        const regex = /\(수량:(\d+) piece\)/g;
        let match = order.productName.match(regex);
        if (match && match.length === 1) {
          order.quantity = parseInt(match[0].match(/\(수량:(\d+) piece\)/)[1]);
          order.productName = order.productName.replace(regex, '').trim();
        }
      }

      return order;
    });
    return newOrders;
  };

  const deleteDateinNameOption = (orders) => {
    if (
      !oms.user.setting?.preprocessSettings?.deleteDateinNameOption?.enabled
    ) {
      return orders;
    }

    let newOrders = [...orders];
    let days = ['월', '화', '수', '목', '금', '토', '일'];
    let nameOptions = ['productName', 'option'];

    newOrders = newOrders.map((order) => {
      nameOptions.forEach((nameOption) => {
        // (숫자)월에 해당하는 것 찾아 없애기
        order[nameOption] = order[nameOption].replace(/\d+\s?월/, '');
        order[nameOption] = order[nameOption].replace(/\d+\s?일/, '');
        // 요일 없애기
        days.forEach((day) => {
          order[nameOption] = order[nameOption].replace(' ' + day + ' ', '');
          order[nameOption] = order[nameOption].replace('(' + day + ')', '');
        });
      });
      return order;
    });
    return newOrders;
  };

  const deleteWonInNameOption = (orders) => {
    if (!oms.user.setting?.preprocessSettings?.deleteWonInNameOption?.enabled) {
      return orders;
    }

    let newOrders = [...orders];
    let nameOptions = ['productName', 'option'];
    newOrders = newOrders.map((order) => {
      nameOptions.forEach((nameOption) => {
        while (order[nameOption].match(/\d+\s?원/)) {
          // 112342 원, 112,342 원, +숫자 원, -숫자 원, (+숫자 원) 등을 삭제
          order[nameOption] = order[nameOption].replace(
            /\(?-?\+?\d{1,3}(,?\d{3})*(\.\d+)?\s?원\)?/g,
            ''
          );
          order[nameOption] = order[nameOption].trim();
        }
      });
      return order;
    });
    return newOrders;
  };

  return {
    preprocessOrders,
  };
};

export default usePreprocessOrder;
