import {
  CreatePurchaseOrderFileRecordDTO,
  ExcelFileRecord,
  NormalizedOrder,
  Supplier,
  UserSetting,
} from '@sweep/contract';
import { selectFileFormatting } from '@sweep/domain/services/order-shipping-process-purchase-order';
import { formatDate } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { selectExcelHeaderAndMapper } from './partials/selectExcelHeaderAndMapper';

export function createPurchaseOrderFileRecordFromSupplier(params: {
  orders: NormalizedOrder[];
  oms: OMSStore;
  supplier: Supplier;
  today: Date;
  groupId: string;
  groupName: string;
}): CreatePurchaseOrderFileRecordDTO {
  const { orders, oms, supplier, today, groupId, groupName } = params;
  const supplierKey = supplier._id;

  const { fileFormat, dateFormat } = selectFileFormatting([
    supplier.settings?.fileFormatting,
    oms.user.setting?.supplierFileFormatting,
  ]);

  const supplierFilename = fileFormat
    .replace('{date}', formatDate(today, dateFormat))
    .replace('{supplierName}', supplier.name)
    .replace('{name}', oms.user.name);

  const supplierHeader = supplier.header ?? [];
  const supplierColumnMapping = supplier.columnMapping ?? {};
  const userDefaultExcelHeaderKeys = oms.user.excelHeaderKeys;
  const userDefaultExcelColumnMapping = oms.user.excelColumnMapping;

  // NOTE(@hungjoon): SWP-447 임시 코드
  const { headers, headerKeys, columnMapping } = selectExcelHeaderAndMapper({
    supplierHeaders: supplierHeader,
    supplierColumnMapping: supplierColumnMapping,
    userDefaultHeaderKeys: userDefaultExcelHeaderKeys,
    userDefaultColumnMapping: userDefaultExcelColumnMapping,
  });
  // NOTE(@hungjoon): SWP-447 임시 코드

  const userCustomSettings = oms.user.setting as UserSetting;

  // TODO(@hungjoon): 템플릿 지원해줘야 함
  const excelFileRecord: ExcelFileRecord = {
    filename: supplierFilename,
    fileExtension: 'xlsx',
    orders,
    headers,
    headerKeys,
    columnMappings: columnMapping,
    separateAddressEnabled:
      userCustomSettings?.preprocessSettings?.separateAddress?.enabled === true,
    userExcelSettingFields: userCustomSettings?.excel?.fields,
  };

  const record: CreatePurchaseOrderFileRecordDTO = {
    supplierId: supplierKey,
    groupId,
    groupName,
    excelFileRecord,
  };

  return record;
}
