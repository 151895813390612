import { observer } from 'mobx-react-lite';
import { Button } from '@sweep/sds';

interface InquiryReplyConfirmInputProps {
  onSubmit: () => void;
}

function InquiryConfirmReviewInput({
  onSubmit,
}: InquiryReplyConfirmInputProps) {
  return (
    <div className="gap-16px flex w-full flex-col">
      <div className="px-15px py-12px rounded-8px w-full border-[1.5px] border-gray-200">
        <p className="text-medium-m text-gray-400">
          답변을 작성할 수 없는 문의입니다.
        </p>
      </div>
      <div className="flex justify-end">
        <Button onClick={onSubmit} className="w-160px">
          확인 완료
        </Button>
      </div>
    </div>
  );
}

export default observer(InquiryConfirmReviewInput);
