import { FileMatching } from 'forms/partner/matching/interface';
import { action, observable, runInAction } from 'mobx';
import { getOrders, upsertOrders } from 'network/order/order';
import { overlay } from 'overlay-kit';
import { transformToOrders } from 'services/column-mapping/transformToOrders';
import { MultiSheetExcelFile } from 'services/file/interface';
import { validateFiles } from 'services/file/validateFiles';
import { OMSStore } from 'stores/OMSStore';
import { PluginExecutionService } from 'stores/plugin/PluginExecutionService';
import { DispatchedOrder, Order } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { EXCEL_EXTENSIONS } from 'src/constants/constants';
import PartnerMatchingForm from 'src/forms/partner/matching/PartnerMatchingForm';
import { openConfirmUploadDialog } from 'src/screens/order-process/components/dialogs/openConfirmUploadDialog';
import { readExcelMigrationMultiSheet } from 'src/services/file/excel/readExcel-migration-multisheet';
import { toast } from 'src/third-parties/toast';

type OrderEntries = Array<readonly [string, Order[]]>;

export class MergeOrderStore {
  @observable
  accessor mergedOrders: Order[] = [];

  constructor(private oms: OMSStore) {}

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  @action.bound
  setMergedOrders(orders: Order[]) {
    this.mergedOrders = orders;
  }

  @action.bound
  async init() {
    await this.loadMergedOrders();
  }

  @action.bound
  async loadMergedOrders() {
    const response = await getOrders();

    if (response == null || response.success !== true) {
      return;
    }

    runInAction(() => {
      this.mergedOrders = response.data;
    });
  }

  @action.bound
  async mergeDispatchedOrders(orders: DispatchedOrder[]) {
    const plugins = this.oms.plugin.getPlugins(
      this.oms.user.mergeDispatchPlugin
    );
    const pluginExecutionService = new PluginExecutionService(
      this.oms,
      plugins,
      'merge-dispatched'
    );
    const transformedOrders = await pluginExecutionService.execute(orders);

    const response = await upsertOrders(transformedOrders);
    if (response?.success !== true) {
      return null;
    }
    const upsertedOrders = response.data;

    runInAction(() => {
      this.mergedOrders = [...this.mergedOrders, ...upsertedOrders];
    });

    return transformedOrders;
  }

  @action.bound
  async mergeOrders(files: File[]): Promise<Order[] | null> {
    // TODO SWP-540, 로직 올바르게 돌아가는지 체크

    const excelFiles = await this.readFilesMultisheet(files);
    if (excelFiles.length === 0) {
      return null;
    }

    const partnerMatchings = await this.oms.loading.reverseBatch(() =>
      this.findPartnerMatchings(excelFiles)
    );

    const orderEntries = partnerMatchings.map(
      (matching) => [matching.partner._id, transformToOrders(matching)] as const
    );

    const transformedOrderEntries = await this.oms.loading.batch(
      async () => await this.transformOrderEntires(orderEntries)
    );

    const transformedOrders = transformedOrderEntries.flatMap(
      ([, orders]) => orders
    );
    // TODO SWP-540, 로직 올바르게 돌아가는지 체크
    const response = await upsertOrders(transformedOrders);
    if (response?.success !== true) {
      return null;
    }
    const upsertedOrders = response.data;

    runInAction(() => {
      this.mergedOrders = [...this.mergedOrders, ...upsertedOrders];
    });

    return transformedOrders;
  }

  private async readFilesMultisheet(
    files: File[]
  ): Promise<MultiSheetExcelFile[]> {
    const { validatedFiles, invalidedFiles } = validateFiles(files, {
      accepts: EXCEL_EXTENSIONS,
      invalidatedFileNames: this.oms.order.uploadedFileNames,
    });

    const confirmed = await confirmUpload(validatedFiles, invalidedFiles);
    if (!confirmed) {
      return [];
    }

    const excelFiles = await this.oms.loading.batch(async () => {
      return await Promise.all(
        validatedFiles.map((file) =>
          readExcelMigrationMultiSheet(file, {
            includeRawData: true,
          })
        )
      );
    });

    return excelFiles.filter(isNotNil);
  }

  private findPartnerMatchings(
    excelFiles: MultiSheetExcelFile[]
  ): Promise<FileMatching[]> {
    return new Promise<FileMatching[]>((resolve) => {
      overlay.open(({ isOpen, close, unmount }) => (
        <PartnerMatchingForm
          open={isOpen}
          files={excelFiles}
          onSubmit={(matchings) => {
            close();
            unmount();
            resolve(matchings);
          }}
        />
      ));
    });
  }

  private async transformOrderEntires(
    orderEntries: OrderEntries
  ): Promise<OrderEntries> {
    const transformedOrderEntryPromises = orderEntries.map(
      async ([partnerId, orders]) => {
        const partner = this.oms.partner.getPartnerById(partnerId);
        if (partner == null) {
          return [partnerId, orders] as const;
        }

        const plugins = this.oms.plugin.getPlugins(partner.pluginIds ?? []);
        const pluginExecutionService = new PluginExecutionService(
          this.oms,
          plugins,
          'merge'
        );

        const transformedOrders = await pluginExecutionService.execute(orders);
        return [partnerId, transformedOrders] as const;
      }
    );

    return Promise.all(transformedOrderEntryPromises);
  }

  @action.bound
  async upsertOrders(orders: Order[]) {
    const indexByUniqueCode = new Map<string, number>(
      this.mergedOrders.map((order, index) => [order.uniqueCode, index])
    );
    const now = new Date().getTime();
    orders.forEach((order) => {
      const index = indexByUniqueCode.get(order.uniqueCode);
      if (index != null) {
        this.mergedOrders[index] = {
          ...this.mergedOrders[index],
          ...order,
          updatedAt: now,
        };
        return;
      }

      this.mergedOrders.push({
        ...order,
        updatedAt: now,
        registeredAt: now,
      });
    });

    await upsertOrders(orders);
  }

  @action.bound
  removeOrderByFilename(filename: string) {
    this.mergedOrders = this.mergedOrders.filter(
      (order) => order.originFile !== filename
    );
  }
}

async function confirmUpload(
  validatedFiles: File[],
  uploadedFiles: File[]
): Promise<boolean> {
  if (validatedFiles.length === 0) {
    toast.error('이미 업로드된 파일입니다.');
    return false;
  }

  if (uploadedFiles.length === 0) {
    return true;
  }

  const firstUploadedFileName = uploadedFiles.at(0)?.name;
  if (firstUploadedFileName == null) {
    return false;
  }

  const isConfirmed = await openConfirmUploadDialog(
    uploadedFiles.length,
    firstUploadedFileName
  );
  return isConfirmed;
}
