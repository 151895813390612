import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';

export type SelectOptionValue = string | number | boolean;

export interface SelectContextOption {
  value: SelectOptionValue;
  label: string;
}

export interface SelectContextValue {
  value?: SelectOptionValue;
  options: SelectContextOption[];
  onOptionMount: (option: SelectContextOption) => void;
  onOptionUnmount: (option: SelectContextOption) => void;
  onChange: (value: SelectOptionValue) => void;
}

const selectContext = createContext<SelectContextValue>({
  options: [],
  onOptionMount: noop,
  onOptionUnmount: noop,
  onChange: noop,
});

export const SelectContextProvider = selectContext.Provider;

export const useSelectContext = () => useContext(selectContext);
