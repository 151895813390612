import { BrandLogo, BrandLogoProps } from './BrandLogo';
import { getLogoMallBrand } from './getLogoMallBrand';

interface BrandLogoByNameProps extends Omit<BrandLogoProps, 'brand'> {
  name: string;
}

export function BrandLogoByName({ name, ...rest }: BrandLogoByNameProps) {
  return <BrandLogo brand={getLogoMallBrand(name)} {...rest} />;
}
