import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { IconControlTrashcan } from '@sweep/asset/icons';
import { MatchingProduct } from '@sweep/contract';
import { SearchSelect, SearchSelectOption } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { isEmptyString } from '@sweep/utils';
import {
  openProductCreateFormModal,
  openProductUpdateFormModal,
} from 'src/forms/product';
import { toast } from 'src/third-parties/toast';

interface MatchingProductInputProps {
  value: Partial<MatchingProduct>;
  onChange: (value: Partial<MatchingProduct> | null) => void;
  required?: boolean;
  filled?: boolean;
}

function MatchingProductInput({
  value,
  onChange,
  required = false,
  filled = false,
}: MatchingProductInputProps) {
  const oms = useOMSStore();
  const product =
    value.productId != null ? oms.product.getProduct(value.productId) : null;
  const productName = product?.productName;
  const productUnitnames = product?.units?.map((unit) => unit.unit) ?? [];

  const handleProductNameChange = (newProductName: string | null) => {
    if (newProductName == null) {
      return;
    }

    const productId = oms.product.productIdByName.get(newProductName);
    const product =
      productId != null ? oms.product.getProduct(productId) : null;
    if (product == null) {
      onChange({ ...value, productId: undefined, unit: undefined });
      return;
    }

    const productUnits = product.units?.map((unit) => unit.unit) ?? [];
    const isNotValidUnit =
      productUnits.length === 0 ||
      (value.unit != null && !productUnits.includes(value.unit));

    if (isNotValidUnit) {
      onChange({ ...value, productId, unit: undefined });
      return;
    }

    onChange({ ...value, productId });
  };

  const handleUnitChange = (newUnit: string | null) => {
    if (newUnit == null) {
      onChange({ ...value, unit: undefined });
      return;
    }

    onChange({ ...value, unit: newUnit });
  };

  const onProductCreate = async (productName: string) => {
    const product = await openProductCreateFormModal(productName);
    if (product == null) {
      return;
    }

    const registeredProduct = await oms.product.register(product);
    if (registeredProduct == null) {
      return;
    }

    onChange({ ...value, productId: registeredProduct._id, unit: undefined });
  };

  const onProductUnitCreate = async () => {
    if (product == null) {
      return;
    }

    const updatedProduct = await openProductUpdateFormModal(product);
    if (updatedProduct == null) {
      return;
    }

    const result = await oms.product.update(product._id, updatedProduct);

    if (result == null) {
      toast.error('상품 수정에 실패했습니다. 잠시 후 다시 시도해주세요.');
      return;
    }
  };

  const productNameOptions: SearchSelectOption[] = oms.product.productNames.map(
    (name) => ({
      label: name,
      value: name,
    })
  );
  const optionOptions: SearchSelectOption[] = productUnitnames.map((name) => ({
    label: name,
    value: name,
  }));

  return (
    <div className="gap-10px flex flex-wrap">
      <SearchSelect
        value={productName ?? null}
        onChange={handleProductNameChange}
        options={productNameOptions}
        placeholder="상품 검색"
        error={required && isEmptyString(productName)}
        className="min-w-[200px] flex-1"
        footer={
          <SearchSelect.Footer onClick={() => onProductCreate('')}>
            + 상품 추가하기
          </SearchSelect.Footer>
        }
      />

      {productUnitnames.length > 0 && (
        <SearchSelect
          value={value.unit ?? null}
          onChange={handleUnitChange}
          options={optionOptions}
          placeholder="옵션 검색"
          error={required && isEmptyString(value.unit)}
          className="min-w-[100px] flex-1"
          footer={
            <SearchSelect.Footer onClick={onProductUnitCreate}>
              + 옵션 추가하기
            </SearchSelect.Footer>
          }
        />
      )}

      <div className="h-40px w-72px py-8px pl-16px pr-4px flex rounded-lg border border-gray-300 bg-white focus-within:border-blue-400">
        <label>x</label>
        <input
          className="w-full outline-none"
          type="number"
          min={1}
          max={9999}
          value={value.quantity ?? 1}
          onChange={(event) => {
            const quantity = parseInt(event.target.value);
            onChange({ ...value, quantity });
          }}
          onBlur={(event) => {
            const quantity = parseInt(event.target.value);
            if (isNaN(quantity) || quantity < 1) {
              onChange({ ...value, quantity: 1 });
            }
          }}
        />
      </div>
      <button
        className={deleteButton({ filled })}
        onClick={() => onChange(null)}
      >
        <IconControlTrashcan className="text-gray-500" width={20} height={20} />
      </button>
    </div>
  );
}

const deleteButton = cva(
  'rounded-5px size-40px flex items-center justify-center',
  {
    variants: {
      filled: {
        true: 'bg-white',
        false: 'bg-gray-100',
      },
    },
  }
);

export default observer(MatchingProductInput);
