import { IconCheckMedium, IconCheckSmall } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';

interface CheckIconProps {
  checked: boolean;
  size: 'sm' | 'md';
}

export function CheckIcon({ checked, size }: CheckIconProps) {
  const icon = (() => {
    switch (size) {
      case 'sm':
        return <IconCheckSmall />;
      case 'md':
        return <IconCheckMedium />;
    }
  })();

  return <div className={container({ checked })}>{icon}</div>;
}

const container = cva('size-18px pl-2px pt-2px shrink-0', {
  variants: {
    checked: {
      true: 'text-blue-500',
      false: 'text-gray-300',
    },
  },
});
