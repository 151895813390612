import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

export function openConfirmDialog(title: string, description?: string) {
  const existingDescription = description != null;

  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, unmount }) => (
      <Dialog open={isOpen} onClose={unmount}>
        <Dialog.Title className={titleClass({ existingDescription })}>
          {title}
        </Dialog.Title>
        {existingDescription && (
          <Dialog.Description className="mb-28px">
            {description}
          </Dialog.Description>
        )}
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}

const titleClass = cva('mb-28px', {
  variants: {
    existingDescription: {
      true: 'mb-16px',
      false: 'mb-28px',
    },
  },
});
