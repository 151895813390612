import { z } from 'zod';

export const FileHeaderMappingResultSchema = z.object({
  isSuccess: z.boolean(),
  headerMatchReason: z.string().optional(),
  headerMatchDetails: z.string().optional(),

  headers: z.array(z.string()),
  headerKeys: z.array(z.string()),
  mappingColumn: z.record(z.string(), z.unknown()),
  defaultShippingCompany: z.string().nullable().optional(),
});

export type FileHeaderMappingResult = z.infer<
  typeof FileHeaderMappingResultSchema
>;
