import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openConfirmCancelDispatchDialog() {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-28px">
          진행 중인 주문 수집을 취소할까요?
        </Dialog.Title>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            돌아가기
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>취소하기</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
