import { keyBy } from 'es-toolkit';
import { PurchaseOrderFileRecord, Supplier } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { openPurchaseOrderFileProcessModal } from '../../components/PurchaseOrderFileProcessModal/openPurchaseOrderFileProcessModal';

export async function sendEmailWithPurchaseOrderFile(params: {
  oms: OMSStore;
  suppliers: Supplier[];
  purchaseOrderFileRecords: PurchaseOrderFileRecord[];
}) {
  const { oms, purchaseOrderFileRecords, suppliers } = params;

  const fileRecordsForSuppliers = purchaseOrderFileRecords.filter(
    (record) => record.supplierId != null
  );

  const userSupplierKeyBySupplierId = keyBy(
    suppliers,
    (supplier) => supplier._id
  );

  const purchaseOrderFileRecordsWithSupplierEmail = fileRecordsForSuppliers
    .map((record) => {
      const supplierId = record.supplierId;
      if (supplierId == null) {
        return null;
      }

      const supplier = userSupplierKeyBySupplierId[supplierId];

      if (supplier == null) {
        return null;
      }

      const supplierEmail = supplier.email;
      const supplierName = supplier.name;

      if (supplierEmail == null || supplierEmail === '') {
        return null;
      }

      return {
        record,
        supplierEmail,
        supplierName,
      };
    })
    .filter(isNotNil);

  const isEmailExists = purchaseOrderFileRecordsWithSupplierEmail.length > 0;
  if (!isEmailExists) {
    return;
  }

  await oms.sendEmailWithPurchaseOrderFileStore.bulkInsertNewRecordItemStatuses(
    purchaseOrderFileRecordsWithSupplierEmail.map(
      ({ record, supplierEmail, supplierName }) => ({
        recordId: record._id,
        email: supplierEmail,
        filename: record.excelFileRecord.filename,
        supplierName,
      })
    )
  );

  const newRecordIds = purchaseOrderFileRecordsWithSupplierEmail.map(
    (record) => record.record._id
  );
  oms.sendEmailWithPurchaseOrderFileStore.setSelectedRecordIds(newRecordIds);

  await openPurchaseOrderFileProcessModal();
  oms.sendEmailWithPurchaseOrderFileStore.clear();
}
