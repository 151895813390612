import { observer } from 'mobx-react-lite';
import { IconCs } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';

interface InquiryEmptyScreenProps {
  onDispatchClick: () => void;
}

function InquiryEmptyScreen({ onDispatchClick }: InquiryEmptyScreenProps) {
  return (
    <div className="gap-24px py-20px px-40px flex h-full flex-col bg-gray-100">
      <p className="text-extrabold-l text-gray-700">CS 관리</p>
      <div className="gap-20px py-40px shadow-line rounded-16px flex h-fit w-full flex-col items-center bg-white">
        <IconCs />
        <p className="text-semibold-m text-gray-700">
          쇼핑몰의 <span className="text-blue-500">CS</span>를 한 번에 수집하고
          처리하세요.
        </p>
        <Button onClick={onDispatchClick} className="w-200px">
          CS 불러오기
        </Button>
      </div>
    </div>
  );
}

export default observer(InquiryEmptyScreen);
