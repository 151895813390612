import { overlay } from 'overlay-kit';
import ProductDeleteDialog from './ProductDeleteDialog';

export function openProductDeleteDialog(productIds: string[]) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <ProductDeleteDialog
        open={isOpen}
        onClose={(result) => {
          close();
          unmount();
          resolve(result);
        }}
        productIds={productIds}
      />
    ))
  );
}
