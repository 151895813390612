import { overlay } from 'overlay-kit';
import { CombinedShippingResult } from '../../interface';
import CombinedShippingCreateFormModal from './CombinedShippingCreateFormModal';

export function openCombinedShippingCreateFormModal() {
  return new Promise<CombinedShippingResult | null>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <CombinedShippingCreateFormModal
        open={isOpen}
        onSubmit={(result) => {
          close();
          unmount();
          resolve(result);
        }}
      />
    ))
  );
}
