import { action, computed, observable } from 'mobx';
import { NormalizedOrder } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { isShippingOrderStatusShipped } from '../services/isShippingOrderStatusShipped';

export class OrderShippingShippedStore {
  @observable
  accessor selectedOrders: NormalizedOrder[] = [];

  @computed
  get shippingStatusShippedOrders() {
    return this.oms.order.normalizedOrders.filter(isShippingOrderStatusShipped);
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  setSelectedOrders = (orders: NormalizedOrder[]) => {
    this.selectedOrders = orders;
  };
}
