import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { MatchingProduct } from '@sweep/contract';
import { Badge } from '@sweep/sds';

interface MatchedCMConfirmFormProps {
  products: MatchingProduct[];
}

function MatchedCMConfirmFormRow({ products }: MatchedCMConfirmFormProps) {
  const oms = useOMSStore();

  return (
    <div className="gap-8px flex w-full min-w-0 flex-wrap items-center">
      {products.map((matchingProduct, index) => {
        const product = oms.product.getProduct(matchingProduct.productId);
        if (product == null) {
          return null;
        }

        return (
          <Badge key={index} color="blue" variant="soft">
            {product.productName}&nbsp;
            {matchingProduct.unit != null && `${matchingProduct.unit} `}
            <span className="text-gray-500">x{matchingProduct.quantity}</span>
          </Badge>
        );
      })}
    </div>
  );
}

export default observer(MatchedCMConfirmFormRow);
