import { isNil, uniqBy } from 'es-toolkit';
import { Partner } from 'models/Partner';
import { Order } from '@sweep/contract';

export async function updateLastActiveMatchingIdFromOrders(params: {
  orders: Order[];
  updateHandler: (
    partnerId: string,
    partnerDTO: Partial<Partner>
  ) => Promise<void>;
  errorHandler?: (error: unknown) => void;
}): Promise<void> {
  const { orders, updateHandler, errorHandler } = params;

  const partnerIdAndMatchingIdPairs = orders.map((order) => [
    order.partnerId,
    order.matchingId,
  ]);

  const uniquePartnerIdAndMatchingIdPairs = uniqBy(
    partnerIdAndMatchingIdPairs,
    ([partnerId, matchingId]) => `${partnerId}-${matchingId}`
  );

  const lastActiveMatchingIdByPartnerId =
    uniquePartnerIdAndMatchingIdPairs.reduce<Record<string, string>>(
      (acc, [partnerId, matchingId]) => {
        if (isNil(partnerId) || isNil(matchingId)) {
          return acc;
        }

        acc[partnerId] = matchingId;
        return acc;
      },
      {}
    );

  const results = await Promise.allSettled(
    Object.entries(lastActiveMatchingIdByPartnerId).map(
      ([partnerId, matchingId]) =>
        updateHandler(partnerId, { lastActiveMatchingId: matchingId })
    )
  );

  const failedUpdates = results.filter(
    (result) => result.status === 'rejected'
  );

  if (failedUpdates.length > 0 && errorHandler != null) {
    failedUpdates.forEach((result) => {
      errorHandler(result.reason);
    });
  }

  return;
}
