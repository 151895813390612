import { overlay } from 'overlay-kit';
import ProductUpdateSupplierFormModal from './ProductUpdateSupplierFormModal';

/**
 * NOTE(@이지원) 빈 공급사를 선택하는 경우 ""을 반환
 */
export function openProductUpdateSupplierFormModal() {
  return new Promise<string | null>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <ProductUpdateSupplierFormModal
        open={isOpen}
        onSubmit={(supplierId) => {
          resolve(supplierId);
          close();
          unmount();
        }}
      />
    ))
  );
}
