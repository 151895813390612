import { CreateOrderShippingProcessHistoryDTO } from '@sweep/contract';
import { requestCreateOrderShippingProcessHistory } from 'src/network/order-shipping-process-history/createOrderShippingProcessHistory';
import ErrorReporter from 'src/third-parties/ErrorReporter';

export function saveOrderShippingProcessHistory(
  dto: CreateOrderShippingProcessHistoryDTO
) {
  requestCreateOrderShippingProcessHistory(dto).catch((error) => {
    ErrorReporter.captureError(error);
  });
}
