import ErrorReporter from 'src/third-parties/ErrorReporter';

/*
 * NOTE(@hungjoon): SWP-447 임시 코드
 * 데이터 Example
 *
 * this.oms.user.excelHeaders: 수령인, 수령인 연착처, 핸드폰번호, 고유번호
 * this.oms.user.excelColumnMapping: {name: '수령인', uniqueCode: '고유번호'}
 */
export function migrateExcelColumnMapping(
  excelColumnMapping: Record<string, string>
): Record<string, string> {
  const entries = Object.entries(excelColumnMapping);

  const targetEntries = entries.filter(([key]) => key === 'uniqueCode');
  if (targetEntries.length === 0) {
    return { ...excelColumnMapping, uniqueCodeAfterCustomization: '고유번호' };
  }

  if (targetEntries.length > 1) {
    ErrorReporter.captureMessage('SWP-380: uniqueCode 가 여러 개 있습니다.', {
      extra: {
        excelColumnMapping,
      },
    });
  }

  const targetEntryValue = targetEntries[0][1];

  const removedEntries = entries.filter(([key]) => key !== 'uniqueCode');
  const addedEntries = [['uniqueCodeAfterCustomization', targetEntryValue]];
  const newEntries = [...removedEntries, ...addedEntries];

  return Object.fromEntries(newEntries);
}
