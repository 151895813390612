import { formatDate } from '@sweep/utils';

const FILENAME_SUFFIX = '운송장첨부';

export function getFilename(documentName: string): string {
  const formattedDate = formatDate(new Date(), 'yy.MM.dd');
  const sanitizedDocumentName = removeInvalidFilenameCharacters(documentName);

  return `${formattedDate}_${sanitizedDocumentName}_${FILENAME_SUFFIX}.xlsx`;
}

function removeInvalidFilenameCharacters(text: string): string {
  const invalidCharacters = /[/\\:*?"|<>]/g;
  return text.replace(invalidCharacters, '');
}
