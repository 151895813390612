import { formatDate } from '@sweep/utils';

interface InquiryReviewReplyResultProps {
  replyDate: number;
}

function InquiryReviewReplyResult({
  replyDate,
}: InquiryReviewReplyResultProps) {
  return (
    <div className="gap-8px flex w-full flex-col">
      <div className="px-15px py-12px rounded-8px w-full border-[1.5px] border-gray-200">
        <p className="text-medium-m text-gray-400">처리 완료된 문의입니다.</p>
      </div>
      <div className="flex justify-end">
        <p className="text-medium-s text-gray-500">
          처리일시: {formatDate(new Date(replyDate), 'yyyy-MM-dd HH:mm:ss')}
        </p>
      </div>
    </div>
  );
}

export default InquiryReviewReplyResult;
