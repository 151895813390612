import { action, computed, observable } from 'mobx';
import { NormalizedOrder } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { isShippingOrderStatusReviewing } from '../services/isShippingOrderStatusReviewing';

export class OrderShippingReviewingStore {
  @observable
  accessor selectedOrders: NormalizedOrder[] = [];

  @computed
  get shippingStatusReviewingOrders() {
    return this.oms.order.normalizedOrders.filter(
      isShippingOrderStatusReviewing
    );
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  setSelectedOrders = (orders: NormalizedOrder[]) => {
    this.selectedOrders = orders;
  };

  downloadPurchaseOrder = (filenames: string[]) => {
    const ordersByFilename: Record<string, NormalizedOrder[]> = {};
    this.shippingStatusReviewingOrders.forEach((order) => {
      if (order.purchaseOrderFile == null) {
        return;
      }

      if (ordersByFilename[order.purchaseOrderFile] == null) {
        ordersByFilename[order.purchaseOrderFile] = [order];
        return;
      }

      ordersByFilename[order.purchaseOrderFile].push(order);
    });

    filenames.forEach((filename) => {
      const orders = ordersByFilename[filename];
      if (orders == null) {
        return;
      }

      const downloadPurchaseOrder = this.oms.user.isRetailer
        ? this.oms.order.file.downloadRetailerPurchaseOrder
        : this.oms.order.file.downloadSupplierPurchaseOrder;

      downloadPurchaseOrder(orders, filename);
    });
  };
}
