import { Inquiry, INQUIRY_CATEGORY } from '@sweep/contract';
import { formatDate } from '@sweep/utils';

export function formatInquiry(inquiry: Inquiry) {
  return {
    date: formatInquiryDate(inquiry),
    categoryType: formatInquiryCategoryType(inquiry),
    productName: formatInquiryProductNames(inquiry),
    title: formatInquiryTitle(inquiry),
    buyerName: formatInquiryBuyerName(inquiry),
    orderNumber: formatInquiryOrderNumbers(inquiry),
  };
}

function formatInquiryDate(inquiry: Inquiry) {
  return inquiry.inquiryDate != null
    ? formatDate(new Date(inquiry.inquiryDate), 'yyyy-MM-dd HH:mm:ss')
    : '';
}

function formatInquiryCategoryType(inquiry: Inquiry) {
  const category = INQUIRY_CATEGORY[inquiry.inquiryCategory];
  const type = inquiry.inquiryType;
  if (type == null) {
    return category;
  }
  return `${category}(${type})`;
}

function formatInquiryProductNames(inquiry: Inquiry) {
  return (inquiry.productNames ?? inquiry.productIds)?.join(', ');
}

function formatInquiryTitle(inquiry: Inquiry) {
  return inquiry.title ?? inquiry.content;
}

function formatInquiryBuyerName(inquiry: Inquiry) {
  return inquiry.buyerName ?? '***';
}

function formatInquiryOrderNumbers(inquiry: Inquiry) {
  return inquiry.orderNumbers?.join(', ');
}
