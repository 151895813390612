import { IconSortDown, IconSortUp } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';

export type SortType = 'ASC' | 'DESC' | null;

interface SortIconProps extends React.HTMLAttributes<HTMLDivElement> {
  sort: SortType;
}

export function SortIcon({ sort, className, ...rest }: SortIconProps) {
  return (
    <div className={container({ className })} {...rest}>
      {sort && (
        <>
          <IconSortUp
            className={icon({ position: 'top', active: sort === 'ASC' })}
          />
          <IconSortDown
            className={icon({ position: 'bottom', active: sort === 'DESC' })}
          />
        </>
      )}
    </div>
  );
}

const container = cva('w-14px h-15px relative');

const icon = cva('', {
  variants: {
    active: {
      true: 'text-blue-500',
      false: 'text-gray-300',
    },
    position: {
      top: 'top-0',
      bottom: 'bottom-0',
    },
  },
});
