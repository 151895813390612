import { ExcelFill, Order } from '@sweep/contract';

const EXCEL_FILL_PINK: ExcelFill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFC7CD' },
};

export function highlightCombinedOrders(orders: Order[]): Order[] {
  const countByUniqueCode = new Map<string, number>();
  orders.forEach((order) => {
    const { uniqueCode } = order;
    const uniqueCodes = uniqueCode.split(',').map((code) => code.trim());
    uniqueCodes.forEach((uniqueCode) => {
      const count = countByUniqueCode.get(uniqueCode) ?? 0;
      countByUniqueCode.set(uniqueCode, count + 1);
    });
  });

  return orders.map<Order>((order) => {
    const { uniqueCode } = order;
    const isCombinedOrder = uniqueCode.split(',').length > 1;
    const count = countByUniqueCode.get(uniqueCode) ?? 0;
    const isDividedOrder = count > 1;

    if (isCombinedOrder || isDividedOrder) {
      return {
        ...order,
        excelModel: {
          ...order.excelModel,
          styles: {
            ...order.excelModel?.styles,
            address: {
              fill: EXCEL_FILL_PINK,
            },
          },
        },
      };
    }

    return order;
  });
}
