import { observer } from 'mobx-react-lite';
import { IconXDeleteModal } from '@sweep/asset/icons';
import { Modal } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { If } from '@sweep/utils/react';
import { amplitude } from 'src/third-parties/amplitude';
import { DispatchModalStore } from '../../store/DispatchModalStore';
import { openConfirmCancelDispatchDialog } from '../dialogs/openConfirmCancelDispatchDialog';
import DispatchFailureResult from './DispatchFailureResult';
import DispatchLoading from './DispatchLoading';
import { LoadingLottie } from './LoadingLottie';

interface DispatchModalProps {
  open: boolean;
  onClose: () => void;
  store: DispatchModalStore;
  abortController: AbortController;
}

function DispatchModal({
  open,
  onClose,
  store,
  abortController,
}: DispatchModalProps) {
  const handleCloseClick = async () => {
    amplitude.track('Click Close-order-dispatch');

    if (store.isDispatchLoading) {
      const isConfirmed = await openConfirmCancelDispatchDialog();
      if (isConfirmed) {
        abortController.abort();
        onClose();
      }
      return;
    }

    onClose();
  };

  if (!store.isDispatchLoading && store.isSuccessful) {
    onClose();
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className={modal({ loading: store.isDispatchLoading })}
    >
      <div className="flex items-center justify-between">
        <div className="flex h-[48px] items-center">
          <p className="text-extrabold-l">주문 수집하기</p>
          {store.isTransformLoading && <LoadingLottie />}
        </div>
        <button onClick={handleCloseClick}>
          <IconXDeleteModal className="text-gray-700" />
        </button>
      </div>
      <If is={store.isDispatchLoading}>
        <DispatchLoading store={store} />
      </If>
      <If is={!store.isDispatchLoading && !store.isSuccessful}>
        <DispatchFailureResult store={store} />
      </If>
    </Modal>
  );
}

const modal = cva('px-32px pt-32px max-h-[612px]', {
  variants: {
    loading: {
      true: 'pb-40px',
      false: 'pb-20px',
    },
  },
});

export default observer(DispatchModal);
