import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Order } from '@sweep/contract';
import { If } from '@sweep/utils/react';
import { TableFilterStore } from '../TableFilterStore';
import TableFilterContent from './TableFilterContent';
import FilterTriggerButton from './TableFilterTrigger';

interface TableFilterDropdownProps {
  store: TableFilterStore;
  orders: Order[];
  headerKey: string;
}

function TableFilterDropdown({
  store,
  orders,
  headerKey,
}: TableFilterDropdownProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <FilterTriggerButton
        ref={ref}
        onClick={() => setOpen(!open)}
        active={store.isFiltered(headerKey)}
      />
      <If is={open}>
        <TableFilterContent
          store={store}
          orders={orders}
          headerKey={headerKey}
          open={open}
          setOpen={setOpen}
          triggerRef={ref}
        />
      </If>
    </>
  );
}

export default observer(TableFilterDropdown);
