import { z } from 'zod';
import { PurchaseOrderFileRecordSchema } from '../../type/purchase-order-file-record/PurchaseOrderFileRecord';

export const CreatePurchaseOrderFileRecordDTOSchema =
  PurchaseOrderFileRecordSchema.omit({
    _id: true,
    userId: true,
    createdAt: true,
  });

export type CreatePurchaseOrderFileRecordDTO = z.infer<
  typeof CreatePurchaseOrderFileRecordDTOSchema
>;
