import { observer } from 'mobx-react-lite';
import { IconCheckSmall, IconFactory } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { SendEmailStatus } from 'src/stores/SendEmailWithPurchaseOrderFileStore';

type RecordItemProps = {
  supplierName: string;
  supplierEmail: string;
  filename: string;
  emailStatus: SendEmailStatus;
  isSelected: boolean;
  onClick: () => void;
};

export const RecordItem = observer(
  ({
    supplierName,
    supplierEmail,
    filename,
    isSelected,
    onClick,
  }: RecordItemProps) => {
    return (
      <div
        className={container({
          isSelected,
        })}
        onClick={onClick}
      >
        <div className="flex w-full items-center justify-between gap-4">
          <div className="gap-12px flex items-center">
            <IconCheckSmall className={iconCheck({ isSelected })} />
            <div className="flex size-[44px] items-center justify-center rounded-[8px] bg-gray-100">
              <IconFactory className={iconFactory({ isSelected })} />
            </div>
            <div className="gap-3px flex flex-col">
              <p className="text-bold-m max-w-[180px] truncate text-gray-700">
                {supplierName}
              </p>
              <p className="text-medium-s max-w-[180px] shrink-0 truncate text-gray-500">
                {filename}
              </p>
            </div>
          </div>

          <div className="text-medium-s w-full max-w-[180px] flex-1 truncate text-right text-gray-500">
            {supplierEmail}
          </div>
        </div>
      </div>
    );
  }
);

const iconFactory = cva('size-[24px] text-blue-200', {
  variants: {
    isSelected: {
      true: '',
      false: 'bg-gray-100',
    },
  },
});

const iconCheck = cva('size-[19px]', {
  variants: {
    isSelected: {
      true: 'text-blue-500',
      false: 'text-gray-300 hover:text-blue-500',
    },
  },
});

const container = cva(
  'py-14px px-20px gap-16px flex w-full cursor-pointer select-none gap-2 rounded-[12px]',
  {
    variants: {
      isSelected: {
        true: 'bg-gray-100',
        false: 'bg-white hover:bg-gray-100',
      },
    },
  }
);
