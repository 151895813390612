import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import {
  Badge,
  SelectableCellContext,
  SelectableColumnDef,
  SelectableTable,
  Tooltip,
} from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { useIsOverflow } from '@sweep/utils/react';
import { CombinedShippingTableProduct } from '../../interface';

interface CombinedShippingProductTableProps {
  items: CombinedShippingTableProduct[];
  disabledItems: CombinedShippingTableProduct[];
  selectedItems: CombinedShippingTableProduct[];
  onSelect: (products: CombinedShippingTableProduct[]) => void;
}

function CombinedShippingProductTable({
  items,
  disabledItems,
  selectedItems,
  onSelect,
}: CombinedShippingProductTableProps) {
  const columns: SelectableColumnDef<CombinedShippingTableProduct>[] = [
    {
      header: (context) => (
        <SelectableTable.HeaderCell context={context} className="flex-1">
          상품명
        </SelectableTable.HeaderCell>
      ),
      cell: (context) => <ProductNameCell context={context} />,
    },
    {
      header: (context) => (
        <SelectableTable.HeaderCell context={context} className="flex-1">
          옵션
        </SelectableTable.HeaderCell>
      ),
      cell: (context) => <OptionCell context={context} />,
    },
    {
      header: (context) => (
        <SelectableTable.HeaderCell context={context} className="flex-1">
          공급사
        </SelectableTable.HeaderCell>
      ),
      cell: (context) => (
        <SelectableTable.Cell
          context={context}
          className={cell({
            empty: context.row.supplierName == null,
            selected: context.selected,
            disabled: context.disabled,
          })}
        >
          {context.row.supplierName ?? '없음'}
        </SelectableTable.Cell>
      ),
    },
  ];

  return (
    <SelectableTable
      className="h-fit w-full overflow-y-auto bg-white"
      items={items}
      columns={columns}
      getKey={getKey}
      selectedItems={selectedItems}
      onSelect={onSelect}
      disabledItems={disabledItems}
      highlightSelectedRow={false}
    />
  );
}

function ProductNameCell({
  context,
}: {
  context: SelectableCellContext<CombinedShippingTableProduct>;
}) {
  return (
    <SelectableTable.Cell
      context={context}
      className={cell({
        selected: context.selected,
        disabled: context.disabled,
      })}
    >
      {context.row.productName}
    </SelectableTable.Cell>
  );
}

function OptionCell({
  context,
}: {
  context: SelectableCellContext<CombinedShippingTableProduct>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(ref, 'x');

  return (
    <SelectableTable.Cell
      context={context}
      className={cell({ padding: 'narrow' })}
    >
      <Tooltip disabled={!isOverflow}>
        <Tooltip.Trigger>
          {context.row.option != null && (
            <Badge
              ref={ref}
              variant="soft"
              color={
                context.selected || context.disabled ? 'lightGray' : 'blue'
              }
              className="text-ellipsis-nowrap"
            >
              {context.row.option}
            </Badge>
          )}
        </Tooltip.Trigger>
        <Tooltip.Content>{context.row.option}</Tooltip.Content>
      </Tooltip>
    </SelectableTable.Cell>
  );
}

function getKey(product: CombinedShippingTableProduct) {
  return `${product.productId}-${product.option}-${product.supplierId ?? ''}`;
}

const cell = cva('h-40px', {
  variants: {
    empty: {
      true: 'text-gray-400',
      false: '',
    },
    disabled: {
      true: 'text-gray-400',
      false: '',
    },
    selected: {
      true: 'text-gray-400',
      false: '',
    },
    padding: {
      narrow: 'px-8px',
    },
  },
});

export default observer(CombinedShippingProductTable);
