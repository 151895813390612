import { ForwardedRef, forwardRef } from 'react';
import { cva } from '@sweep/tailwind';

interface BadgeProps {
  color: 'gray' | 'blue' | 'orange' | 'green' | 'lightGray';
  variant: 'soft' | 'outline';
  size?: 'sm' | 'md';
  className?: string;
  children?: React.ReactNode;
}

function _Badge(
  { color, variant, size = 'md', className, children }: BadgeProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref} className={badge({ color, variant, size, className })}>
      {children}
    </div>
  );
}

const badge = cva('size-fit', {
  variants: {
    color: {
      gray: '',
      blue: '',
      orange: '',
      green: '',
      lightGray: '',
    },
    variant: {
      solid: '',
      soft: '',
      outline: 'border border-gray-200',
    },
    size: {
      sm: 'text-semibold-xs px-8px py-4px rounded-4px',
      md: 'text-medium-s px-12px py-4px rounded-8px',
    },
  },
  compoundVariants: [
    // soft color
    {
      color: 'gray',
      variant: 'soft',
      className: 'bg-gray-100 text-gray-700',
    },
    {
      color: 'blue',
      variant: 'soft',
      className: 'bg-gray-100 text-blue-700',
    },
    {
      color: 'orange',
      variant: 'soft',
      className: 'bg-gray-100 text-orange-500',
    },
    {
      color: 'green',
      variant: 'soft',
      className: 'bg-gray-100 text-green-500',
    },
    {
      color: 'lightGray',
      variant: 'soft',
      className: 'bg-gray-100 text-gray-400',
    },
    // outline size
    {
      variant: 'outline',
      size: 'sm',
      className: 'py-3px px-7px',
    },
    {
      variant: 'outline',
      size: 'md',
      className: 'py-3px px-11px',
    },
    // outline color
    {
      color: 'gray',
      variant: 'outline',
      className: 'bg-white text-gray-700',
    },
    {
      color: 'blue',
      variant: 'outline',
      className: 'bg-white text-blue-700',
    },
    {
      color: 'orange',
      variant: 'outline',
      className: 'bg-white text-orange-500',
    },
    {
      color: 'green',
      variant: 'outline',
      className: 'bg-white text-green-500',
    },
    {
      color: 'lightGray',
      variant: 'outline',
      className: 'bg-white text-gray-400',
    },
  ],
});

export const Badge = forwardRef(_Badge);
