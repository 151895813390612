/* eslint-disable simple-import-sort/imports */
import '../config/sentry-init';
import './index.css';
import '@sweep/tailwind/index.css';

import { activateScan } from './third-parties/ReactScan';

import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import Hotjar from '@hotjar/browser';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AMPLITUDE_API_KEY, NODE_ENV } from './env';
import { startMockingOnlyDev } from './mocks/browser';
import { amplitude } from './third-parties/amplitude';

// Google Ads 전환 추적 (랜딩페이지 CTA)
declare global {
  interface Window {
    gtag_report_conversion: () => void;
  }
}

const SCAN_ENABLED = false;
if (SCAN_ENABLED) {
  activateScan();
}

amplitude.init(AMPLITUDE_API_KEY, {
  autocapture: true,
});

if (NODE_ENV === 'production') {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  });
  amplitude.add(sessionReplayTracking);

  const siteId = 5278372;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

const GTM_ID = 'GTM-T6KPK6W5';

TagManager.initialize({
  gtmId: GTM_ID,
});

startMockingOnlyDev().then(() => {
  window.global ||= window;

  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container!);

  root.render(
    <Router>
      <App />
    </Router>
  );
});
