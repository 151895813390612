import { z } from 'zod';

export const SpreadsheetHeaderMappingSchema = z.object({
  _id: z.string().optional(),
  userId: z.string(),
  headers: z.array(z.string()),
  columnMappings: z.record(z.string()),
  createdAt: z.string(),

  originalFilename: z.string(),
});

export type SpreadsheetHeaderMapping = z.infer<
  typeof SpreadsheetHeaderMappingSchema
>;
