import { z } from 'zod';

export const ExcelConfigurationSchema = z.object({
  headers: z.array(z.string()),
  headerKeys: z.array(z.string()),
  columnMappings: z.record(z.string(), z.string()),
  separateAddressEnabled: z.boolean(),
  userExcelSettingFields: z
    .array(
      z.object({
        target: z.string(),
        type: z.enum(['number']),
      })
    )
    .optional(),

  // isTemplateEnabled: z.boolean(),
});

export type ExcelConfiguration = z.infer<typeof ExcelConfigurationSchema>;
