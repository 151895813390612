import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openProductUpdateFormCloseDialog() {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-[28px]">
          상품을 수정하지 않고 화면을 닫을까요?
        </Dialog.Title>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
