import { z } from 'zod';
import { FileHeaderMappingResultSchema } from '../../type/FileHeaderMappingResult';

export const CreateOrderShippingProcessHistoryDTOSchema = z.object({
  isSuccess: z.boolean(),
  userId: z.string(),

  uploadFilename: z.string(),
  uploadFilePassword: z.string().nullable().optional(),

  fileHeaderMappingResult: FileHeaderMappingResultSchema,

  appliedProcessors: z.array(z.string()),
  processedItems: z.array(z.record(z.string(), z.unknown())),
});

export type CreateOrderShippingProcessHistoryDTO = z.infer<
  typeof CreateOrderShippingProcessHistoryDTOSchema
>;
