import { Column, Workbook } from 'exceljs';
import { ExcelFileRecord, Order } from '@sweep/contract';
import { isNotEmptyString } from '@sweep/utils';
import { colorDict } from 'src/utils/colorDict';
import { isValid } from 'src/utils/utils';
import { applyExcelModel } from './applyExcelModel';
import { applyFields } from './applyFields';
import { COLUMN_WIDTHS } from './columnWidth';

const STRING_HEADERS = [
  '주문번호',
  '상품코드',
  '연락처',
  '우편번호',
  '옵션',
  '개수',
  '운송장번호',
];

const STRING_FORMAT = '@';

export function addWorkSheet(params: {
  workbook: Workbook;
  record: ExcelFileRecord;
}): Workbook {
  const { workbook, record } = params;
  const {
    filename,
    columnMappings,
    headerKeys,
    userExcelSettingFields,
    separateAddressEnabled,
  } = record;

  let orders = record.orders as Order[];

  const sheetName = filename.replace(/[*?:\\/\[\]]/g, ' ');

  orders = orders.map((order) => {
    Object.keys(order).forEach((key) => {
      const value = order[key];
      if (typeof value === 'number' && value.toString().length > 9) {
        order[key] = value.toString();
      }
    });

    return order;
  });

  const headers = headerKeys.map((col: string) => columnMappings[col]);
  if (separateAddressEnabled) {
    headerKeys.push('mainAddress', 'detailAddress');
    headers.push('메인주소', '상세주소');
  }

  const worksheet = workbook.addWorksheet(`${sheetName}`);
  const headerRow = worksheet.addRow(headers);

  orders =
    userExcelSettingFields != null
      ? applyFields(orders, userExcelSettingFields)
      : orders;

  const addressMap = new Map<string, number>();
  orders.forEach(({ address }) => {
    if (isNotEmptyString(address)) {
      const count = addressMap.get(address) ?? 0;
      addressMap.set(address, count + 1);
    }
  });

  orders.forEach((order) => {
    const row = worksheet.addRow(
      headerKeys.map((col: string) => {
        let value = order[col];

        // name의 길이가 1이면, value에 '님'을 추가
        if (
          col === 'name' &&
          order[col] != null &&
          (order[col] as string).length === 1
        ) {
          value += '님';
        }

        if (col === 'contactNumber' && !isValid(order[col])) {
          value = order['telephoneNumber'];
        }

        if (col === 'price' || col === 'quantity' || col === 'shippingPrice') {
          value = Number(value) !== Number(value) ? value : Number(value);
        } //NaN 이면 그대로, 아니면 숫자로 변환

        return typeof value === 'string' ? value.normalize('NFC') : value;
      })
    );

    // 주소가 중복된 경우 노란색으로 칠하기
    const addressIndex = headerKeys.findIndex((col) => col === 'address');
    const addressExcelIndex = addressIndex + 1;

    const hasAddressColumn = addressIndex !== -1;
    const hasMultipleAddress =
      typeof order.address === 'string' &&
      (addressMap.get(order.address) ?? 0) > 1;

    const isAddressDuplicated = hasAddressColumn && hasMultipleAddress;

    if (isAddressDuplicated) {
      row.getCell(addressExcelIndex).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }, // 노란색
      };
    }

    if (order.isIncludedSpecialCharacterInName === true) {
      const nameExcelIndex =
        headerKeys.findIndex((col: string) => col === 'name') + 1;

      if (nameExcelIndex !== 0) {
        row.getCell(nameExcelIndex).font = {
          color: { argb: 'FF0000' },
        };
      }
    }

    if (isValid(order.isPostCodeOutdated)) {
      const postCodeIndex = headerKeys.findIndex(
        (col: string) => col === 'postCode'
      );
      const postCodeExcelIndex = postCodeIndex + 1;

      const hasPostCodeColumn = postCodeExcelIndex !== -1;
      if (hasPostCodeColumn) {
        row.getCell(postCodeExcelIndex).font = {
          color: { argb: 'FF0000' }, // 빨간색
        };
      }
    }

    if (isValid(order.isQuantityMoreThanOne)) {
      const quantityIndex = headerKeys.findIndex(
        (col: string) => col === 'quantity'
      );
      const quantityExcelIndex = quantityIndex + 1;
      const hasQuantityColumn = quantityExcelIndex !== -1;
      if (hasQuantityColumn) {
        row.getCell(quantityExcelIndex).font = {
          color: { argb: 'FF0000' }, // 빨간색
          bold: true,
        };
      }
    }

    const contactNumberIndex = headerKeys.findIndex(
      (col: string) => col === 'contactNumber'
    );
    const contactNumberExcelIndex = contactNumberIndex + 1;
    if (contactNumberIndex !== -1) {
      let contactNumber = row.getCell(contactNumberExcelIndex).value;
      if (typeof contactNumber === 'string') {
        contactNumber = contactNumber.replace(/-/g, '');
        // 0503, 0502 등의 안심번호
        const isSafeNumber =
          contactNumber.startsWith('050') && contactNumber.length === 12;

        if (
          !isSafeNumber &&
          (contactNumber.slice(0, 3) !== '010' ||
            (contactNumber.length !== 11 && contactNumber.length !== 10))
        ) {
          row.getCell(contactNumberExcelIndex).font = {
            color: { argb: 'FF0000' },
          };
        }
      }
    }

    if (addressIndex !== -1) {
      const isAddressValid = order.isAddressValid;
      if (isAddressValid !== 'okay' && isAddressValid != null) {
        if (colorDict[isAddressValid] != null) {
          row.getCell(addressExcelIndex).font = {
            bold: true,
            color: { argb: colorDict[isAddressValid][0] }, // 연한 초록색, fixed 된것들
          };
        }
      }
      if (order.memo) {
        row.getCell(addressExcelIndex).note = {
          texts: order.memo,
        };
      }
    }

    STRING_HEADERS.forEach((textCol) => {
      const colNum = headers.indexOf(textCol) + 1;
      if (colNum > 0) {
        row.getCell(colNum).numFmt = STRING_FORMAT;
      }
    });

    const excelModel = order.excelModel;
    if (excelModel != null) {
      applyExcelModel(row, excelModel, headerKeys);
    }
  });

  // 엑셀 beautify
  headerRow.eachCell((cell: any) => {
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
    };
  });

  worksheet.autoFilter = {
    from: 'A1',
    to: `${String.fromCharCode(64 + headerKeys.length)}1`,
  };

  worksheet.columns = headerKeys.map<Partial<Column>>((headerKey) => ({
    key: headerKey,
    width: COLUMN_WIDTHS[headerKey] ?? 20,
  }));

  return workbook;
}
