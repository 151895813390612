import {
  GET_NORMALIZED_ORDERS_URL,
  GET_ORDERS_URL,
  GetNormalizedOrdersResponse,
  GetOrdersResponse,
  NormalizedOrder,
  Order,
  REPLACE_ALL_ORDERS_URL,
  REPLACE_NORMALIZED_ORDERS_URL,
  REPLACE_ORDERS_URL,
  ReplaceAllOrdersBody,
  ReplaceNormalizedOrdersBody,
  ReplaceOrdersBody,
  UPSERT_NORMALIZED_ORDERS_URL,
  UPSERT_ORDERS_URL,
  UpsertNormalizedOrdersBody,
  UpsertOrdersBody,
  UpsertOrdersResponse,
} from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function getOrders() {
  const response =
    await api.get<APIResponse<GetOrdersResponse>>(GET_ORDERS_URL);

  return response?.data;
}

export async function getNormalizedOrders() {
  const response = await api.get<APIResponse<GetNormalizedOrdersResponse>>(
    GET_NORMALIZED_ORDERS_URL
  );

  return response?.data;
}

export async function upsertOrders(orders: Order[]) {
  const response = await api.put<
    APIResponse<UpsertOrdersResponse>,
    UpsertOrdersBody
  >(UPSERT_ORDERS_URL, {
    orders,
  });

  return response?.data;
}

export async function upsertNormalizedOrders(orders: NormalizedOrder[]) {
  const response = await api.put<unknown, UpsertNormalizedOrdersBody>(
    UPSERT_NORMALIZED_ORDERS_URL,
    { orders }
  );

  return response?.data;
}

export async function replaceOrders(orders: Order[]) {
  const response = await api.put<unknown, ReplaceOrdersBody>(
    REPLACE_ORDERS_URL,
    { orders }
  );

  return response?.data;
}

export async function replaceNormalizedOrders(orders: NormalizedOrder[]) {
  const response = await api.put<unknown, ReplaceNormalizedOrdersBody>(
    REPLACE_NORMALIZED_ORDERS_URL,
    { orders }
  );

  return response?.data;
}

export async function replaceAllOrders(
  orders: Order[],
  normalizedOrders: NormalizedOrder[]
) {
  const response = await api.put<unknown, ReplaceAllOrdersBody>(
    REPLACE_ALL_ORDERS_URL,
    { orders, normalizedOrders }
  );

  return response?.data;
}
