import { divide } from '@sweep/utils';

export function getSeparatedPrice(price: number, quantity: number): number[] {
  if (quantity <= 0) {
    return [];
  }

  if (quantity === 1) {
    return [price];
  }

  return divide(price, new Array(quantity).fill(1));
}
