import { SharePurchaseOrderFileRecordWithEmailResponse } from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function requestSharePurchaseOrderFileRecordWithEmail(params: {
  purchaseOrderFileRecordId: string;
}): Promise<SharePurchaseOrderFileRecordWithEmailResponse | null> {
  try {
    const url = `/purchase-order-file-records/${params.purchaseOrderFileRecordId}/share/email`;

    const response = await api.post<
      APIResponse<SharePurchaseOrderFileRecordWithEmailResponse>
    >(url, {});

    if (response?.data?.success === true && response.data.data != null) {
      return response.data.data;
    }

    return null;
  } catch {
    return null;
  }
}
