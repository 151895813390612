import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { Order } from '@sweep/contract';
import { HeaderCellContext, SortIcon, Table } from '@sweep/sds';
import { TableFilterStore } from '../TableFilterStore';
import TableFilterDropdown from './TableFilterDropdown';

interface TableFilterHeadercellProps
  extends React.HTMLAttributes<HTMLDivElement> {
  store: TableFilterStore;
  orders: Order[];
  headerKey: string;
  context: HeaderCellContext;
  children?: React.ReactNode;
}

function TableFilterHeaderCell(
  {
    store,
    headerKey,
    orders,
    context,
    children,
    ...rest
  }: TableFilterHeadercellProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { key: sortKey, type: sortType } = store.sort;

  return (
    <Table.HeaderCell ref={ref} context={context} {...rest}>
      <div className="flex w-full justify-between">
        <div className="gap-4px flex items-center">
          {children}
          <SortIcon sort={sortKey === headerKey ? sortType : null} />
        </div>
        <TableFilterDropdown
          store={store}
          orders={orders}
          headerKey={headerKey}
        />
      </div>
    </Table.HeaderCell>
  );
}

export default observer(forwardRef(TableFilterHeaderCell));
