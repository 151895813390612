import { useCallback, useState } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { isDoseosangan } from 'src/services/doseosangan/isDoseosangan';

export function useDoseosangan() {
  const oms = useOMSStore();
  const setting = oms.user.setting?.doseosangan;
  const standard = setting?.standard ?? 'CJ';

  const [doseosangan, setDoseosangan] = useState<boolean | undefined>(
    undefined
  );

  const handleDoseosanganChange = useCallback(
    (value: boolean) => {
      setDoseosangan(value);
    },
    [setDoseosangan]
  );

  const filterDoseosangan = useCallback(
    (orders: NormalizedOrder[]) => {
      if (doseosangan === undefined || doseosangan === false) {
        return orders;
      }

      return orders.filter(
        (order) =>
          order.postCode != null && isDoseosangan(order.postCode, standard)
      );
    },
    [doseosangan, standard]
  );

  return {
    doseosangan,
    onDososanganChange: handleDoseosanganChange,
    filterDoseosangan,
  };
}
