import { observer } from 'mobx-react-lite';
import { Inquiry } from '@sweep/contract';
import { SwitchCase } from '@sweep/utils/react';
import InquiryConfirmReviewInput from './InquiryReplyConfirmInput';
import InquiryReplyPassInput from './InquiryReplyPassInput';
import InquiryReplyWriteInput from './InquiryReplyWriteInput';

interface InquiryReplyInputProps {
  inquiry: Inquiry;
  content?: string;
  onChange: (content: string) => void;
  onSubmit: () => void;
}

function InquiryReplyInput({
  inquiry,
  content,
  onChange,
  onSubmit,
}: InquiryReplyInputProps) {
  const replyType = inquiry.replyType;

  return (
    <SwitchCase
      value={replyType}
      caseBy={{
        WRITE: (
          <InquiryReplyWriteInput
            value={content ?? ''}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        ),
        PASS: <InquiryReplyPassInput />,
        CONFIRM: <InquiryConfirmReviewInput onSubmit={onSubmit} />,
      }}
    />
  );
}

export default observer(InquiryReplyInput);
