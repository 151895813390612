import {
  IconNaviCalculator,
  IconNaviMenu,
  IconNaviPaperWaybill,
  IconNaviRetailer,
  IconNaviSearch,
  IconNaviShoppingBag,
  IconNaviSupplier,
  IconNaviTempStorage,
  IconNaviUser,
} from '@sweep/asset/icons';
import { UserCompanyType } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';

interface TabGroup {
  title: string;
  tabs: {
    icon?: React.ReactNode;
    title: string;
    href: string;
    beta?: boolean;
  }[];
}

export const getTabGroups = ({
  companyType,
  isAdmin,
  cs,
}: {
  companyType: UserCompanyType | '';
  isAdmin: boolean;
  cs: boolean;
}): TabGroup[] => {
  const commonTabs: TabGroup[] = [
    {
      title: '발주',
      tabs: [
        {
          title: '주문 수집하기',
          href: '/order-dispatch',
          icon: <IconNaviShoppingBag />,
        },
        {
          title: '발주 진행하기',
          href: '/order-aggregation',
          icon: <IconNaviPaperWaybill />,
        },
      ],
    },
    {
      title: '주문',
      tabs: [
        {
          title: '주문 조회',
          href: '/order-dispatch-search',
          icon: <IconNaviSearch />,
        },
      ],
    },
    cs
      ? {
          title: 'CS',
          tabs: [
            {
              title: 'CS 관리',
              href: '/cs',
              icon: <IconNaviUser />,
              beta: true,
            },
          ],
        }
      : null,
    {
      title: '정산',
      tabs: [
        {
          title: '일일 정산 관리',
          href: '/settlement/daily-management',
          icon: <IconNaviCalculator />,
        },
      ],
    },
    {
      title: '상품',
      tabs: [
        {
          title: '상품 관리',
          href: '/product',
          icon: <IconNaviTempStorage />,
        },
        {
          title: '합배송 설정',
          href: '/product/combined-shipping',
          icon: <IconNaviMenu />,
        },
      ],
    },
    {
      title: '파트너사',
      tabs: [
        {
          title: '판매처 관리',
          href: '/setting/partner',
          icon: <IconNaviRetailer />,
        },
        companyType === 'retailer'
          ? {
              title: '공급사 관리',
              href: '/setting/supplier',
              icon: <IconNaviSupplier />,
            }
          : null,
      ].filter(isNotNil),
    },
    isAdmin
      ? {
          title: '관리자',
          tabs: [
            {
              title: '플러그인 설정',
              href: '/setting/plugin',
            },
            {
              title: 'QA 설정',
              href: '/setting/qa',
            },
            {
              title: '통합 엑셀 양식 관리',
              href: '/setting/integrated-excel-format',
            },
          ],
        }
      : null,
  ].filter(isNotNil);

  return commonTabs;
};
