import { overlay } from 'overlay-kit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconControlNext, IconHeaderLogotype } from '@sweep/asset/icons';
import { Modal } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { imageFullLogoAliExpress } from 'src/images';
import { preRegister } from 'src/network/user';
import { amplitude } from 'src/third-parties/amplitude';

interface SubmitSuccessModalProps {
  open: boolean;
  onClose: () => void;
}

function SubmitSuccessModal({ open, onClose }: SubmitSuccessModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className="w-4/5 max-w-[384px] p-[32px]"
    >
      <div className="flex flex-col items-center justify-between gap-[16px]">
        <div className="flex w-full flex-col items-center gap-[4px]">
          <p className="text-[18px] font-bold tracking-tight text-gray-700">
            무료체험 신청이 접수되었습니다.
          </p>
          <p className="text-[14px] font-medium tracking-tight text-gray-500">
            곧 담당 매니저가 연락드릴 예정입니다.
          </p>
        </div>
        <button
          className="w-full rounded-[8px] bg-red-500 px-[20px] py-[6px] text-[18px] font-bold text-white"
          onClick={onClose}
        >
          확인
        </button>
      </div>
    </Modal>
  );
}

function AliExpressRegistrationScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const navigateToLandingPage = () => {
    navigate(`/ali-express?${urlParams.toString()}`);
  };

  let utmInfo = '';
  for (const [key, value] of urlParams.entries()) {
    utmInfo += `\n  - ${key}: ${value}`;
  }

  const [companyName, setCompanyName] = useState<string>('');
  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = async () => {
    if (companyName === '' || phoneNumber === '') {
      return;
    }

    const response = await preRegister({
      from: '알리 무료체험 신청' + utmInfo,
      companyName,
      phoneNumber,
    });
    if (response.status === 200) {
      amplitude.track('[Ali] Submit', {
        companyName,
        phoneNumber,
      });
      setCompanyName('');
      setPhoneNumber('');
      openSubmitSuccessModal();
      return;
    }
    amplitude.track('[Ali] Submit Failed');
    window.alert('신청에 실패했습니다. 다시 시도해주세요.');
  };

  const onCompanyNameFocus = () => {
    amplitude.track('[Ali] Focus CompanyName Input');
  };

  const onPhoneNumberFocus = () => {
    amplitude.track('[Ali] Focus PhoneNumber Input');
  };

  return (
    <div className="relative flex min-h-screen w-full flex-col items-center justify-start bg-gradient-to-b from-red-100 to-white">
      <div
        className="flex w-full items-center justify-start p-[24px]"
        onClick={navigateToLandingPage}
      >
        <IconHeaderLogotype className="h-auto w-1/5 text-blue-500" />
      </div>
      <div className="mt-[40px] flex w-[90%] flex-col items-center gap-[20px] rounded-[32px] bg-white/80 py-[60px] shadow-[0_8px_30px_rgba(0,0,0,0.1)]">
        <div className="flex w-full flex-col items-center gap-[10px]">
          <div className="flex flex-col gap-[8px] text-center text-[16px] font-semibold leading-[48px] tracking-tight text-gray-700">
            <div className="flex items-center gap-[6px]">
              <img
                src={imageFullLogoAliExpress}
                alt="ali-express"
                className="h-[14px] translate-y-px"
              />
              <span>주문처리에 불편함이 있으신가요?</span>
            </div>
          </div>
          <div className="flex items-center text-[20px] font-semibold text-gray-700">
            <IconHeaderLogotype className="h-[21px] w-auto text-blue-500" />
            <span>이 전부 해결해드릴게요.</span>
          </div>
        </div>
        <IconControlNext className="size-[32px] rotate-90 text-gray-500" />
        <div className="flex w-full flex-col items-center gap-[24px]">
          <div className="flex w-full flex-col items-center gap-[12px]">
            <input
              type="text"
              placeholder="회사 이름을 입력해주세요."
              value={companyName}
              onChange={handleCompanyNameChange}
              onFocus={onCompanyNameFocus}
              className="w-4/5 rounded-[16px] border border-gray-300 px-[20px] py-[12px] text-[16px] font-medium text-gray-700 focus:outline-red-400"
            />
            <input
              type="tel"
              placeholder="연락처를 입력해주세요."
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              onFocus={onPhoneNumberFocus}
              className="w-4/5 rounded-[16px] border border-gray-300 px-[20px] py-[12px] text-[16px] font-medium text-gray-700 focus:outline-red-400"
            />
          </div>
          <div className="flex w-full flex-col items-center gap-[12px]">
            <button
              disabled={companyName === '' || phoneNumber === ''}
              className={button({
                disabled: companyName === '' || phoneNumber === '',
              })}
              onClick={handleSubmit}
            >
              무료체험 시작하기
            </button>
            <p className="w-4/5 text-[14px] text-gray-500">
              - 담당 매니저가 하루 내에 연락드릴 예정입니다.
              <br />- 스윕의{' '}
              <a
                className="underline"
                target="_blank"
                rel="noreferrer"
                href="https://sweepoms.notion.site/476a63cf5c3842569775438ae80be500"
              >
                서비스 이용약관
              </a>{' '}
              및{' '}
              <a
                className="underline"
                target="_blank"
                rel="noreferrer"
                href="https://sweepoms.notion.site/587bc67c6247485eab6b6972bef1a586"
              >
                개인정보처리방침
              </a>
              이 적용됩니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const button = cva('w-4/5 rounded-[16px] py-[12px] text-[20px] font-bold', {
  variants: {
    disabled: {
      true: 'bg-gray-200 text-gray-400',
      false: 'bg-red-500 text-white',
    },
  },
});

const openSubmitSuccessModal = () => {
  overlay.open(({ isOpen, close, unmount }) => (
    <SubmitSuccessModal
      open={isOpen}
      onClose={() => {
        close();
        unmount();
      }}
    />
  ));
};

export default AliExpressRegistrationScreen;
