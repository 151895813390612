import { action, observable, runInAction } from 'mobx';
import {
  CreatePurchaseOrderFileRecordDTO,
  PurchaseOrderFileRecord,
} from '@sweep/contract';
import { requestCreatePurchaseOrderFileRecord } from 'src/network/purchase-order-file-record/createPurchaseOrderFileRecord';
import { requestFindAllPurchaseOrderFileRecords } from 'src/network/purchase-order-file-record/findAllPurchaseOrderFileRecords';
import { OMSStore } from './OMSStore';

export class PurchaseOrderFileRecordStore {
  @observable
  accessor purchaseOrderFileRecords: PurchaseOrderFileRecord[] = [];

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await this.loadPurchaseOrderFileRecords();
  }

  @action.bound
  async loadPurchaseOrderFileRecords() {
    const purchaseOrderFileRecords =
      await requestFindAllPurchaseOrderFileRecords();

    runInAction(() => {
      this.purchaseOrderFileRecords = purchaseOrderFileRecords;
    });
  }

  @action.bound
  async createPurchaseOrderFileRecord(
    purchaseOrderFileRecord: CreatePurchaseOrderFileRecordDTO
  ) {
    const result = await requestCreatePurchaseOrderFileRecord(
      purchaseOrderFileRecord
    );

    if (result != null) {
      runInAction(() => {
        this.purchaseOrderFileRecords = [
          ...this.purchaseOrderFileRecords,
          result,
        ];
      });
    }
  }

  @action.bound
  async bulkCreatePurchaseOrderFileRecords(
    purchaseOrderFileRecords: CreatePurchaseOrderFileRecordDTO[],
    onError?: (error: unknown) => void
  ): Promise<PurchaseOrderFileRecord[]> {
    const results = await Promise.allSettled(
      purchaseOrderFileRecords.map(async (record) => {
        const result = await requestCreatePurchaseOrderFileRecord(record);
        return result;
      })
    );

    const userId = this.oms.user.userId.value!;

    const updatedPurchaseOrderFileRecords: PurchaseOrderFileRecord[] =
      results.map((result, index) => {
        if (result.status === 'fulfilled' && result.value != null) {
          return result.value;
        }

        onError?.('Failed to create purchase order file record');

        const prevPurchaseOrderFileRecord = purchaseOrderFileRecords[index];

        return {
          ...prevPurchaseOrderFileRecord,
          createdAt: '',
          _id: '',
          userId,
        };
      });

    runInAction(() => {
      this.purchaseOrderFileRecords.push(...updatedPurchaseOrderFileRecords);
    });

    return updatedPurchaseOrderFileRecords;
  }
}
