import { ReactNode, useEffect, useMemo } from 'react';
import { cva } from '@sweep/tailwind';
import {
  SelectContextOption,
  SelectOptionValue,
  useSelectContext,
} from './context/SelectContext';

interface SelectOptionProps {
  value: SelectOptionValue;
  className?: string;
  children?: ReactNode;
}

function SelectOption({ value, className, children }: SelectOptionProps) {
  const {
    value: selectedValue,
    onChange,
    onOptionMount,
    onOptionUnmount,
    options,
  } = useSelectContext();

  // TODO(@이지원): string[] 타입인 경우도 처리해주기
  const option: SelectContextOption = useMemo(
    () => ({
      value,
      label: typeof children === 'string' ? children : value.toString(),
    }),
    [value, children]
  );

  useEffect(() => {
    onOptionMount(option);

    return () => {
      onOptionUnmount(option);
    };
  }, [onOptionMount, onOptionUnmount, option]);

  const isNotLastOption = options.at(-1)?.value !== value;

  const handleClick = () => {
    onChange(value);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={optionClass({
          selected: value === selectedValue,
          className,
        })}
      >
        {children}
      </div>
      {isNotLastOption && <hr />}
    </>
  );
}

const optionClass = cva(
  [
    'h-44px px-16px flex items-center',
    'text-medium-s text-gray-700',
    'cursor-pointer',
  ],
  {
    variants: {
      selected: {
        true: 'bg-gray-100',
        false: 'bg-white hover:bg-gray-100',
      },
    },
  }
);

export default SelectOption;
