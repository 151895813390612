import { overlay } from 'overlay-kit';
import { CombinedShippingMultiCreateResult } from '../../interface';
import CombinedShippingMultiCreateFormModal from './CombinedShippingMultiCreateFormModal';

export function openCombinedShippingMultiCreateFormModal() {
  return new Promise<CombinedShippingMultiCreateResult | null>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <CombinedShippingMultiCreateFormModal
        open={isOpen}
        onSubmit={(result) => {
          close();
          unmount();
          resolve(result);
        }}
      />
    ))
  );
}
