import { overlay } from 'overlay-kit';
import DispatchSelectModal from '../DispatchSelectModal/DispatchSelectModal';

export function openDispatchSelectModal(
  selectedShoppingMalls: string[],
  onSelect: (shoppingMalls: string[]) => void
) {
  overlay.open(({ isOpen, close, unmount }) => (
    <DispatchSelectModal
      open={isOpen}
      onClose={() => {
        close();
        unmount();
      }}
      onSubmit={onSelect}
      selectedShoppingMalls={selectedShoppingMalls}
    />
  ));
}
