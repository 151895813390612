import { isNil } from 'es-toolkit';
import { isEmpty } from 'es-toolkit/compat';
import { ORDER_SHIPPING_STATUS } from '@sweep/contract';
import { isValidUniqueCodeSub } from '@sweep/domain/services/order';
import {
  findMatchingOrderUsingBasicInfo,
  SHIPPING_ORDER_PROCESS_STATUS_CODE,
} from '@sweep/domain/services/order-shipping-process';
import { createDebug } from 'src/third-parties/createDebug';
import { ProcesserFunction } from './processor.type';

const debug = createDebug(
  'services:process-shipping-order-files:processShippingInfoUpdate'
);

export const processShippingInfoUpdate: ProcesserFunction = (item, context) => {
  debug('start', item.index);
  if (!item.isProcessable) {
    return item;
  }

  try {
    const { shippingOrderMap } = context;
    debug('shippingOrderMap.keys', Object.keys(shippingOrderMap));

    const { key, value: inputShippingOrder } = item;
    debug('key', key);
    debug('inputShippingOrder', inputShippingOrder);
    const useFindByKey =
      !isNil(key) && !isEmpty(key) && isValidUniqueCodeSub(key);

    const matchingShippingOrder = useFindByKey
      ? shippingOrderMap[key]
      : findMatchingOrderUsingBasicInfo(inputShippingOrder, shippingOrderMap);

    debug('matchingShippingOrder', matchingShippingOrder);

    if (isNil(matchingShippingOrder)) {
      const nextStatus = useFindByKey
        ? SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_ORIGINAL_ORDER_NOT_FOUND
        : SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_ORIGINAL_ORDER_NOT_FOUND_BY_BASIC_INFO;
      const prevHistories = item.histories;
      const nextHistories = [...prevHistories, nextStatus];

      return {
        ...item,
        isProcessable: false,
        status: nextStatus,
        histories: nextHistories,
      };
    }

    const matchingShippingOrderKey =
      matchingShippingOrder.uniqueCodeAfterCustomization;

    // check shipping status
    const isInvalidStatus =
      matchingShippingOrder.shippingStatus !== ORDER_SHIPPING_STATUS.processing;
    if (isInvalidStatus) {
      const nextStatus =
        SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_STATUS;
      const prevHistories = item.histories;
      const nextHistories = [...prevHistories, nextStatus];

      return {
        ...item,
        isProcessable: false,
        status: nextStatus,
        histories: nextHistories,
      };
    }

    // update shipping order
    const nextStatus = useFindByKey
      ? SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_KEY
      : SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_BASIC_INFO;
    const prevHistories = item.histories;
    const nextHistories = [...prevHistories, nextStatus];

    const updatedShippingOrder = {
      ...matchingShippingOrder,
      shippingNumber: item.value.shippingNumber,
      shippingCompany: item.value.shippingCompany,
      shippingStatus: ORDER_SHIPPING_STATUS.reviewing,
    };

    return {
      ...item,
      isProcessable: true,
      key: matchingShippingOrderKey,
      value: updatedShippingOrder,
      status: nextStatus,
      histories: nextHistories,
    };
  } catch (error) {
    const nextStatus = SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_UNKNOWN_ERROR;
    const prevHistories = item.histories;
    const nextHistories = [...prevHistories, nextStatus];

    return {
      ...item,
      isProcessable: false,
      status: nextStatus,
      histories: nextHistories,
    };
  }
};
