import { action, computed, observable, runInAction } from 'mobx';
import {
  deleteProduct,
  getProducts,
  registerProduct,
  updateProduct,
  updateProductMany,
} from 'network/product';
import {
  CreateProductDTO,
  Product,
  UpdateProductDTO,
  UpdateProductManyDTO,
} from '@sweep/contract';
import { isNotEmptyArray } from '@sweep/utils';
import { isEmptyString } from 'src/utils/string';
import { OMSStore } from './OMSStore';

export class ProductStore {
  @observable
  accessor products: Product[] = [];

  @computed
  get productNames() {
    return this.products.map((product) => product.productName);
  }

  @computed
  get productIdByName() {
    return new Map<string, string>(
      this.products.map((product) => [product.productName, product._id])
    );
  }

  getProduct = (id: string) => {
    return this.products.find((product) => product._id === id);
  };

  getProductByName = (name: string) => {
    return this.products.find((product) => product.productName === name);
  };

  getSupplierId = (
    productId: string,
    context?: {
      optionName?: string | null;
      partnerId?: string | null;
      shoppingMallId?: string | null;
    }
  ) => {
    const { optionName, partnerId, shoppingMallId } = context ?? {};

    const product = this.getProduct(productId);
    if (product == null) {
      return null;
    }

    if (product.useSupplierByOption === true && optionName != null) {
      const option = product.units?.find((unit) => unit.unit === optionName);
      if (option == null) {
        return null;
      }

      return option.supplierId;
    }

    if (product.useSupplierBySalesChannel === true) {
      if (partnerId != null) {
        const salesChannelSupplierId = product.salesChannelSupplierIds?.find(
          (salesChannelSupplierId) =>
            'partnerId' in salesChannelSupplierId &&
            salesChannelSupplierId.partnerId === partnerId
        );

        if (salesChannelSupplierId != null) {
          return salesChannelSupplierId.supplierId;
        }
      }

      if (shoppingMallId != null) {
        const salesChannelSupplierId = product.salesChannelSupplierIds?.find(
          (salesChannelSupplierId) =>
            'shoppingMallId' in salesChannelSupplierId &&
            salesChannelSupplierId.shoppingMallId === shoppingMallId
        );

        if (salesChannelSupplierId != null) {
          return salesChannelSupplierId.supplierId;
        }
      }
    }

    // TODO(@이지원): supplierId가 어디서 ""으로 들어오는지 확인하고 삭제
    return isEmptyString(product.supplierId) ? null : product.supplierId;
  };

  getAmount = (
    productId: string,
    optionName?: string | null
  ): number | null => {
    const product = this.getProduct(productId);
    if (product == null) {
      return null;
    }

    if (optionName == null) {
      return product.amount ?? null;
    }

    const option = product.units?.find((unit) => unit.unit === optionName);
    if (option == null) {
      return null;
    }

    return option.amount ?? null;
  };

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await this.loadProducts();
  }

  @action.bound
  loadProducts = async () => {
    const response = await getProducts();
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      const products = response.data ?? [];
      this.products = products.sort((p1, p2) =>
        p1.productName.localeCompare(p2.productName)
      );
    });
  };

  @action.bound
  async register(product: CreateProductDTO) {
    const response = await registerProduct(product);
    if (response.success === false) {
      return null;
    }
    const registeredProduct = response.data;

    runInAction(() => {
      this.products = [registeredProduct, ...this.products];
    });

    return registeredProduct;
  }

  @action.bound
  async update(
    productId: string,
    product: UpdateProductDTO
  ): Promise<Product | null> {
    const index = this.products.findIndex(
      (product) => product._id === productId
    );
    if (index === -1) {
      return null;
    }

    const prevProduct = this.products[index];
    this.products[index] = {
      ...prevProduct,
      ...product,
    };

    const response = await updateProduct(productId, product);
    if (response?.success !== true) {
      this.products[index] = prevProduct;
      return null;
    }

    const {
      combinedShippingById,
      deletedCompositionMatchingIds,
      deletedOptionCodeMatchingIds,
    } = response.data ?? {};
    if (combinedShippingById != null) {
      Object.entries(combinedShippingById).forEach(([id, combinedShipping]) => {
        this.oms.combinedShipping._update(id, combinedShipping);
      });
    }

    if (deletedCompositionMatchingIds != null) {
      this.oms.cm._deleteCompositionMatchings(deletedCompositionMatchingIds);
    }

    if (deletedOptionCodeMatchingIds != null) {
      this.oms.cm._deleteOptionCodeMatchings(deletedOptionCodeMatchingIds);
    }

    return this.products[index];
  }

  @action.bound
  async updateMany(products: UpdateProductManyDTO[]) {
    const prevProducts = this.products;
    this.products = this.products.map((product) => {
      const updatedProduct = products.find((p) => p._id === product._id);
      if (updatedProduct == null) {
        return product;
      }

      return {
        ...product,
        ...updatedProduct,
      };
    });

    const response = await updateProductMany(products);
    if (response?.success !== true) {
      this.products = prevProducts;

      return false;
    }

    return true;
  }

  @action.bound
  delete = async (productId: string) => {
    const prevProducts = this.products;
    const index = prevProducts.findIndex(
      (product) => product._id === productId
    );
    if (index === -1) {
      return false;
    }

    this.products.splice(index, 1);

    const response = await deleteProduct(productId);
    if (response?.success !== true) {
      this.products = prevProducts;
      return false;
    }

    const {
      combinedShippingById,
      deletedCompositionMatchingIds,
      deletedOptionCodeMatchingIds,
    } = response.data ?? {};
    if (combinedShippingById != null) {
      Object.entries(combinedShippingById).forEach(([id, combinedShipping]) => {
        this.oms.combinedShipping._update(id, combinedShipping);
      });
    }

    if (isNotEmptyArray(deletedCompositionMatchingIds)) {
      this.oms.cm._deleteCompositionMatchings(deletedCompositionMatchingIds);
    }

    if (isNotEmptyArray(deletedOptionCodeMatchingIds)) {
      this.oms.cm._deleteOptionCodeMatchings(deletedOptionCodeMatchingIds);
    }

    return true;
  };

  @action.bound
  deleteMany = async (productIds: string[]) => {
    // NOTE(@이지원): Promise.all로 하면 합배송 업데이트순서가 지켜지지 않아 빈 상품이 나올 수 있음
    for (const productId of productIds) {
      const isSuccess = await this.delete(productId);

      if (!isSuccess) {
        await this.loadProducts();
        return false;
      }
    }

    return true;
  };
}
