import {
  NormalizedOrder,
  Order,
  PurchaseOrderFileRecord,
} from '@sweep/contract';
import { formatDate, getUnusedFilename, isNotNil } from '@sweep/utils';
import useFileHandling from 'src/hooks/useFileHandling';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OrderXX } from 'src/models/OrderXX';
import { openCMRequriedDialog } from 'src/screens/order-process/components/dialogs/openCMRequiredDialog';
import { openConfirmMoveToShippingDialog } from 'src/screens/order-process/components/dialogs/openConfirmMoveToShippingDialog';
import { filterCMOrders } from 'src/screens/order-process/services/filterCMOrders';
import { getRemainedOrdersByUniqueCodeAfterCustomization } from 'src/screens/order-process/services/getRemainedOrdersByUniqueCodeAfterCustomization';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import { toast } from 'src/third-parties/toast';
import { createPurchaseOrderFileForRetailer } from '../services/create-purchase-order-file/createPurchaseOrderFileForRetailer';
import { createPurchaseOrderZipFile } from '../services/create-purchase-order-file/createPurchaseOrderZipFile';
import usePrevDivideOrders from './usePrevDivideOrders';

const useCreatePurchaseOrderForRetailer = () => {
  const oms = useOMSStore();

  const { processNormalizeOrders } = useFileHandling();

  const { preDivideOrders } = usePrevDivideOrders();

  const createRetailerPurchaseOrder = async (
    orders: NormalizedOrder[]
  ): Promise<{
    dividedOrders: NormalizedOrder[];
    purchaseOrderFileRecords: PurchaseOrderFileRecord[];
    filename: string;
  } | null> => {
    const isConfirmed = await openConfirmMoveToShippingDialog(orders.length);
    if (!isConfirmed) {
      return null;
    }

    const remainedOrders = getRemainedOrdersByUniqueCodeAfterCustomization(
      oms.order.normalizedOrders,
      orders.map((order) => order.uniqueCodeAfterCustomization)
    );

    const { unmatchedOrders, matchedOrders } = filterCMOrders(orders);
    if (unmatchedOrders.length > 0) {
      const isMatchingConfirmed = await openCMRequriedDialog();
      if (!isMatchingConfirmed) {
        return null;
      }
    }

    const unmatchedUniqueCodes = unmatchedOrders.map(
      (order) => order.uniqueCode
    );
    const unmatchedUniqueCodeSet = new Set(unmatchedUniqueCodes);
    const unamtchedMergedOrders = oms.order.mergedOrders.filter((order) =>
      unmatchedUniqueCodeSet.has(order.uniqueCode)
    );
    oms.loading.start();
    const unmatchedNormalizedOrders = await normalizeOrdersBeforePreDivide(
      unamtchedMergedOrders
    );
    oms.loading.end();

    if (unmatchedNormalizedOrders == null) {
      return null;
    }

    const filename = formatDate(new Date(), 'yy.MM.dd 발주서 (HHmm)');
    const usedFilenames = oms.order.normalizedOrders
      .map((order) => order.purchaseOrderFile)
      .filter(isNotNil);
    const unusedFilename = getUnusedFilename(filename, usedFilenames);

    const shippingOrders = [
      ...unmatchedNormalizedOrders,
      ...matchedOrders,
    ].map<NormalizedOrder>((order) => ({
      ...order,
      purchaseOrderFile: unusedFilename,
      process: 'shipping',
      shippingStatus: 'processing',
    }));

    const dividedOrders = await oms.loading.batch(() =>
      oms.order.divide(shippingOrders, remainedOrders, preDivideOrders)
    );

    const suppliers = oms.supplier.suppliers;

    const { records, hasUnseparatedOrders } =
      createPurchaseOrderFileForRetailer({
        orders: dividedOrders,
        oms,
        suppliers,
        groupName: unusedFilename,
      });

    const purchaseOrderFileRecords =
      await oms.purchaseOrderFileRecord.bulkCreatePurchaseOrderFileRecords(
        records,
        (error) => {
          ErrorReporter.captureMessage(error as string);
        }
      );

    if (purchaseOrderFileRecords == null) {
      return null;
    }

    const excelFileRecords = purchaseOrderFileRecords.map(
      (record) => record.excelFileRecord
    );

    await createPurchaseOrderZipFile({
      zipFilename: unusedFilename,
      purchaseOrderFileRecords: excelFileRecords,
    });

    if (hasUnseparatedOrders === true) {
      toast.error('분류되지 않은 주문이 있습니다. 해당 상품들을 분류해주세요.');
    }

    return {
      dividedOrders,
      purchaseOrderFileRecords,
      filename: unusedFilename,
    };
  };

  const normalizeOrdersBeforePreDivide = async (orders: Order[]) => {
    if (orders.length === 0) {
      return [];
    }

    const newOrders = (await processNormalizeOrders(orders as OrderXX[], {
      stage: 'pre-divide',
      initialProcess: 'shipping',
      initialShippingStatus: 'processing',
    })) as NormalizedOrder[];

    if (newOrders.length === 0) {
      return null;
    }

    return newOrders;
  };

  return {
    createRetailerPurchaseOrder,
  };
};

export default useCreatePurchaseOrderForRetailer;
