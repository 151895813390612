import { groupBy, keyBy } from 'es-toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  CreatePurchaseOrderFileRecordDTO,
  NormalizedOrder,
  Supplier,
} from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { createPurchaseOrderFileRecordForUnseparatedOrders } from './createPurchaseOrderFileRecordForUnseparatedOrders';
import { createPurchaseOrderFileRecordFromSupplier } from './createPurchaseOrderFileRecordFromSupplier';

export function createPurchaseOrderFileForRetailer(params: {
  orders: NormalizedOrder[];
  oms: OMSStore;
  suppliers: Supplier[];
  groupName: string;
}): {
  records: CreatePurchaseOrderFileRecordDTO[];
  hasUnseparatedOrders: boolean;
} {
  const { orders, oms, suppliers, groupName } = params;
  const groupId = uuidv4();

  const orderGroupBySupplierId = groupBy(
    orders,
    (order) => order.supplierId ?? 'UNSEPARATED_ORDERS'
  );

  const records: CreatePurchaseOrderFileRecordDTO[] = [];

  const supplierKeyBySupplierId = keyBy(suppliers, (supplier) => supplier._id);

  const today = new Date();

  Object.entries(orderGroupBySupplierId).map(
    ([supplierKey, supplierOrders]) => {
      const supplier = supplierKeyBySupplierId[supplierKey];

      const isSupplierNotFound = supplier == null;
      if (isSupplierNotFound) {
        return;
      }

      const isEmptyOrders = supplierOrders.length === 0;
      if (isEmptyOrders) {
        return;
      }

      // TODO(@hungjoon): 템플릿 지원해줘야 함
      const record = createPurchaseOrderFileRecordFromSupplier({
        orders: supplierOrders,
        oms,
        supplier,
        today,
        groupId,
        groupName,
      });

      records.push(record);
    }
  );
  const unseparatedOrders = orders.filter((order) => order.supplierId == null);
  const unseparatedOrdersRecord =
    unseparatedOrders.length > 0
      ? createPurchaseOrderFileRecordForUnseparatedOrders({
          orders: unseparatedOrders,
          oms,
          today,
          groupId,
          groupName,
        })
      : null;

  if (unseparatedOrdersRecord != null) {
    records.push(unseparatedOrdersRecord);
  }

  return {
    records,
    hasUnseparatedOrders: unseparatedOrdersRecord != null,
  };
}
