import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SwitchCase } from '@sweep/utils/react';
import useFileHandling from 'src/hooks/useFileHandling';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OrderXX } from 'src/models/OrderXX';
import { toast } from 'src/third-parties/toast';
import OrderProcessRadioGroup from './components/OrderProcessRadioGroup/OrderProcessRadioGroup';
import OrderShippingSectionPending from './sections/OrderShippingSectionPending';
import OrderShippingSectionProcessing from './sections/OrderShippingSectionProcessing';
import OrderShippingSectionReviewing from './sections/OrderShippingSectionReviewing';
import OrderShippingSectionShipped from './sections/OrderShippingSectionShipped';
import OrderTotalSection from './sections/OrderTotalSection';

export type OrderProcessType =
  | 'total'
  | 'pending'
  | 'processing'
  | 'reviewing'
  | 'shipped';

function OrderProcessScreen() {
  const oms = useOMSStore();

  const [process, setProcess] = useState<OrderProcessType>('pending');
  const { normalizeOrders } = useFileHandling();

  useEffect(() => {
    const draftFiles = oms.order.draftFiles;
    const { filenames, orders } = draftFiles;
    if (orders.length === 0) {
      return;
    }
    normalizeOrders(orders as OrderXX[]).then(() => {
      toast.success('주문 파일이 업로드 되었습니다.');
      oms.loading.batch(() => oms.order.draft.process(filenames));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oms.loading, oms.order.draft, oms.order.draftFiles]);

  if (oms.order.component) {
    return oms.order.component;
  }

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <h1 className="text-extrabold-l font-bold text-gray-700">
        발주 진행하기
      </h1>
      <div className="gap-20px flex flex-col">
        <OrderProcessRadioGroup value={process} onChange={setProcess} />
        <SwitchCase
          value={process}
          caseBy={{
            total: <OrderTotalSection />,
            pending: <OrderShippingSectionPending />,
            processing: <OrderShippingSectionProcessing />,
            reviewing: <OrderShippingSectionReviewing />,
            shipped: <OrderShippingSectionShipped />,
          }}
        />
      </div>
    </div>
  );
}

export default observer(OrderProcessScreen);
