export function migrateExcelHeaderKeys(excelHeaderKeys: string[]): string[] {
  if (excelHeaderKeys.length === 0) {
    return [];
  }

  const originalIndex = excelHeaderKeys.findIndex(
    (key) => key === 'uniqueCode'
  );

  const notFound = originalIndex === -1;

  if (notFound) {
    return [...excelHeaderKeys, 'uniqueCodeAfterCustomization'];
  }

  const updatedHeaderKeys = excelHeaderKeys.map((key, index) => {
    if (index === originalIndex) {
      return 'uniqueCodeAfterCustomization';
    }
    return key;
  });

  return updatedHeaderKeys;
}
