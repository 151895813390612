import { cva } from '@sweep/tailwind';

interface SectionProps {
  position: 'left' | 'right';
  className?: string;
  children: React.ReactNode;
}

function Section({ position, className, children }: SectionProps) {
  return <div className={section({ position, className })}>{children}</div>;
}

const section = cva(
  'rounded-8px px-24px py-20px flex h-full flex-col border border-gray-300 bg-gray-100',
  {
    variants: {
      position: {
        left: 'w-[55%]',
        right: 'w-[45%]',
      },
    },
  }
);

export default Section;
