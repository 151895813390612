import { sortBy } from 'es-toolkit';
import { action, computed, observable, runInAction } from 'mobx';
import { ReactElement } from 'react';
import { OMSStore } from 'stores/OMSStore';
import { DispatchedOrder, NormalizedOrder, Order } from '@sweep/contract';
import { isNotNil, uniq } from '@sweep/utils';
import { replaceAllOrders } from 'src/network/order/order';
import { DispatchOrderStore } from './DispatchOrderStore';
import { DivideOrderStore } from './DivideOrderStore';
import { DraftStore } from './DraftStore';
import { FileOrderStore } from './FileOrderStore';
import { MergeOrderStore } from './MergeOrderStore';
import { NormalizeOrderStore } from './NormalizeOrderStore';

export type Control = ({ close }: { close: () => void }) => ReactElement;

export class OrderStore {
  dispatch: DispatchOrderStore;
  draft: DraftStore;
  file: FileOrderStore;
  _merge: MergeOrderStore;
  _normalize: NormalizeOrderStore;
  _divide: DivideOrderStore;

  @observable
  accessor component: ReactElement | null = null;

  @computed
  get uploadedFileNames() {
    const sortedByRegisteredAtOrders = sortBy(
      this._normalize.normalizedOrders,
      [(order) => (order.registeredAt ?? 0) * -1]
    );
    const uploadedFileNames = sortedByRegisteredAtOrders
      .map((order) => order.originFile)
      .filter(isNotNil);

    return uniq(uploadedFileNames);
  }

  @computed
  get dispatchedOrders() {
    return this.dispatch.dispatchedOrders;
  }

  @computed
  get mergedOrders() {
    return this._merge.mergedOrders;
  }

  @computed
  get normalizationQueueOrders(): Order[] {
    const draftFilenames = this.oms.order.draft.filenames;
    if (draftFilenames.length === 0) {
      return [];
    }

    const normalizedOrders = this._normalize.normalizedOrders;
    const normalizedOrderFiles = normalizedOrders
      .map((order) => order.originFile)
      .filter(isNotNil);
    const normalizedOrderFileSet = new Set(normalizedOrderFiles);

    const mergedOrders = this._merge.mergedOrders;

    return mergedOrders.filter(
      (order) =>
        order.originFile != null &&
        !normalizedOrderFileSet.has(order.originFile)
    );
  }

  @computed
  get normalizedOrders() {
    return this._normalize.normalizedOrders;
  }

  @computed
  get draftFiles(): { filenames: string[]; orders: Order[] } {
    const filenames = this.oms.order.draft.filenames;
    const mergedOrders = this._merge.mergedOrders;

    if (filenames.length === 0 || mergedOrders.length === 0) {
      return { filenames: [], orders: [] };
    }

    const filenameSet = new Set(filenames);
    const orders = mergedOrders.filter(
      (order) => order.originFile != null && filenameSet.has(order.originFile)
    );

    return { filenames, orders };
  }

  constructor(private oms: OMSStore) {
    this.dispatch = new DispatchOrderStore(oms);
    this.file = new FileOrderStore(oms);
    this.draft = new DraftStore(oms);
    this._merge = new MergeOrderStore(oms);
    this._normalize = new NormalizeOrderStore(oms);
    this._divide = new DivideOrderStore(oms);
  }

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  @action.bound
  setMergedOrders(orders: Order[]) {
    this._merge.setMergedOrders(orders);
  }

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  @action.bound
  setNormalizedOrders(orders: NormalizedOrder[]) {
    this._normalize.setNormalizedOrders(orders);
  }

  @action.bound
  render(control: Control) {
    const stack = this.oms.loading.forceStop();

    this.component = control({
      close: () => {
        runInAction(() => {
          this.component = null;
        });
        this.oms.loading.restoreForceStop(stack);
      },
    });
  }

  @action.bound
  async init() {
    await Promise.all([
      this._merge.init(),
      this._normalize.init(),
      this.draft.init(),
    ]);
  }

  @action.bound
  async mergeDispatchedOrders(orders: DispatchedOrder[]) {
    return await this._merge.mergeDispatchedOrders(orders);
  }

  @action.bound
  async merge(files: File[]): Promise<Order[] | null> {
    return await this._merge.mergeOrders(files);
  }

  @action.bound
  async normalize(orders: Order[]): Promise<Order[]> {
    const normalizedOrders = await this._normalize.normalizeOrders(orders);
    return normalizedOrders;
  }

  @action.bound
  async divide(
    orders: NormalizedOrder[],
    remainingOrders: NormalizedOrder[],
    preDivideOrders: (orders: NormalizedOrder[]) => Promise<NormalizedOrder[]>
  ) {
    return await this._divide.divideOrders(
      orders,
      remainingOrders,
      preDivideOrders
    );
  }

  @action.bound
  async upsertMergedOrders(orders: Order[]) {
    await this._merge.upsertOrders(orders);
  }

  @action.bound
  async upsertNormalizedOrders(orders: NormalizedOrder[]) {
    await this._normalize.upsertOrders(orders);
  }

  @action.bound
  removeOrderByFilename(filename: string) {
    this._merge.removeOrderByFilename(filename);
    this._normalize.removeOrderByFilename(filename);
  }

  @action.bound
  async removeOrders(orders: NormalizedOrder[]) {
    const uniqueCodeSet = new Set(
      orders.map((order) => order.uniqueCodeAfterCustomization).filter(isNotNil)
    );

    const filteredNormalizedOrders = this.normalizedOrders.filter(
      (order) => !uniqueCodeSet.has(order.uniqueCodeAfterCustomization)
    );
    const removedNormalizedOrders = this.normalizedOrders.filter((order) =>
      uniqueCodeSet.has(order.uniqueCodeAfterCustomization)
    );
    const removedUniqueCodes = removedNormalizedOrders
      .map((order) => order.uniqueCode)
      .filter((uniqueCode) =>
        filteredNormalizedOrders.every(
          (order) => order.uniqueCode !== uniqueCode
        )
      );
    const removedUniqueCodeSet = new Set(removedUniqueCodes);

    const filteredOrders = this.mergedOrders.filter(
      (order) => !removedUniqueCodeSet.has(order.uniqueCode)
    );

    this.setMergedOrders(filteredOrders);
    this.setNormalizedOrders(filteredNormalizedOrders);

    await replaceAllOrders(filteredOrders, filteredNormalizedOrders);
  }
}
