import { observer } from 'mobx-react-lite';
import { BrandLogoByName, Select } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface ShoppingMallSelectProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const TOTAL = '전체';

function ShoppingMallSelect({
  value: givenValue,
  onChange: givenOnChange,
}: ShoppingMallSelectProps) {
  const oms = useOMSStore();

  const value = givenValue ?? TOTAL;

  const handleValue = (value: string) => {
    if (value === TOTAL) {
      givenOnChange(null);
      return;
    }

    givenOnChange(value);
  };

  return (
    <Select value={value} onChange={handleValue}>
      <Select.Option value={TOTAL}>전체</Select.Option>
      {oms.shoppingMall.shoppingMallNames.map((shoppingMall) => (
        <Select.Option key={shoppingMall} value={shoppingMall}>
          <BrandLogoByName name={shoppingMall} size="sm" className="mr-6px" />
          {shoppingMall}
        </Select.Option>
      ))}
    </Select>
  );
}

export default observer(ShoppingMallSelect);
