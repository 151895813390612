export const INQUIRY_STATUS = {
  ANSWERED: '답변완료',
  UNANSWERED: '미답변',
} as const;

export type InquiryStatus = keyof typeof INQUIRY_STATUS;

export const INQUIRY_CATEGORY = {
  CALL: '고객센터문의',
  ORDER: '주문문의',
  PRODUCT: '상품문의',
  CUSTOMER: '고객문의',
} as const;

export type InquiryCategory = keyof typeof INQUIRY_CATEGORY;

export type InquiryReplyType = 'WRITE' | 'PASS' | 'CONFIRM';

export interface BaseInquiry {
  shoppingMall: string;
  inquiryUniqueCode: string;

  inquiryId: string;
  inquiryCategory: InquiryCategory;
  inquiryType?: string;

  inquiryStatus: InquiryStatus;

  title?: string;
  content?: string;

  buyerEmail?: string;
  buyerName?: string;
  buyerContactNumber?: string;

  inquirerId?: string;
  inquirerName?: string;

  orderNumbers?: string[];
  productIds?: string[];
  productNames?: string[];

  inquiryDate: number;

  replyType: InquiryReplyType;
  replies?: InquiryReply[];
}

export interface InquiryReply {
  inquiryId: string;
  replyId: string;
  content: string;
  replyDate: number;
}

export type CoupangInquiryCategory = 'CALL' | 'CUSTOMER';
export interface CoupangInquiry extends BaseInquiry {
  inquiryCategory: CoupangInquiryCategory;
}

export type NaverInquiry = BaseInquiry;

export type Inquiry = CoupangInquiry | NaverInquiry;
