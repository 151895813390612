import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { IconControlDownload } from '@sweep/asset/icons';
import { NormalizedOrder } from '@sweep/contract';
import {
  Button,
  SearchInput,
  SelectableTable,
  TableCount,
  TableSelectControl,
} from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { createOrderExcel } from 'src/services/file/excel/create';
import { toast } from 'src/third-parties/toast';
import { useTableFilterColumns } from '../hooks/useTableFilterColumns';
import { splitAndDownloadPurchaseOrder } from '../services/split-and-download-purchase-order/splitAndDownloadPurchaseOrder';
import { OrderShippingShippedStore } from '../stores/OrderShippingShippedStore';

function OrderShippingSectionShipped() {
  const oms = useOMSStore();
  const [store] = useState(() => new OrderShippingShippedStore(oms));
  const shippingCompleteOrders = store.shippingStatusShippedOrders;

  const [search, setSearch, filterSearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });
  const searchFilteredOrders = useMemo(
    () => filterSearch(shippingCompleteOrders),
    [filterSearch, shippingCompleteOrders]
  );

  const isUploadHandWrittenFile =
    oms.user.setting?.uploadHandWrittenFile === true;
  const { columns, filteredOrders } = useTableFilterColumns({
    orders: searchFilteredOrders,
    useUniqueCodeSub: !isUploadHandWrittenFile, // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
  });

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);
  const currentOrders =
    selectedOrders.length > 0 ? selectedOrders : filteredOrders;

  const download = () => {
    const isUploadHandWrittenFile =
      oms.user.setting?.uploadHandWrittenFile === true;
    // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
    const tempModifiedOrders = isUploadHandWrittenFile
      ? currentOrders
      : currentOrders.map((order) => ({
          ...order,
          uniqueCode: order.uniqueCodeAfterCustomization,
        }));

    const isOrderEmpty = tempModifiedOrders.length === 0;
    if (isOrderEmpty) {
      toast.error('선택된 주문이 없습니다.');
      return;
    }

    createOrderExcel(
      oms,
      tempModifiedOrders, // SWP-582 뉴스고 보여주기
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );
  };

  const splitPurchaseOrder = async () => {
    const isOrderEmpty = currentOrders.length === 0;
    if (isOrderEmpty) {
      toast.error('선택된 주문이 없습니다.');
      return;
    }

    oms.loading.batch(async () => {
      const [_, failedCount] = await splitAndDownloadPurchaseOrder({
        oms,
        shippingOrders: currentOrders,
      });

      if (failedCount > 0) {
        toast.error('주문서 파일 생성에 실패했습니다.');
        return;
      }

      toast.success('운송장이 입력된 주문서가 생성되었습니다.');
    });
  };

  return (
    <div className="shadow-line p-20px rounded-8px gap-16px flex flex-col bg-white">
      <div className="flex justify-between">
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="검색어를 입력하세요."
          className="max-w-400px"
        />
      </div>
      <hr />
      <div>
        <TableCount count={filteredOrders.length} />
        <div className="h-40px flex items-end justify-between">
          <TableSelectControl
            count={selectedOrders.length}
            onClear={() => setSelectedOrders([])}
          />
          <div className="gap-10px flex">
            <Button
              color="gray"
              rightAccessory={<IconControlDownload />}
              onClick={download}
            >
              엑셀 파일
            </Button>
            <Button
              rightAccessory={<IconControlDownload />}
              onClick={splitPurchaseOrder}
            >
              주문서 분리
            </Button>
          </div>
        </div>
      </div>
      <If is={shippingCompleteOrders.length > 0}>
        <SelectableTable
          items={filteredOrders}
          columns={columns}
          selectedItems={selectedOrders}
          onSelect={setSelectedOrders}
          getKey={(order) => order.uniqueCodeAfterCustomization}
          className="max-h-[calc(100vh-300px)]"
        />
      </If>
    </div>
  );
}

export default observer(OrderShippingSectionShipped);
