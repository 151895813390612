import { PurchaseOrderFileRecord } from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function requestFindAllPurchaseOrderFileRecords(): Promise<
  PurchaseOrderFileRecord[]
> {
  const response = await api.get<APIResponse<PurchaseOrderFileRecord[]>>(
    '/purchase-order-file-records'
  );

  if (response?.data?.success === true && response.data.data != null) {
    return response.data.data;
  }

  return [];
}
