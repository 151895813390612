import { overlay } from 'overlay-kit';
import { IconAlert } from '@sweep/asset/icons';
import { Dialog } from '@sweep/sds';

export function openHandwrittenUploadWithoutUniqueCode() {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-16px gap-10px flex items-center">
          <IconAlert />
          스윕고유번호가 없는 주문이 있어요.
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          이 주문을 인식할 수 없어 중복 발주가 나갈 가능성이 있습니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(true)} color="lightGray">
            업로드하기
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(false)}>
            업로드 취소하기
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
