import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IconControlDownload, IconToolbar } from '@sweep/asset/icons';
import { PurchaseOrderFileRecord } from '@sweep/contract';
import { Button, Divider } from '@sweep/sds';
import { requestFindPurchaseOrderFileRecordWithShareLink } from 'src/network/file-share-link/findPurchaseOrderFileRecordWithShareLink';
import { createOrderExcelBuffer } from 'src/services/file/excel/create/createOrderExcelBuffer';
import { amplitude } from 'src/third-parties/amplitude';
import Sweep from '../../images/Sweep.png';

function useDownloadFile() {
  const [searchParams] = useSearchParams();
  const fileId = searchParams.get('id');
  const code = searchParams.get('code');

  return { fileId, code };
}

function usePurchaseOrderFileRecord() {
  const [isLoading, setIsLoading] = useState(false);
  const { fileId, code } = useDownloadFile();
  const [purchaseOrderFileRecord, setPurchaseOrderFileRecord] =
    useState<PurchaseOrderFileRecord | null>(null);

  useEffect(() => {
    if (fileId == null || code == null) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    requestFindPurchaseOrderFileRecordWithShareLink(fileId, code)
      .then((purchaseOrderFileRecord) => {
        setPurchaseOrderFileRecord(purchaseOrderFileRecord);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fileId, code]);

  const onClickDownload = async () => {
    if (purchaseOrderFileRecord == null) {
      return;
    }

    if (purchaseOrderFileRecord.excelFileRecord == null) {
      return;
    }

    const buffer = await createOrderExcelBuffer(
      purchaseOrderFileRecord.excelFileRecord
    );

    if (buffer == null) {
      return;
    }

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const filename = `${purchaseOrderFileRecord.excelFileRecord.filename}.${purchaseOrderFileRecord.excelFileRecord.fileExtension}`;

    saveAs(blob, filename);
  };

  const isDownloadable = purchaseOrderFileRecord != null;
  const filename = purchaseOrderFileRecord?.excelFileRecord?.filename;

  return {
    isDownloadable,
    onClickDownload,
    isLoading,
    filename,
  };
}

function SharedPurchaseOrderFileDownloadScreen() {
  const { fileId, code } = useDownloadFile();
  const { isDownloadable, onClickDownload, isLoading, filename } =
    usePurchaseOrderFileRecord();

  const isDownloadButtonDisabled = isLoading || !isDownloadable;

  useEffect(() => {
    if (filename === null) {
      return;
    }
    amplitude.track('Pageview shared-purchase-order', {
      'file name': filename,
    });
  }, [filename]);

  const handleDownloadButtonClick = () => {
    onClickDownload();
    amplitude.track('Click download-shared-purchase-order', {
      'file name': filename,
    });
  };

  return (
    <div className="flex min-h-screen w-full flex-col overflow-x-hidden bg-gray-100">
      <header className="sticky top-0 z-10 w-full bg-white shadow-sm">
        <div className="mx-auto flex h-16 max-w-screen-xl items-center px-4">
          <img src={Sweep} alt="Service icon" className="w-22.5 h-6" />
        </div>
      </header>

      <main className="gap-32px mx-auto flex w-full max-w-[1200px] flex-1 flex-col items-center  py-8">
        <div className="gap-32px flex-center flex w-full flex-col rounded-lg bg-white p-8 shadow-sm">
          <div className="flex-center gap-32px flex w-full max-w-[500px] flex-col">
            <h1 className="mb-4 text-2xl font-bold text-gray-600">
              발주서 다운로드
            </h1>
            <Divider type="horizontal-full" />
            <div className="gap-16px flex flex-row items-center">
              <div className="p-24px gap-6px flex w-[310px] items-center justify-center rounded-[8px] bg-gray-100">
                {fileId != null && (
                  <>
                    <IconToolbar className="shrink-0" />
                    <div className="text-semibold-m truncate text-gray-700">
                      {filename}
                    </div>
                  </>
                )}
              </div>
              <Button
                variant="solid"
                color="blue"
                onClick={handleDownloadButtonClick}
                disabled={isDownloadButtonDisabled}
              >
                발주서
                <IconControlDownload className="shrink-0" />
              </Button>
            </div>

            <Divider type="horizontal-full" />
            <p className="text-semibold-s text-gray-500">
              스윕은 고객 개인정보를 안전하게 보호하고 있습니다.
            </p>
          </div>
        </div>
        <div className="flex h-[55px] items-center">
          <div className="h-[24px]">
            <div className="gap-12px flex h-full flex-row items-center text-sm text-gray-400">
              <div>
                <img src={Sweep} alt="회사 로고" className="h-auto w-20" />
              </div>
              <Divider type="vertical-full" />
              <div>5분 만에 끝내는 발주 업무</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SharedPurchaseOrderFileDownloadScreen;
