import { useEffect } from 'react';
import { IconSend } from '@sweep/asset/icons';
import { Button, Modal } from '@sweep/sds';
import { RecordItemStatus } from 'src/stores/SendEmailWithPurchaseOrderFileStore';
import { amplitude } from 'src/third-parties/amplitude';
import { RecordItem } from './RecordItem';

type PendingRecordSectionProps = {
  records: RecordItemStatus[];
  selectedRecordIds: string[];
  selectedRecordCount: number;
  onSelectRecord: (recordId: string) => void;
  onClickSend: () => void;
  onClose: () => void;
};

export function PendingRecordSection({
  records,
  selectedRecordIds,
  selectedRecordCount,
  onSelectRecord,
  onClickSend,
  onClose,
}: PendingRecordSectionProps) {
  const isSendDisabled = selectedRecordIds.length === 0;

  useEffect(() => {
    amplitude.track('View send-purchase-order');
  }, []);

  return (
    <>
      <Modal.Title className="text-gray-700">발주서 전송</Modal.Title>
      <Modal.Description className="pb-32px text-gray-700">
        공급사 <span className="text-blue-500">{selectedRecordCount}곳</span>에
        이메일로 발주서를 전송할까요?
      </Modal.Description>
      <Modal.Description className="gap-8px flex flex-col">
        {records.map((recordItemStatus) => (
          <RecordItem
            key={recordItemStatus.recordId}
            supplierName={recordItemStatus.supplierName}
            supplierEmail={recordItemStatus.supplierEmail}
            filename={recordItemStatus.filename}
            emailStatus={recordItemStatus.emailStatus}
            isSelected={selectedRecordIds.includes(recordItemStatus.recordId)}
            onClick={() => onSelectRecord(recordItemStatus.recordId)}
          />
        ))}
      </Modal.Description>

      <div className="flex justify-center gap-[12px] pt-[20px]">
        <Button onClick={onClose} color="gray" className="w-[200px]">
          취소하기
        </Button>
        <Button
          onClick={onClickSend}
          className="w-[200px]"
          disabled={isSendDisabled}
        >
          <span className="gap-6px flex items-center">
            전송하기
            <IconSend />
          </span>
        </Button>
      </div>
    </>
  );
}
