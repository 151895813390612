import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { CombinedShipping } from '@sweep/contract';
import { Button, Modal, SearchInput } from '@sweep/sds';
import { If, SwitchCase } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { toast } from 'src/third-parties/toast';
import { CombinedShippingFormStore } from '../../CombinedShippingFormStore';
import { CombinedShippingResult } from '../../interface';
import CombinedShippingProductTable from '../CombinedShippingProductTable/CombinedShippingProductTable';
import { CombinedShippingProductTableStore } from '../CombinedShippingProductTable/CombinedShippingProductTableStore';
import CountInput from '../common/CountInput';
import Section from '../common/Section';
import TypeInput from '../common/TypeInput';
import UnitInput from '../common/UnitInput';

interface CombinedShippingEditFormModalProps {
  open: boolean;
  onSubmit: (result: CombinedShippingResult | null) => void;
  combinedShipping: CombinedShipping;
}

function CombinedShippingEditFormModal({
  open,
  onSubmit,
  combinedShipping,
}: CombinedShippingEditFormModalProps) {
  const oms = useOMSStore();
  const [store] = useState(
    () => new CombinedShippingFormStore(oms, combinedShipping)
  );
  const [tableStore] = useState(
    () => new CombinedShippingProductTableStore(oms, combinedShipping._id)
  );

  const [search, setSearch, filterSearch] = useSearchState({
    keys: ['productName', 'option', 'supplierName'],
  });
  const filteredCombinedShippingTableProducts = filterSearch(
    tableStore.combinedShippingTableProducts
  );

  const handleClose = () => {
    onSubmit(null);
  };

  const handleSubmit = () => {
    const result = store.getResult();
    if (store.type === 'count') {
      onSubmit(result);
      return;
    }

    if (store.isInvalidAmountUnit) {
      toast.error('숫자와 단위를 순서대로 입력해주세요.');
      store.setUnitInputError(true);
      return;
    }

    if (store.isAmountLessThenUnitAmount) {
      toast.error('상품의 단위 정보보다 작은 값으로 합배송할 수 없습니다.');
      store.setUnitInputError(true);
      return;
    }

    onSubmit(result);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      dismiss={{
        escapeKey: false,
        outsidePress: false,
      }}
      className="h-[690px] max-h-[calc(100vh-120px)] w-[1180px] max-w-[calc(100vw-80px)]"
    >
      <Modal.Title className="mb-20px">합배송 수정</Modal.Title>
      <div className="gap-32px mb-28px flex min-h-0 flex-1">
        <Section position="left" className="gap-16px">
          <p className="text-medium-s text-gray-500">
            함께 배송 보낼 상품을 선택해주세요
          </p>
          <SearchInput
            placeholder="검색"
            value={search}
            onChange={setSearch}
            className="shrink-0"
          />
          <CombinedShippingProductTable
            items={filteredCombinedShippingTableProducts}
            selectedItems={store.selectedProducts}
            onSelect={store.setSelectedProducts}
            disabledItems={tableStore.alreadyUsedCombinedShippingTableProducts}
          />
        </Section>
        <div className="w-1px h-full shrink-0 bg-gray-200" />
        <Section position="right" className="overflow-y-auto">
          <p className="text-medium-s mb-20px text-gray-500">
            합배송 기준을 입력해주세요
          </p>
          <TypeInput store={store} className="mb-20px" />
          <If is={store.selectedProducts.length > 0}>
            <SwitchCase
              value={store.type}
              caseBy={{
                count: <CountInput store={store} />,
                unit: <UnitInput store={store} />,
              }}
            />
          </If>
        </Section>
      </div>
      <div className="flex justify-center">
        <Button
          disabled={store.disabled}
          onClick={handleSubmit}
          className="w-200px"
        >
          수정하기
        </Button>
      </div>
    </Modal>
  );
}

export default observer(CombinedShippingEditFormModal);
