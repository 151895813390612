import { z } from 'zod';

// TODO(@형준): SWP-700 - 삭제 예정

export const SendEmailForPurchaseOrderFileRequestSchema = z.object({
  purchaseOrderFileRecordId: z.string(),
});

export type SendEmailForPurchaseOrderFileRequest = z.infer<
  typeof SendEmailForPurchaseOrderFileRequestSchema
>;

export const SendEmailForPurchaseOrderFileResponseSchema = z.object({
  isSuccess: z.boolean(),
  purchaseOrderFileRecordId: z.string(),
  errorMessage: z.string().optional(),
  successCount: z.number(),
  failedCount: z.number(),
});

export type SendEmailForPurchaseOrderFileResponse = z.infer<
  typeof SendEmailForPurchaseOrderFileResponseSchema
>;
