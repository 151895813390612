import { Workbook } from 'exceljs';
import { ExcelFileRecord } from '@sweep/contract';
import { addWorkSheet } from './addWorkSheet';

export async function createOrderExcelBuffer(
  record: ExcelFileRecord
): Promise<ArrayBuffer | null> {
  const { orders } = record;

  if (orders.length === 0) {
    return null;
  }

  const workbook = addWorkSheet({
    workbook: new Workbook(),
    record,
  });

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
}
