import { overlay } from 'overlay-kit';
import { DispatchModalStore } from '../../store/DispatchModalStore';
import DispatchModal from '../DispatchModal/DispatchModal';

export async function openDispatchDialog(
  store: DispatchModalStore,
  abortController: AbortController
) {
  return overlay.open(({ isOpen, close, unmount }) => {
    return (
      <DispatchModal
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
        store={store}
        abortController={abortController}
      />
    );
  });
}
