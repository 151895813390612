import { action, observable, runInAction } from 'mobx';
import { Inquiry } from '@sweep/contract';
import { replyInquiry } from 'src/network/inquiry';
import { OMSStore } from './OMSStore';

export class InquiryStore {
  @observable
  accessor inquiries: Inquiry[] = [];

  constructor(private oms: OMSStore) {}

  @action.bound
  setInquiries(inquiries: Inquiry[]) {
    this.inquiries = inquiries;
  }

  @action.bound
  async reply(
    inquiryUniqueCode: string,
    content?: string
  ): Promise<Inquiry | null> {
    const response = await replyInquiry(inquiryUniqueCode, content);
    if (response?.success !== true) {
      return null;
    }

    runInAction(() => {
      this.inquiries = this.inquiries.map<Inquiry>((inquiry) =>
        inquiry.inquiryUniqueCode === inquiryUniqueCode
          ? response.data
          : inquiry
      );
    });

    return response.data;
  }
}
